import { ArrowBack, DeleteOutline } from '@mui/icons-material';
import {
	Alert,
	AlertColor,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	Modal,
	Snackbar,
	Step,
	StepButton,
	Stepper,
	Typography
} from '@mui/material';
import AWS from 'aws-sdk';
import axios from 'axios';
import { usePostHog } from 'posthog-js/react';
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import CheckoutForm from '../../components/Merchant/Shipment/CreateShipmentForm/CheckoutForm';
import ExpressCustomsForm from '../../components/Merchant/Shipment/CreateShipmentForm/CustomsForm';
import ServiceForm from '../../components/Merchant/Shipment/CreateShipmentForm/ServiceForm';
import ShipmentDetailsForm from '../../components/Merchant/Shipment/CreateShipmentForm/ShipmentDetailsForm';
import { axiosConfig, useAuthToken } from '../../constants/axios';
import {
	BACK,
	BUTTON,
	CREATE_SHIPMENTS,
	DASHBOARD,
	MODAL,
	NEXT,
	RESET_FORM,
	RETURN_TO_DASHBOARD,
	STEPPER,
	createElementId
} from '../../constants/id';
import { setAlertBoolean } from '../../stores/alert';
import { ProductRecord } from '../../types/product';
import { ShipmentData } from '../../types/shipment';
import { UserRecord } from '../../types/user';

export default function Shipment(props: any) {
	const steps = ['SHIPMENT DETAILS', 'SERVICE', 'CUSTOMS INFO', 'CHECKOUT'];
	const [modalOpen, setModalOpen] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [shipmentDetails, setShipmentDetails] = useState({});
	const user: UserRecord = JSON.parse(
		sessionStorage.getItem('user') ? (sessionStorage.getItem('user') as string) : '{}'
	);
	const merchant = JSON.parse(sessionStorage.getItem('merchant') as string);
	const merchant_details = JSON.parse(sessionStorage.getItem('merchant') || '{}');
	const [products, setProducts] = useState<ProductRecord[]>();
	const [rates, setRates] = useState();
	const [selectedRate, setSelectedRate] = useState();
	const [insuranceValue, setInsuranceValue] = useState();
	const [validInsurance, setValidInsurance] = useState(false);
	const [addedInsurance, setAddedInsurance] = useState(true);
	const [errorAlert, setErrorAlert] = useState(false);
	const [alertErrorMessage, setAlertErrorMessage] = useState('');
	const [pickupErrorMessage, setPickupErrorMessage] = useState('');
	const dispatch = useDispatch();
	const [pdf, setPdf] = useState('' as string | ArrayBuffer | null);
	const [file, setfile] = useState<File | undefined>(undefined);
	const [fileName, setFileName] = useState('tmp');
	const [pdfError, setPdfError] = useState(false);
	const [expanded, setExpanded] = useState<string | false>('to_address');
	const [consent, setConsent] = useState(false);
	const [customsTotalValue, setTotalCustomsValue] = useState(0);
	const [codeError, setCodeError] = useState(false);
	const [codeWarning, setCodeWarning] = useState(false);
	const [invalidLandedCosts, setInvalidLandedCosts] = useState(false);
	const posthog = usePostHog();
	const token = useAuthToken();
	const axiosInstance = axiosConfig(token);

	const [apiResponse, setApiResponse] = useState<{
		type: AlertColor;
		message: string;
	}>({ type: 'success', message: '' });
	const [to_address, setToAddressDetails] = useState({
		address_to_street_1: '',
		address_to_street_2: '',
		address_to_zip_postal: '',
		address_to_city_locality: '',
		address_to_state_province: '',
		address_to_country: ''
	});
	const [from_address, setFromAddressDetails] = useState({
		address_from_street_1: '',
		address_from_zip_postal: '',
		address_from_city_locality: '',
		address_from_state_province: '',
		address_from_country: ''
	});

	const [parcel_details, setParcelDimensions] = useState({
		parcel_length_cm: null,
		parcel_width_cm: null,
		parcel_height_cm: null,
		parcel_weight_kg: null,
		shipment_reference: ''
	});

	const [pickup_slot, setPickupSlot] = useState({});

	useEffect(() => {
		axiosInstance
			.post('/product/getProductsByMerchantId', {
				merchant_id: merchant.partitionKey
			})
			.then(response => {
				setProducts(response.data);
			})
			.catch(err => {
				console.log(err);
			}); // eslint-disable-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	// 	if (activeStep === 1) {
	// 		setValue('customs_info.customs_items', [
	// 			{
	// 				description: '',
	// 				quantity: 1,
	// 				weight: 0,
	// 				value: 0,
	// 				hs_tariff_number: '',
	// 				origin_country: 'ZA',
	// 				currency: 'USD',
	// 				save_customs_line_item: false
	// 			}
	// 		]);
	// 	}
	// }, [activeStep]);

	let shipment = useSelector((state: any) => state.shipment);

	const validateNext = (): boolean => {
		switch (activeStep) {
			case 1:
				return !selectedRate;
			case 2:
				return codeError ? true : false;
			case 3: {
				if (
					getValues('shipment_type') === 'express' &&
					getValues('pickup.pickup_selected') === 1 &&
					(getValues('pickup.slot') === 0 || getValues('pickup.slot') === 7) &&
					consent === true //&& invalidLandedCosts === false
				) {
					return true;
				}
				// return !validInsurance;
				return (
					(!validInsurance && consent === false) || consent === false || (!insuranceValue && !validInsurance)
					// (!validInsurance && consent === false && invalidLandedCosts) || consent === false || (!insuranceValue && !validInsurance) || invalidLandedCosts
				);
			}
			default:
				return false;
		}
	};

	const {
		register,
		formState: { errors, isValid, isDirty },
		control,
		trigger,
		setValue,
		getValues,
		setError,
		clearErrors,
		watch,
		reset
	} = useForm<ShipmentData>({
		mode: 'all',
		defaultValues: {
			from_address: {
				address_from_name: user.user_name,
				address_from_company: merchant.merchant_name,
				address_from_street_1: merchant.merchant_street_1,
				address_from_street_2: merchant.merchant_street_2,
				address_from_city_locality: merchant.merchant_city_locality,
				address_from_state_province: merchant.merchant_state_province,
				address_from_zip_postal: merchant.merchant_zip_postal,
				address_from_country: merchant.merchant_country,
				address_from_phone: merchant.merchant_phone,
				address_from_email: merchant.merchant_email
			},
			to_address: {
				address_to_name: '',
				address_to_company: '',
				address_to_street_1: '',
				address_to_street_2: '',
				address_to_city_locality: '',
				address_to_state_province: '',
				address_to_zip_postal: '',
				address_to_country: '',
				address_to_phone: '',
				address_to_email: ''
			},
			pickup: {
				slot: 0,
				pickup_selected: 1,
				reference: '',
				min_datetime: '',
				max_datetime: '',
				is_account_address: false
			},
			parcel_details: {
				parcel_length_cm: 0,
				parcel_width_cm: 0,
				parcel_height_cm: 0,
				parcel_weight_kg: 0,
				shipment_reference: ''
			},
			reference: '',
			product_reference: '',
			customs_info: {
				customs_certify: true,
				customs_signer: '',
				contents_type: 'merchandise',
				contents_explanation: '',
				restriction_type: 'none',
				restriction_comments: '',
				eel_pfc: '',
				customs_items: [
					{
						description: '',
						quantity: 1,
						weight: 0,
						value: 0,
						hs_tariff_number: '',
						origin_country: 'ZA',
						currency: undefined,
						save_customs_line_item: false
					}
				]
			},
			...merchant.merchant_settings,
			customs_incoterm: merchant.merchant_settings.ddp ? 'DDP' : 'DAP',
			selected_currency: '',
			service: '',
			carrier_accounts: [],
			merchant_details: merchant_details,
			rate: '',
			carrier: '',
			delivery_confirmation: merchant.merchant_settings.delivery_confirmation
		}
	});

	const handlePdfUpload = (e: BaseSyntheticEvent) => {
		const file = e.target.files[0];
		if (file.type === 'application/pdf') {
			setPdfError(false);
			setfile(file);
			setFileName(file.name);
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				setPdf(reader.result);
			};
		} else {
			if (!pdf) {
				setPdfError(true);
			}
			setApiResponse({
				type: 'error',
				message: "Error: Only PDF's are allowed"
			});
			setOpenSnackBar(true);
		}
	};

	const handleDropPdf = (e: DragEvent) => {
		e.preventDefault();

		if (e.dataTransfer?.items[0].type !== 'application/pdf') {
			setApiResponse({
				type: 'error',
				message: "Error: Only PDF's are allowed"
			});
			setOpenSnackBar(true);
			setPdfError(true);
		}
		if (e.dataTransfer?.items[0].kind === 'file') {
			setPdfError(false);
			const file = e.dataTransfer?.files[0];
			setfile(file);
			setFileName(file?.name as string);
			const reader = new FileReader();
			reader.readAsDataURL(file as Blob);
			reader.onload = () => {
				setPdf(reader.result);
			};
		}
	};

	const handleDragOver = (e: DragEvent) => {
		if (e.dataTransfer?.items[0].type !== 'application/pdf') {
			setApiResponse({
				type: 'error',
				message: "Error: Only PDF's are allowed"
			});
			setOpenSnackBar(true);
			if (!pdf) setPdfError(true);
		} else {
			e.preventDefault();
		}
	};

	const uploadPdfToS3 = async () => {
		const S3Bucket = new AWS.S3({
			accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
			secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
			region: process.env.REACT_APP_S3_REGION
		});

		const params: AWS.S3.PutObjectRequest = {
			Bucket: process.env.REACT_APP_S3_BUCKET_NAME as string,
			Key: `${process.env.REACT_APP_ENVIRONMENT}/${v4()}.pdf`,
			Body: file,
			ContentType: 'application/pdf'
		};

		try {
			const { Location } = await S3Bucket.upload(params).promise();
			return Location;
		} catch (error) {
			console.log(error);
			return null;
		}
	};

	const getRates = async (ratesData: any) => {
		setLoading(true);
		try {
			const response = await axiosInstance.post('/getExpressRatesNoShipment', ratesData);
			setValue('rates_breakdown.returned_rates', response.data.data);
			setLoading(false);
			return response.data.data;
		} catch (error) {
			if (axios.isAxiosError(error) && error.response) {
				setApiResponse({
					type: 'error',
					message: error.response.data.error
				});
			} else {
				setApiResponse({
					type: 'error',
					message: 'Error: An error has occurred'
				});
			}
			setLoading(false);
		}
	};

	const handleLeaveOpen = () => {
		if (isDirty) {
			setModalOpen(true);
		} else {
			navigate('/');
		}
	};

	const alertUser = (e: { preventDefault: () => void; returnValue: string }) => {
		e.preventDefault();
		e.returnValue = '';
	};

	useEffect(() => {
		window.addEventListener('beforeunload', alertUser);
		return () => {
			window.removeEventListener('beforeunload', alertUser);
		};
	}, []);

	const handleLeaveClose = () => setModalOpen(false);

	useEffect(() => {
		if (to_address.address_to_street_1 || to_address.address_to_country) {
			setValue('to_address.address_to_street_1', to_address.address_to_street_1);
			setValue('to_address.address_to_street_2', to_address.address_to_street_2);
			setValue('to_address.address_to_zip_postal', to_address.address_to_zip_postal);
			setValue('to_address.address_to_city_locality', to_address.address_to_city_locality);
			setValue('to_address.address_to_state_province', to_address.address_to_state_province);
			setValue('to_address.address_to_country', to_address.address_to_country);
			trigger('to_address.address_to_street_1');
			trigger('to_address.address_to_street_2');
			trigger('to_address.address_to_zip_postal');
			trigger('to_address.address_to_city_locality');
			trigger('to_address.address_to_state_province');
			trigger('to_address.address_to_country');
		}
	}, [to_address]);

	useEffect(() => {
		if (from_address.address_from_street_1 || from_address.address_from_country) {
			setValue('from_address.address_from_street_1', from_address.address_from_street_1);
			setValue('from_address.address_from_zip_postal', from_address.address_from_zip_postal);
			setValue('from_address.address_from_city_locality', from_address.address_from_city_locality);
			setValue('from_address.address_from_state_province', from_address.address_from_state_province);
			setValue('from_address.address_from_country', from_address.address_from_country);
			trigger('from_address.address_from_street_1');
			trigger('from_address.address_from_zip_postal');
			trigger('from_address.address_from_city_locality');
			trigger('from_address.address_from_state_province');
			trigger('from_address.address_from_country');
		}
	}, [from_address]);

	const handleAddressChange = (e: any) => {
		const { name, value } = e.target;
		setValue(name, value);
		trigger(name);
	};

	const handleDimensionChange = (e: any) => {
		const { name, value } = e.target;
		if (!isNaN(value)) {
			setParcelDimensions((prevState: any) => ({
				...prevState,
				[name]: Number(Number(value).toFixed(1))
			}));
		}
	};

	const determineInsurance = (insurance: any) => {
		if (addedInsurance) {
			if (insurance > 0) {
				return insurance >= 2000 ? insurance : 2000;
			} else {
				return 0;
			}
		} else {
			return 0;
		}
	};

	const handleBuyShipment = async (rate: any, insurance: any) => {
		try {
			const response = await axiosInstance.post('/createShipment', {
				...getValues(),
				rate: rate.rate,
				insurance: determineInsurance(insurance)
			});

			const responseData = response.data.data;

			//TODO: check in on this
			posthog.capture('shipment_purchased_details', {
				rate: rate.rate,
				insurance: response.data.insurance,
				user: user.user_email,
				merchant: merchant.merchant_name,
				shipment_id: responseData.shipment_id,
				date_created: responseData.date_created,
				shipment_type: responseData.shipment_type,
				carrier: responseData.source_api_carrier,
				incoterm: responseData.customs_incoterm
			});

			navigate('/shipment/details', {
				state: response.data.data,
				replace: true
			});

			setLoading(false);
		} catch (error: any) {
			console.log(error);
			setErrorAlert(true);
			setPickupErrorMessage(`Buy shipment failed. ${error?.response?.data?.data}`);
			setApiResponse({
				type: 'error',
				message: error?.response?.data?.data
			});
			setOpenSnackBar(true);
			setLoading(false);
		}
	};

	const handleNext = async () => {
		if (activeStep === steps.length - 1) {
			setLoading(true);
			const invoice = (await uploadPdfToS3()) as string;
			setValue('invoice', invoice);
			handleBuyShipment(selectedRate, insuranceValue);
		}
		if (activeStep === 0) {
			const isEmptyAddressDetails = Object.values(to_address).every(value => value === '');
			await trigger('from_address');
			await trigger('to_address');
			await trigger('parcel_details');
			if (errors.from_address) setExpanded('from_address');
			else if (errors.to_address) setExpanded('to_address');
			else if (errors.parcel_details) setExpanded('parcel_details');
			if (isEmptyAddressDetails) {
				return;
			}
			if (!errors.to_address && !errors.from_address && !errors.parcel_details)
				setRates(
					await getRates({
						shipment: {
							to_address: getValues('to_address'),
							from_address: getValues('from_address'),
							parcel_details: getValues('parcel_details')
						}
					})
				);
		}
		if (
			(activeStep === 2 && getValues('selected_currency') === '') ||
			getValues('selected_currency') === 'placeholder'
		) {
			return;
		}
		if (activeStep === 2) {
			let totalValue = 0;
			getValues('customs_info.customs_items').forEach((item: any, index: number) => {
				totalValue += item.value;
			});
			setTotalCustomsValue(totalValue);
			if (totalValue > 800 && getValues('shipment_type') === 'economy') return;
		}
		if (activeStep === 2 && !pdf) {
			setPdfError(true);
		}
		if (activeStep === 2) {
			await trigger('customs_info.customs_items');

			const customsItems = getValues('customs_info.customs_items');
			addProducts(customsItems);
			const totalWeight = Number(customsItems.reduce((sum, item) => sum + item.weight, 0).toFixed(2));
			const parcelWeight = getValues('parcel_details.parcel_weight_kg');

			if (totalWeight > parcelWeight) {
				setOpenSnackBar(true);
				setApiResponse({
					type: 'error',
					message: `The combined weight of your items cannot exceed the total shipment weight of
					${getValues('parcel_details.parcel_weight_kg')}kg. Please adjust the weight(s) of
					your item(s) or package details on the shipment details step.`
				});
				return;
			} else {
				setOpenSnackBar(false);
			}
		}

		if (!Object.keys(errors).length && activeStep !== steps.length - 1)
			setActiveStep(prevActiveStep => prevActiveStep + 1);
		trigger(['to_address', 'parcel_details', 'customs_info', 'from_address']);
	};

	const addProducts = async (customsItems: any) => {
		for (const customItem of customsItems) {
			if (customItem.save_customs_line_item && !productExists(customItem)) {
				try {
					await axiosInstance.post('/product/createProduct', {
						merchant_id: merchant.partitionKey,
						merchant_name: merchant.merchant_name,
						...customItem
					});
				} catch (error) {
					if (axios.isAxiosError(error) && error.response) {
						setApiResponse({
							type: 'error',
							message: error.response.data.error
						});
					} else {
						setApiResponse({
							type: 'error',
							message: 'Error: An error has occurred'
						});
					}
				}
			}
		}
	};

	const productExists = (customItem: any) => {
		return products?.some(product => product.description === customItem.description) ?? false;
	};

	const handleBack = () => {
		clearErrors();
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};

	const handleStepLabelClicked = (step: number) => () => {
		clearErrors();
		setActiveStep(step);
	};

	const handleReset = () => {
		reset();
		setActiveStep(0);
		handleClose();
	};

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackBar(false);
	};

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'customs_info.customs_items'
	});

	const resetModalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 405,
		bgcolor: 'background.paper',
		border: 'none',
		boxShadow: '1px 1px solid gray',
		pt: 2,
		px: 4,
		pb: 3
	};

	if (isDirty) {
		dispatch(setAlertBoolean('true'));
	}

	return (
		<>
			<div
				style={{
					display: 'block',
					justifyContent: 'center',
					alignContent: 'center',
					marginBottom: '60px',
					maxWidth: 1200,
					marginLeft: 'auto',
					marginRight: 'auto'
				}}
			>
				<Button onClick={handleLeaveOpen} sx={{ mr: 1, mt: 5, color: 'gray', alignContent: 'center' }}>
					<ArrowBack fontSize="small" sx={{ color: 'gray' }} /> Dashboard
				</Button>
				<Grid container alignItems="center" direction="column" marginTop={8}>
					<Grid item marginBottom={2} justifyContent="space-around" sx={{ width: '100%' }}>
						<Stepper activeStep={activeStep} orientation="horizontal" alternativeLabel>
							{steps.map((label, index) => {
								return (
									<Step key={label}>
										<StepButton onClick={handleStepLabelClicked(index)}>{label}</StepButton>
									</Step>
								);
							})}
						</Stepper>
					</Grid>
					<Grid item sx={{ width: '100%' }} alignContent="center" justifySelf="center">
						<form key="express-shipment-form">
							{activeStep === 0 ? (
								<ShipmentDetailsForm
									handleAddressChange={handleAddressChange}
									setFromAddressDetails={setFromAddressDetails}
									setToAddressDetails={setToAddressDetails}
									from_address={from_address}
									to_address={to_address}
									register={register}
									errors={errors}
									setValue={setValue}
									watch={watch}
									shipment_type="express"
									handleDimensionChange={handleDimensionChange}
									parcel_details={parcel_details}
									getValues={getValues}
									setError={setError}
									clearErrors={clearErrors}
									setPickupSlot={setPickupSlot}
									expanded={expanded}
									setExpanded={setExpanded}
								/>
							) : activeStep === 1 ? (
								<ServiceForm
									getValues={getValues}
									setValue={setValue}
									register={register}
									rates={rates}
									setSelectedRate={setSelectedRate}
								/>
							) : activeStep === 2 ? (
								<ExpressCustomsForm
									parcel_details={parcel_details}
									handleDimensionChange={handleDimensionChange}
									register={register}
									setError={setError}
									clearErrors={clearErrors}
									errors={errors}
									append={append}
									remove={remove}
									fields={fields}
									getValues={getValues}
									setValue={setValue}
									watch={watch}
									products={products}
									pdf={pdf}
									pdfError={pdfError}
									handlePdfUpload={handlePdfUpload}
									handleDropPdf={handleDropPdf}
									handleDragOver={handleDragOver}
									customsTotalValue={customsTotalValue}
									codeError={codeError}
									setCodeError={setCodeError}
									codeWarning={codeWarning}
									setCodeWarning={setCodeWarning}
									to_address={to_address}
								/>
							) : activeStep === 3 ? (
								<CheckoutForm
									consent={consent}
									register={register}
									errors={errors}
									watch={watch}
									getValues={getValues}
									setValue={setValue}
									setError={setError}
									clearErrors={clearErrors}
									setPickupSlot={setPickupSlot}
									insuranceValue={insuranceValue}
									setInsuranceValue={setInsuranceValue}
									setValidInsurance={setValidInsurance}
									setAddedInsurance={setAddedInsurance}
									setConsent={setConsent}
									invalidLandedCosts={invalidLandedCosts}
									setInvalidLandedCosts={setInvalidLandedCosts}
								/>
							) : (
								<></>
							)}
						</form>
					</Grid>
					<Grid item>
						<React.Fragment>
							<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
								<Button
									id={createElementId([CREATE_SHIPMENTS, STEPPER, BUTTON, BACK])}
									disabled={activeStep === 0}
									onClick={handleBack}
									sx={{ mr: 1 }}
									color="primary"
									variant="contained"
								>
									Back
								</Button>
								<Box sx={{ flex: '1 1 auto' }} />
								<Button
									id={createElementId([CREATE_SHIPMENTS, STEPPER, BUTTON, NEXT])}
									color="primary"
									variant="contained"
									onClick={handleNext}
									disabled={validateNext()}
									sx={
										activeStep === steps.length - 1
											? {
													backgroundColor: '#03C988',
													':hover': {
														backgroundColor: '#057852'
													}
											  }
											: {}
									}
								>
									{activeStep === steps.length - 1 ? 'Buy' : 'Next'}
								</Button>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', pt: 2 }} mb={5}>
								<Button
									id={createElementId([CREATE_SHIPMENTS, RESET_FORM])}
									onClick={handleOpen}
									sx={{ mr: 1, color: 'red', alignContent: 'center' }}
								>
									Reset Form
								</Button>
							</Box>
							<div>
								{open && (
									<Modal
										id={createElementId([CREATE_SHIPMENTS, RESET_FORM, MODAL, 'clear_data'])}
										open={open}
										onClose={handleClose}
										aria-labelledby="modal-modal-title"
										aria-describedby="modal-modal-description"
									>
										<Box sx={resetModalStyle}>
											<Typography id="modal-modal-title" variant="h6" component="h2">
												Clear Form Data?
											</Typography>
											<Typography
												id="modal-modal-description"
												sx={{ mt: 2, color: 'gray', fontSize: '14px' }}
											>
												Are you sure you want to clear all form data? This will delete all
												shipment details you have entered so far.
												<DeleteOutline fontSize="small" sx={{ color: 'red' }} />
											</Typography>
											<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
												<Button
													id={createElementId([
														CREATE_SHIPMENTS,
														RESET_FORM,
														MODAL,
														BUTTON,
														BACK
													])}
													onClick={handleClose}
													sx={{ mr: 1 }}
													color="primary"
												>
													Back
												</Button>
												<Box sx={{ flex: '1 1 auto' }} />
												<Button
													id={createElementId([
														CREATE_SHIPMENTS,
														RESET_FORM,
														MODAL,
														BUTTON,
														'clear_data'
													])}
													color="error"
													variant="contained"
													onClick={handleReset}
												>
													Clear Data
												</Button>
											</Box>
										</Box>
									</Modal>
								)}
								{modalOpen && (
									<Modal
										id={createElementId([CREATE_SHIPMENTS, RETURN_TO_DASHBOARD, MODAL, DASHBOARD])}
										open={modalOpen}
										onClose={handleLeaveClose}
										aria-labelledby="modal-modal-title"
										aria-describedby="modal-modal-description"
									>
										<Box
											sx={resetModalStyle}
											style={{ border: 'none', boxShadow: '1px 1px solid gray' }}
										>
											<Typography id="modal-modal-title" variant="h6" color="gray" component="h2">
												Return to Dashboard?
											</Typography>
											<Typography
												id="modal-modal-description"
												sx={{ mt: 2, color: 'gray', fontSize: '14px' }}
											>
												Are you sure you want to return to the dashboard? This will clear all
												shipment details you have entered so far.
											</Typography>
											<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
												<Button
													id={createElementId([
														CREATE_SHIPMENTS,
														RETURN_TO_DASHBOARD,
														MODAL,
														DASHBOARD,
														BUTTON,
														'stay_here'
													])}
													onClick={handleLeaveClose}
													sx={{ mr: 1 }}
													color="primary"
												>
													Stay here
												</Button>
												<Box sx={{ flex: '1 1 auto' }} />
												<Button
													id={createElementId([
														CREATE_SHIPMENTS,
														RETURN_TO_DASHBOARD,
														MODAL,
														DASHBOARD,
														BUTTON,
														DASHBOARD
													])}
													color="error"
													sx={{ fontSize: '14px' }}
													onClick={() => navigate('/')}
												>
													Return to the dashboard
												</Button>
											</Box>
										</Box>
									</Modal>
								)}
							</div>
						</React.Fragment>
					</Grid>
				</Grid>
				<Snackbar
					open={openSnackBar}
					autoHideDuration={2000}
					onClose={handleAlertClose}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				>
					<Alert onClose={handleAlertClose} severity={apiResponse.type} sx={{ width: '100%' }}>
						{apiResponse.message}
					</Alert>
				</Snackbar>
				<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</div>
		</>
	);
}
