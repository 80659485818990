import {
	Add,
	CheckCircleOutline,
	CopyAll,
	KeyboardArrowDown,
	KeyboardArrowUp,
	RadioButtonUncheckedOutlined,
	Remove
} from '@mui/icons-material';
import {
	Alert,
	Autocomplete,
	Backdrop,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Popover,
	Snackbar,
	TextField,
	Typography
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Document, Page } from 'react-pdf';
import { axiosConfig, useAuthToken } from '../../constants/axios';
import { SnackAlert } from '../../types/util';
import { formatDate } from '../../util/date';

export default function ShipmentReviewComponent(props: any) {
	const [loading, setLoading] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [openPopover, setOpenPopover] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [snackAlert, setSnackAlert] = useState<SnackAlert>({ type: 'success', message: '' });
	const {
		register,
		errors,
		shipmentDetails,
		deliveryAddress,
		handleNext,
		handlePrevious,
		handleUpdate,
		getValues,
		pdf,
		currentShipment,
		numShipments,
		shipments,
		handleSearch,
		handleAdd,
		handleRemove,
		fields,
		handleRemoveLine
	} = props;

	const date = formatDate(new Date(shipmentDetails.last_modified));
	const [numPages, setNumPages] = useState<any>(null);
	const token = useAuthToken();
	const axiosInstance = axiosConfig(token);

	const onDocumentLoadSuccess = (pdfInfo: any) => {
		setPageNumber(1);
		setNumPages(pdfInfo.numPages);
	};

	const handleOpen = (event: any) => {
		setOpenPopover(true);
		setAnchorEl(event.currentTarget);
		setTimeout(() => {
			setOpenPopover(false);
		}, 500);
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'ArrowDown') {
			handleNext();
		} else if (event.key === 'ArrowUp') {
			handlePrevious();
		}
	};

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackBar(false);
	};

	const handleClear = async () => {
		setLoading(true);
		try {
			const response = await axiosInstance.post('/easyclear/clearBookedShipmentToEasyClear', {
				booking_id: shipmentDetails.booking_id,
				shipment_id: shipmentDetails.shipment_id
			});
			setSnackAlert({ type: 'success', message: response.data.message });
			setOpenSnackBar(true);
			setLoading(false);
		} catch (error) {
			if (axios.isAxiosError(error) && error.response) {
				setSnackAlert({ type: 'error', message: error.response.data.error.message });
				setOpenSnackBar(true);
			} else {
				setSnackAlert({ type: 'error', message: 'Error: An error has occurred' });
			}
			console.log(error);
			setLoading(false);
		}
		setLoading(false);
	};

	return (
		<>
			<Grid container direction={'row'} justifyContent={'space-around'} sx={{ paddingBottom: 10 }}>
				<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
					{pdf ? (
						<div
							style={{
								height: '100%',
								maxHeight: '90vh',
								overflow: 'auto'
							}}
						>
							<Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
								{Array.from(new Array(numPages), (el, index) => (
									<Page key={`page_${index + 1}`} pageNumber={index + 1} height={800} />
								))}
							</Document>
						</div>
					) : (
						<></>
					)}
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={7}>
					<form>
						<Grid container direction={'row'}>
							<Grid item xs={10} sm={10} md={2} lg={12} xl={8}>
								<Typography variant="h5" mb={2} mt={5}>
									Search HAWB Number:
								</Typography>

								<Grid container>
									<Grid item xs={10} sm={10} md={4} lg={6} xl={6}>
										<Autocomplete
											id="filter-demo"
											options={shipments}
											getOptionLabel={(option: any) => option.customs_hawb_number}
											defaultValue={shipments[currentShipment]}
											onChange={(event: any, newValue: any) => {
												if (shipments.indexOf(newValue) !== -1) {
													handleSearch(shipments.indexOf(newValue));
												}
											}}
											renderInput={params => <TextField {...params} label="HAWB Number" />}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item mt={5} xs={10} sm={10} md={2} lg={12} xl={4}>
								<Grid container direction={'column'}>
									{shipmentDetails.shipment_edit_status.toLowerCase() === 'edited' ? (
										<>
											<Grid item alignSelf={'flex-end'}>
												<CheckCircleOutline sx={{ color: '#17ff35', marginTop: 5 }} />
											</Grid>{' '}
											<Grid item alignSelf={'flex-end'}>
												<Typography>{'Edited: ' + date}</Typography>
											</Grid>
										</>
									) : (
										<>
											<Grid item alignSelf={'flex-end'}>
												<RadioButtonUncheckedOutlined sx={{ color: '#007BC4', marginTop: 5 }} />
											</Grid>{' '}
											<Grid item alignSelf={'flex-end'}>
												<Typography>{'Unedited Shipment'}</Typography>
											</Grid>
										</>
									)}
								</Grid>
							</Grid>
						</Grid>
						<Grid container direction={'row'}>
							<Grid item xs={10} sm={10} md={2} lg={12} xl={6}>
								<Typography variant="h5" mb={2} mt={5}>
									Invoice Number:
								</Typography>
								<Grid container>
									<Grid item xs={10} sm={10} md={4} lg={6} xl={8}>
										<TextField
											required
											label="Invoice Number"
											{...register('shipment_reference', {
												required: 'Required'
											})}
											error={!!errors?.parcel_details?.shipment_reference}
											helperText={
												errors?.parcel_details?.shipment_reference
													? (errors.parcel_details.shipment_reference.message as string)
													: null
											}
											fullWidth
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={10} sm={10} md={2} lg={12} xl={6}>
								<Typography variant="h5" mb={2} mt={5}>
									Delivery Address:
								</Typography>
								<Grid container direction={'row'}>
									<Grid item xs={10} sm={8} md={5} lg={12} xl={11.3}>
										<TextField value={deliveryAddress} disabled fullWidth></TextField>
									</Grid>
									<Grid item xs={10} sm={8} md={5} lg={2} xl={0.7}>
										<CopyToClipboard text={deliveryAddress}>
											<IconButton
												color="primary"
												component="span"
												onClick={handleOpen}
												size={'small'}
											>
												<CopyAll />
												<Popover
													open={openPopover}
													anchorEl={anchorEl}
													anchorOrigin={{
														vertical: 'top',
														horizontal: 'right'
													}}
												>
													<div
														style={{
															color: 'white',
															backgroundColor: 'black'
															// padding: '6px'
														}}
													>
														Copied!
													</div>
												</Popover>
											</IconButton>
										</CopyToClipboard>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid container direction={'row'}>
							<Grid item xs={10} sm={8} md={5} lg={2} xl={6}>
								<Typography variant="h5" mt={8}>
									Customs Items:
								</Typography>
							</Grid>
							<Grid item xs={10} sm={8} md={5} lg={2} xl={6}>
								<Grid container direction={'row'} justifyContent={'flex-end'} spacing={2} mt={2}>
									<Grid item xs={10} sm={10} md={1} lg={1} xl={4}>
										<TextField
											disabled
											required
											label="Departure Country"
											value={shipmentDetails.merchant_country}
											fullWidth
										/>
									</Grid>
									<Grid item xs={10} sm={10} md={1} lg={1} xl={4}>
										<TextField
											disabled
											required
											label="Arrival Country"
											value={shipmentDetails.address_to_country}
											fullWidth
										/>
									</Grid>
									<Grid item xs={10} sm={10} md={1} lg={1} xl={0.7}></Grid>
									<Grid item xs={10} sm={10} md={1} lg={1} xl={6} mt={2}>
										<Grid
											container
											direction={'row'}
											spacing={2}
											justifyContent={'flex-end'}
										></Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid container>
							{shipmentDetails.customs_info?.customs_items?.map((customs_item: any, index: number) => (
								<>
									<Typography align="center" fontWeight={'bold'}>
										Customs Item {index + 1}
									</Typography>
									<Grid container spacing={2}>
										<Grid item xs={10} sm={10} md={2} lg={5} xl={5}>
											<TextField
												required
												label="Description"
												{...register(`customs_info.customs_items.${index}.description`, {
													required: 'Required'
												})}
												error={!!errors?.customs_info?.customs_items?.[index]?.description}
												helperText={
													errors?.customs_info?.customs_items?.[index]?.description
														? (errors.customs_info.customs_items[index].description
																.message as string)
														: null
												}
												fullWidth
											/>
										</Grid>
										<Grid item xs={10} sm={10} md={1} lg={1} xl={1}>
											<TextField
												required
												label="Origin Country"
												{...register(`customs_info.customs_items.${index}.origin_country`, {
													required: 'Required'
												})}
												error={!!errors?.customs_info?.customs_items?.[index]?.origin_country}
												helperText={
													errors?.customs_info?.customs_items?.[index]?.origin_country
														? (errors.customs_info.customs_items[index].origin_country
																.message as string)
														: null
												}
												fullWidth
											/>
										</Grid>
										<Grid item xs={10} sm={10} md={1.5} lg={1.5} xl={1.5}>
											<TextField
												required
												label="Quantity"
												// type="number"
												{...register(`customs_info.customs_items.${index}.quantity`, {
													required: 'Required',
													valueAsNumber: true
												})}
												error={!!errors?.customs_info?.customs_items?.[index]?.quantity}
												helperText={
													errors?.customs_info?.customs_items?.[index]?.quantity
														? (errors.customs_info.customs_items[index].quantity
																.message as string)
														: null
												}
												fullWidth
											/>
										</Grid>
										<Grid item xs={10} sm={10} md={1.5} lg={1.5} xl={1.5}>
											<TextField
												required
												// type="number"
												label="Total Value(USD)"
												{...register(`customs_info.customs_items.${index}.value`, {
													required: 'Required',
													valueAsNumber: true
												})}
												error={!!errors?.customs_info?.customs_items?.[index]?.value}
												helperText={
													errors?.customs_info?.customs_items?.[index]?.value
														? (errors.customs_info.customs_items[index].value
																.message as string)
														: null
												}
												fullWidth
											/>
										</Grid>
										<Grid item xs={10} sm={10} md={2} lg={2} xl={2}>
											<TextField
												required
												label="Arrival HTS Code"
												{...register(`customs_info.customs_items.${index}.hs_tariff_number`, {
													required: 'Required'
												})}
												error={!!errors?.customs_info?.customs_items?.[index]?.hs_tariff_number}
												helperText={
													errors?.customs_info?.customs_items?.[index]?.hs_tariff_number
														? (errors.customs_info.customs_items[index].hs_tariff_number
																.message as string)
														: null
												}
												fullWidth
											/>
										</Grid>
										<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
											<IconButton
												sx={{ mt: 2 }}
												onClick={() => handleRemoveLine(index)}
												size="small"
											>
												<Remove />
											</IconButton>
										</Grid>
									</Grid>
								</>
							))}
						</Grid>
						<Grid container alignContent="center" justifyContent="center" direction="column">
							<Grid container direction="row" alignContent="center" justifyContent="center" mt={2}>
								<Grid item>
									<IconButton onClick={() => handleAdd()}>
										<Add></Add>
									</IconButton>
								</Grid>
								<Grid item>
									<IconButton
										onClick={() => {
											handleRemove();
										}}
									>
										<Remove></Remove>
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
						<Grid item mt={2}>
							HAWB Number: {shipmentDetails.customs_hawb_number}
						</Grid>
						<Grid item mt={2}>
							<Grid container direction={'row'} spacing={2}>
								<Grid item>
									<Button
										disabled={Object.keys(errors).length ? true : false}
										color="primary"
										variant="contained"
										onClick={handleUpdate}
									>
										Update Shipment
									</Button>
								</Grid>
								<Grid item>
									<Button
										sx={{
											backgroundColor: '#03C988',
											':hover': {
												backgroundColor: '#057852'
											}
										}}
										disabled={Object.keys(errors).length ? true : false}
										variant="contained"
										onClick={handleClear}
									>
										Clear
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<p>{'Shipment : ' + (currentShipment + 1) + ' of ' + numShipments} </p>
					</form>
				</Grid>
				<Grid item xs={1} sm={1} md={1} lg={0.4} xl={0.4} alignSelf="center">
					<Grid container direction={'column'}>
						<Grid item>
							<IconButton aria-label="expand row" size="large" onClick={handlePrevious}>
								{' '}
								<KeyboardArrowUp />
							</IconButton>
						</Grid>
						<Grid item>
							<IconButton aria-label="expand row" size="large" onClick={handleNext}>
								{' '}
								<KeyboardArrowDown />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Snackbar
				open={openSnackBar}
				autoHideDuration={2000}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleAlertClose} severity={snackAlert.type} sx={{ width: '100%' }}>
					{snackAlert.message}
				</Alert>
			</Snackbar>
		</>
	);
}
