import { Alert, AlertTitle } from '@material-ui/core';
import { AlertColor, Typography } from '@mui/material';
import LearnMore from './LearnMore';

interface TNAlertsProps {
	severity: AlertColor;
	title: string;
	message: string;
	link?: string;
}

export default function TNAlerts({ severity, title, message, link }: TNAlertsProps) {
	return (
		<div>
			<Alert severity={severity}>
				<AlertTitle>
					<strong>{title}</strong>
				</AlertTitle>
				<Typography sx={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>{message || 'No message provided'}</Typography>
				<LearnMore href={link} />
			</Alert>
		</div>
	);
}
