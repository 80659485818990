import { CopyAll, Download } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import {
	Alert,
	AlertColor,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Link,
	Paper,
	Snackbar,
	Stack,
	Tooltip,
	Typography
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useLocation, useNavigate } from 'react-router-dom';
import CostItem from '../../components/Common/CostItem';
import SnapScanPayNow from '../../components/Common/SnapScanPayNow';
import NextStepsModal from '../../components/Shared/NextStepsModal';
import PickupModal from '../../components/Shared/PickupModal';
import { axiosConfig, useAuthToken } from '../../constants/axios';
import text from '../../constants/text';
import { Shipment, ShipmentData } from '../../types/shipment';
import { TrackingStatus } from '../../types/tracker';
import { SnackAlert } from '../../types/util';
import { formatDateTime } from '../../util/date';

export default function ShipmentSummary() {
	const location = useLocation();
	const navigate = useNavigate();
	const shipment = location.state as Shipment;
	const [pickupId, setPickupId] = useState('');
	const [loading, setLoading] = useState(false);
	const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
	const [pickupModalOpen, setPickupModalOpen] = useState(false);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [snackAlert, setSnackAlert] = useState<SnackAlert>({ type: 'success', message: '' });
	const [downloaded, setDownloaded] = useState(false);
	const [shipmentPaid, setShipmentPaid] = useState(false);
	const [landedCosts, setLandedCosts] = useState(0);
	const [deliveryConfirmationCost, setDeliveryConfirmationCost] = useState(
		shipment.delivery_confirmation === 'ADULT_SIGNATURE' ? 50.0 : 0
	);
	const token = useAuthToken();
	const axiosInstance = axiosConfig(token);

	useEffect(() => {
		const rates_breakdown = location.state as ShipmentData;
		setLandedCosts(rates_breakdown.rates_breakdown.ddp?.landed_costs_total_rands || 0);
	}, []);

	const calculateDateCreated = () => {
		const dateCreated = new Date(shipment.date_created);
		const today = new Date();
		const diffTime = Math.abs(today.getTime() - dateCreated.getTime());
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return diffDays;
	};

	const [cancelShipmentDisabled, setCancelShipmentDisabled] = useState(
		(shipment.event_status !== TrackingStatus.PreTransit && shipment.event_status !== 'TBD') ||
			calculateDateCreated() > 30
	);

	const [pickup, setPickup] = useState({
		id: '',
		min_datetime: '',
		max_datetime: '',
		confirmation: ''
	});

	const [showAlertResponse, setShowAlertResponse] = useState(false);

	const [apiResponse, setApiResponse] = useState<{
		type: AlertColor;
		message: string;
	}>({ type: 'success', message: '' });

	const shipmentProtection = shipment.insurance ? parseFloat(shipment.insurance.toString()) : 0.0;

	const disabledMessage = () => {
		return shipment.record_type !== 'Express Shipment'
			? 'Economy is a drop-off only service'
			: 'Pickups can only be changed for shipments pre-transit';
	};

	const disablePickupButton = () => {
		return (
			shipment.record_type !== 'Express Shipment' ||
			(shipment.event_status !== TrackingStatus.PreTransit && shipment.event_status !== 'TBD')
		);
	};

	const handleCancelShipment = async () => {
		try {
			setLoading(true);
			const response = await axiosInstance.post('/shipment/cancelShipment', {
				shipment_id: shipment.shipment_id
			});
			setCancelShipmentDisabled(true);
			setCancelDialogOpen(false);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setCancelDialogOpen(false);

			if (axios.isAxiosError(error) && error.response) {
				setApiResponse({
					type: 'error',
					message: `Error cancelling shipment: ${error.response.data.data}`
				});
			} else {
				setApiResponse({
					type: 'error',
					message: 'Error: An error has occurred when cancelling this shipment'
				});
			}

			setShowAlertResponse(true);
			console.error('Error cancelling shipment:', error);
		}
	};

	const getPickup = async () => {
		try {
			const response = await axiosInstance.post('/pickup/getExpressPickup', {
				pickup_id: shipment?.pickup_id || pickupId
			});

			const { id, min_datetime, max_datetime, confirmation } = response.data.data;

			setPickup({
				id: id,
				min_datetime: formatDateTime(min_datetime),
				max_datetime: formatDateTime(max_datetime),
				confirmation: confirmation
			});
		} catch (error) {
			console.error('Error fetching express pickup:', error);
		}
	};

	const getPaymentDetails = async () => {
		try {
			const response = await axios.get(
				`https://pos.snapscan.io/merchant/api/v1/payments?merchantReference=${shipment.shipment_id}`,
				{
					auth: {
						username: '9adc09f8-8030-4ea1-a40d-7a9fc67776fc',
						password: ''
					}
				}
			);

			if (response.data[0]?.status === 'completed') {
				setShipmentPaid(true);
			}
		} catch (error) {
			console.error('Error fetching payment details:', error);
		}
	};

	useEffect(() => {
		if (shipment.pickup_id || pickupId !== '') {
			getPickup();
		}

		getPaymentDetails();
	}, [pickupId]);

	// console.log(shipmentPaid);

	const handleDownload = () => {
		window.open(shipment?.source_api_label_url_pdf, '_blank');
		setDownloaded(true);
	};

	const handleCommercialDownload = () => {
		window.open(shipment?.order_comm_invoice as string, '_blank');
		setDownloaded(true);
	};

	const handleAlertClose = () => {
		setShowAlertResponse(false);
	};

	return (
		<div
			style={{
				display: 'block',
				justifyContent: 'center',
				alignContent: 'center',
				marginBottom: '60px',
				maxWidth: 700,
				marginLeft: 'auto',
				marginRight: 'auto'
			}}
		>
			{showAlertResponse && (
				<Alert onClose={handleAlertClose} severity={apiResponse.type} sx={{ width: '100%', marginTop: '10px' }}>
					{apiResponse.message}
				</Alert>
			)}

			{shipmentPaid && (
				<Grid container alignItems="center" direction="column">
					<Grid item>
						<Alert severity="success" sx={{ width: '100%', marginTop: '10px' }}>
							<Typography variant="h6">Payment Received</Typography>
						</Alert>
					</Grid>
				</Grid>
			)}

			<Grid container alignItems="center" direction="column">
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					sx={{ marginBottom: '20px', marginTop: '30px' }}
				>
					<Grid item>
						<Typography variant="h5">Shipment Details</Typography>
					</Grid>
					<Grid item sx={{ display: 'flex', alignItems: 'center' }}>
						{!shipmentPaid && (
							<div style={{ marginRight: '10px' }}>
								<SnapScanPayNow
									shipment={shipment}
									shipmentProtection={shipmentProtection}
									landedCosts={landedCosts}
									deliveryConfirmationCost={deliveryConfirmationCost}
								/>
							</div>
						)}
						<Tooltip
							title={
								cancelShipmentDisabled
									? 'Shipments can only be cancelled in a pre transit state within 30 days of being created'
									: ''
							}
						>
							<span>
								<Button
									disabled={cancelShipmentDisabled}
									sx={{
										backgroundColor: '#e82727',
										':hover': {
											backgroundColor: '#ff0000'
										},
										whiteSpace: 'nowrap'
									}}
									color="primary"
									variant="contained"
									size="small"
									onClick={() => {
										setCancelDialogOpen(true);
									}}
								>
									Cancel Shipment
								</Button>
							</span>
						</Tooltip>
					</Grid>
				</Grid>
				<Grid container direction="row" justifyContent="space-between" sx={{ paddingBottom: '20px' }}>
					<Grid item>Reference: {shipment.shipment_reference}</Grid>
					<Grid item> {new Date(shipment.date_created).toDateString()}</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Paper elevation={12} sx={{ backgroundColor: '#F5F5F5' }}>
							<Grid container direction={'row'} justifyContent="space-between" padding={2}>
								<Grid item>
									<Grid container direction="column" justifyContent="flex-start" maxWidth={'160px'}>
										<Grid item alignSelf="left" xs={12} sm={6} md={6} lg={6} xl={6}>
											<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
												To:
											</Typography>
										</Grid>
										<Grid item>
											<Typography variant="body1">{shipment.address_to_name}</Typography>
										</Grid>
										<Grid item>
											<Typography variant="body1">{shipment.address_to_company}</Typography>
										</Grid>
										<Grid item alignSelf="left">
											<Typography variant="body1">{shipment.address_to_street_1},</Typography>
										</Grid>
										{shipment.address_to_street_2 && (
											<Grid item alignSelf="left">
												<Typography variant="body1">{shipment.address_to_street_2},</Typography>
											</Grid>
										)}
										<Grid item alignSelf="left">
											<Typography variant="body1" paragraph={true}>
												{shipment.address_to_city_locality},{' '}
												{shipment.address_to_state_province}, {shipment.address_to_zip_postal},{' '}
												{shipment.address_to_country}{' '}
											</Typography>
											<Link
												href={'mailto:' + shipment.address_to_email}
												color="inherit"
												underline="always"
											>
												<Typography variant="body1">{shipment.address_to_email}</Typography>
											</Link>
											<Typography variant="body1">{shipment.address_to_phone}</Typography>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
									<Grid item alignSelf="left" xs={12} sm={6} md={6} lg={6} xl={6}>
										<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
											From:
										</Typography>
									</Grid>
									<Grid item>
										<Typography variant="body1">{shipment.address_from_name}</Typography>
									</Grid>
									<Grid item alignSelf="left">
										<Typography variant="body1">{shipment.address_from_street_1},</Typography>
									</Grid>

									<Grid item alignSelf="left">
										<Typography variant="body1" paragraph={true}>
											{shipment.address_from_city_locality},{' '}
											{shipment.address_from_state_province}, {shipment.address_from_zip_postal},{' '}
											{shipment.address_from_country}{' '}
										</Typography>
										<Link
											href={'mailto:' + shipment.address_from_email}
											color="inherit"
											underline="always"
										>
											<Typography variant="body1">{shipment.address_from_email}</Typography>
										</Link>
										<Typography variant="body1">{shipment.address_from_phone}</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid container direction={'row'} justifyContent="space-between" padding={2}>
								<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
									<Grid container direction="column">
										<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
											Parcel:
										</Typography>
										<Typography variant="body1">
											{shipment.parcel_length_cm}
											{'x'}
											{shipment.parcel_height_cm}
											{'x'}
											{shipment.parcel_width_cm}
											{' cm'}
										</Typography>
										<Typography variant="body1">
											Actual Weight: {shipment.parcel_weight_kg}
											{' kg'}
										</Typography>
										<Typography variant="body1">
											Volumetric Weight: {shipment.parcel_weight_vol_kg}
											{' kg'}
										</Typography>

										{/* <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
											Customer Details:
										</Typography>
										<Link
											href={'mailto:' + shipment.address_to_email}
											color="inherit"
											underline="always"
										>
											<Typography variant="body1">{shipment.address_to_email}</Typography>
										</Link>
										<Typography variant="body1">{shipment.address_to_phone}</Typography> */}
									</Grid>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
									<Grid container direction="column">
										<Grid item alignSelf="left">
											<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
												Costs:
											</Typography>
										</Grid>

										<CostItem costPrice={shipment.rate_charged} costLabel={text.SHIPPING_COST} />

										<CostItem costPrice={shipmentProtection} costLabel={text.SHIPPING_PROTECTION} />

										<CostItem costPrice={landedCosts} costLabel={text.DFT} />

										<CostItem
											costPrice={deliveryConfirmationCost}
											costLabel={text.SIGNATURE_REQUIRED}
										/>

										<Grid item>
											<Typography variant="body1" sx={{ fontWeight: 'bold' }}>
												Total Cost: {'R'}
												{(
													parseFloat(shipmentProtection.toFixed(2)) +
													parseFloat(shipment.rate_charged) +
													landedCosts +
													deliveryConfirmationCost
												).toFixed(2)}
											</Typography>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={6} xl={6} mt={2}>
									<Grid container direction="column">
										<Grid item alignSelf="left">
											<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
												Pickup:
											</Typography>
										</Grid>
										<Grid item>
											{pickup.id.length === 0 ? (
												<Typography variant="body1">No scheduled pickup</Typography>
											) : (
												<>
													<Typography variant="body1">
														Pickup Min: {pickup?.min_datetime}
													</Typography>
													<Typography variant="body1">
														Pickup Max: {pickup?.max_datetime}
													</Typography>
													<Typography variant="body1">
														Confirmation Code: {pickup?.confirmation}
													</Typography>
												</>
											)}
											<Tooltip title={disablePickupButton() ? disabledMessage() : ''}>
												<span>
													<Button
														sx={{
															whiteSpace: 'nowrap',
															marginTop: '10px'
														}}
														disabled={disablePickupButton()}
														color="primary"
														variant="contained"
														size="small"
														onClick={() => {
															setPickupModalOpen(true);
														}}
													>
														{pickup.id.length === 0 && pickupId === ''
															? 'Schedule Pickup'
															: 'Reschedule Pickup'}
													</Button>
												</span>
											</Tooltip>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
						<Grid
							container
							direction={'row'}
							justifyContent="space-between"
							textAlign={'center'}
							mt={4}
							mb={2}
						>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography color={'primary'} variant={'h6'}>
									Tracking information to add to your ecommerce store:
								</Typography>
							</Grid>
						</Grid>

						<Grid container>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Paper elevation={12} sx={{ backgroundColor: '#F5F5F5' }}>
									<Grid container direction={'row'} justifyContent="space-between" padding={2}>
										<Grid item>
											<Grid
												container
												direction="column"
												justifyContent="flex-start"
												maxWidth={'160px'}
											>
												<Grid item alignSelf="left" xs={12} sm={6} md={6} lg={6} xl={6}>
													<Grid container direction={'row'} justifyContent="space-between">
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
																Tracking Code:
															</Typography>
														</Grid>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<Typography variant="body1" noWrap={true}>
																{shipment.carrier_track_code}
															</Typography>
														</Grid>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<CopyToClipboard text={shipment.carrier_track_code}>
																<IconButton color="primary" component="span">
																	<CopyAll />
																	<Typography>Copy to Clipboard</Typography>
																</IconButton>
															</CopyToClipboard>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
											<Grid container direction="row">
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
														Tracking URL:
													</Typography>
												</Grid>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<Link href={shipment.tracker_url} rel="noopener" target="_blank">
														<Typography
															sx={{ fontSize: '12px', wordWrap: 'break-word' }}
															variant="body1"
														>
															{shipment.tracker_url}
														</Typography>
													</Link>
												</Grid>
												<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
													<CopyToClipboard text={shipment.tracker_url}>
														<IconButton color="primary" component="span">
															<CopyAll />
															<Typography>Copy to Clipboard</Typography>
														</IconButton>
													</CopyToClipboard>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						</Grid>

						<Grid
							container
							direction={'row'}
							justifyContent="space-around"
							mt={10}
							style={{ width: '100%' }}
						>
							<Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Paper elevation={12} sx={{ backgroundColor: '#F5F5F5' }}>
										<Grid container direction={'row'} justifyContent="space-between" padding={2}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Grid
													container
													direction="column"
													justifyContent="flex-start"
													maxWidth={'100%'}
												>
													{shipment.record_type === 'Shipment' ? (
														<Grid item alignSelf="left">
															<Grid item direction="column" maxWidth={'100%'}>
																<Stack direction="row" alignItems="center" gap={1}>
																	<ErrorIcon />
																	<Typography>Next Steps:</Typography>
																</Stack>
																<ol>
																	<li>
																		Print this label and attach it to your parcel.
																	</li>
																	<li>
																		Send the parcel to{' '}
																		<a
																			href="https://help.tunl.to/en/article/office-address-1h1cuh1/"
																			target="_blank"
																			rel="noreferrer"
																			style={{ color: '#50B4BD' }}
																		>
																			TUNL, UNIT 52, 22 Cumberland Road, Paarden
																			Eiland, Cape Town, 7405
																		</a>
																	</li>
																	<li>
																		Add the tracking information above to your
																		webstore
																	</li>
																</ol>
																<Stack direction="row" alignItems="center" gap={1}>
																	<WarningIcon />
																	<Typography>
																		{' '}
																		Weekly cut-off time close of business Tuesday.
																	</Typography>
																</Stack>
															</Grid>
														</Grid>
													) : (
														<Grid item alignSelf="left">
															<Grid item direction="column" maxWidth={'100%'}>
																<Stack direction="row" alignItems="center" gap={1}>
																	<ErrorIcon />
																	<Typography>Next Steps:</Typography>
																</Stack>
																<ol>
																	<li>
																		Click Download Label and Download Invoice below.
																	</li>
																	<li>
																		Print this label and the invoice and attach both
																		to your parcel. (If UPS: Attach 2 physical
																		copies of the invoice to the parcel)
																	</li>
																	<li>
																		Add the tracking information above to your
																		webstore.
																	</li>
																	<li>
																		If you have scheduled a pick up please have your
																		parcel ready for collection for the selected
																		time slot.
																	</li>
																	<li>
																		If you have selected "I am sending my parcel to
																		TUNL for pick up" - please send the parcel to
																		our warehouse{' '}
																		<a
																			href="https://help.tunl.to/en/article/office-address-1h1cuh1/"
																			target="_blank"
																			rel="noreferrer"
																			style={{ color: '#50B4BD' }}
																		>
																			(TUNL, UNIT 52, 22 Cumberland Road, Paarden
																			Eiland, Cape Town, 7405)
																		</a>
																		.
																	</li>
																</ol>
															</Grid>
														</Grid>
													)}
												</Grid>
											</Grid>
										</Grid>
									</Paper>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid
						container
						direction={'row'}
						justifyContent="space-around"
						mt={4}
						mb={10}
						padding={2}
						spacing={1}
					>
						<Grid item xs={10} sm={10} md={4} lg={4} xl={4}>
							<Button
								color="primary"
								variant="contained"
								sx={{ borderRadius: '30px', width: '100%' }}
								onClick={handleDownload}
							>
								<Download />
								<Typography>Download Label</Typography>
							</Button>
						</Grid>
						<Grid item xs={10} sm={10} md={4} lg={4} xl={4}>
							<Button
								color="primary"
								variant="contained"
								disabled={!shipment.order_comm_invoice}
								sx={{
									borderRadius: '30px',
									width: '100%'
								}}
								onClick={handleCommercialDownload}
							>
								Download Invoice
							</Button>
						</Grid>
						<Grid item xs={10} sm={10} md={4} lg={4} xl={4}>
							<Button
								color="primary"
								variant="contained"
								sx={{
									borderRadius: '30px',
									width: '100%'
								}}
								onClick={() => {
									if (!downloaded) {
										setDownloaded(true);
									} else {
										navigate('/');
									}
								}}
							>
								Done
							</Button>
						</Grid>
						{!shipment.order_comm_invoice && (
							<Grid item mt={2}>
								<Alert severity="warning">
									This destination country does not support electronically generated invoices. Please
									attach your own copy of your commercial invoice to your parcel.
								</Alert>
							</Grid>
						)}
						<Dialog open={!downloaded} onClose={() => setDownloaded(false)}>
							<NextStepsModal shipment={shipment} downloaded={downloaded} setDownloaded={setDownloaded} />
						</Dialog>
						<Grid item mt={2}>
							<object
								data={shipment.source_api_label_url_pdf}
								type="application/pdf"
								width="500px"
								height="500px"
							></object>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Dialog
				open={pickupModalOpen}
				onClose={() => setPickupModalOpen(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<PickupModal
					shipment={shipment}
					setPickupModalOpen={setPickupModalOpen}
					setSnackAlert={setSnackAlert}
					setOpenSnackBar={setOpenSnackBar}
					setPickupId={setPickupId}
				/>
			</Dialog>
			<Dialog
				open={cancelDialogOpen}
				onClose={() => setCancelDialogOpen(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				fullWidth={true}
			>
				<DialogTitle id="modal-modal-title">Cancel Shipment</DialogTitle>
				<DialogContent>
					{loading ? (
						<Box sx={{ display: 'flex', justifyContent: 'center' }} m={3}>
							<CircularProgress disableShrink size={30} />
						</Box>
					) : (
						<DialogContentText>
							Are you sure you want to cancel this shipment? This action cannot be undone.
						</DialogContentText>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						disabled={loading}
						onClick={() => {
							setCancelDialogOpen(false);
						}}
					>
						Back
					</Button>
					<Button
						disabled={loading}
						color="error"
						variant="contained"
						onClick={() => {
							handleCancelShipment();
						}}
					>
						Cancel Shipment
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={openSnackBar}
				autoHideDuration={2000}
				onClose={handleAlertClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleAlertClose} severity={snackAlert.type} sx={{ width: '100%' }}>
					{snackAlert.message}
				</Alert>
			</Snackbar>
		</div>
	);
}
