import { FlightTakeoff, ShoppingBasket } from '@mui/icons-material';
import { Button, Divider, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserMerchantRecord } from '../../types/user';
import world from '../../img/world.png';
import usa from '../../img/usa.png';

export default function FirstShipment({ merchant }: { merchant: UserMerchantRecord }) {
	const navigate = useNavigate();

	return (
		<Grid container direction="column" pl={2}>
			<Grid item></Grid>
			<Grid item mt={5} p={2}>
				<Grid container direction="column" spacing={2} p={5} justifyContent={'center'} component={Paper}>
					<Grid item>
						<Grid container direction="row" spacing={2}>
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container direction="column" spacing={2} textAlign="center" alignContent="center">
									<Grid item>
										<FlightTakeoff color="action" sx={{ fontSize: '4em' }} />
									</Grid>
									<Grid item>
										<Typography textAlign="center" fontSize="21px" fontWeight="bold" sx={{ mb: 2 }}>
											Book your first shipment
										</Typography>
										<Typography textAlign="center" fontSize="14px" fontWeight="bold" sx={{ mb: 2 }}>
											You haven't booked any shipments yet.
											<br />
											Start by booking your first shipment today.
										</Typography>
										<Button
											variant="contained"
											color="primary"
											// sx={{
											// 	backgroundColor: '#000',
											// 	color: 'white'
											// }}
											onClick={() => navigate('/shipment')}
										>
											Book Shipment
										</Button>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<Grid container direction="column" textAlign="center" alignContent="center" spacing={2}>
									<Grid item>
									<ShoppingBasket color="action" sx={{ fontSize: '4em' }} />
									</Grid>
									<Grid item>
										<Typography textAlign="center" fontSize="21px" fontWeight="bold" sx={{ mb: 2 }}>
											Add a product to your Library
										</Typography>
										<Typography textAlign="center" fontSize="14px" fontWeight="bold" sx={{ mb: 2 }}>
											Add a product to your library to make
											<br />
											booking your next shipment quick and easy.
										</Typography>
										<Button
											variant="contained"
											color="primary"
											// sx={{
											// 	backgroundColor: '#000',
											// 	color: 'white'
											// }}
											onClick={() => navigate('/products')}
										>
											Add Product
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
