import { Alert, Box, Button, Grid, Modal, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { Download } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function NextStepsModal(props: any) {
	const [modalOpen, setModalOpen] = useState(true);
	const handleLeaveClose = () => setModalOpen(false);
	const { shipment, downloaded, setDownloaded } = props;
	const theme = useTheme();
	const isXsSmMd = useMediaQuery(theme.breakpoints.up('md'));
	const resetModalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 'auto',
		height: 'auto',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 4,
		px: 4,
		pb: 3
	};

	const resetModalStyleSm = {
		position: 'absolute' as 'absolute',
		top: '10%',
		left: '10%',
		right: '10%',
		bottom: 'auto',
		transform: 'auto',
		width: 'auto',
		height: 'auto',
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 4,
		px: 4,
		pb: 3
	};

	const handleDownload = () => {
		window.open(shipment?.source_api_label_url_pdf, '_blank');
	};

	const handleCommercialDownload = () => {
		window.open(shipment?.order_comm_invoice as string, '_blank');
	};

	const handleClickAway = () => {
		if (!downloaded) {
			setDownloaded(true);
		}
	};

	return (
		<Modal
			open={modalOpen}
			onClose={handleClickAway}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Grid container alignItems="center" direction="row">
				<Box sx={isXsSmMd ? resetModalStyle : resetModalStyleSm} style={{ border: 'none', boxShadow: '1px 1px solid gray' }}>
					<Grid container alignItems="center" direction="column">
						<Grid
							item
							width={'90%'}
							maxWidth={'768px'}
							overflow-y={'auto'}
						>
							{shipment.record_type === 'Shipment' ? (
								<Grid item alignSelf="center">
									<Grid item direction="column">
										<Stack direction="row" alignItems="center" gap={1}>
											<ErrorIcon />
											<Typography>Next Steps:</Typography>
										</Stack>
										<ol>
											<li>Print this label and attach it to your parcel.</li>
											<li>
												Send the parcel to{' '}
												<a
													href="https://help.tunl.to/en/article/office-address-1h1cuh1/"
													target="_blank"
													rel="noreferrer"
													style={{ color: '#50B4BD' }}
												>
													TUNL, UNIT 52, 22 Cumberland Road, Paarden Eiland, Cape Town, 7405
												</a>
											</li>
											<li>Add the tracking information above to your webstore</li>
										</ol>
										<Stack direction="row" alignItems="center" gap={1}>
											<WarningIcon />
											<Typography> Weekly cut-off time close of business Tuesday.</Typography>
										</Stack>
									</Grid>
								</Grid>
							) : (
								<Grid item alignSelf="center">
									<Grid item direction="column">
										<Stack direction="row" alignItems="center" gap={1}>
											<ErrorIcon />
											<Typography>Next Steps:</Typography>
										</Stack>
										<ol>
											<li>Click Download Label and Download Invoice below.</li>
											<li>
												Print this label and the invoice and attach both to your parcel. (If
												UPS: Attach 2 physical copies of the invoice to the parcel)
											</li>
											<li>Add the tracking information above to your webstore.</li>
											<li>
												If you have scheduled a pick up please have your parcel ready for
												collection for the selected time slot.
											</li>
											<li>
												If you have selected "I am sending my parcel to TUNL for pick up" -
												please send the parcel to our warehouse{' '}
												<a
													href="https://help.tunl.to/en/article/office-address-1h1cuh1/"
													target="_blank"
													rel="noreferrer"
													style={{ color: '#50B4BD' }}
												>
													(TUNL, UNIT 52, 22 Cumberland Road, Paarden Eiland, Cape Town, 7405)
												</a>
												.
											</li>
										</ol>
									</Grid>
								</Grid>
							)}
						</Grid>
						<Grid container>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid
									container
									direction={'column'}
									mt={4}
									mb={10}
									spacing={2}
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
									justifyContent={'center'}
									alignItems={'center'}
								>
									<Grid item direction={'column'}>
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4} mb={2}>
											<Button
												color="primary"
												variant="contained"
												sx={{ borderRadius: '30px', width: '100%' }}
												onClick={handleDownload}
											>
												<Download />
												<Typography>Download Label</Typography>
											</Button>
										</Grid>
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4} mb={2}>
											<Button
												color="primary"
												variant="contained"
												disabled={!shipment.order_comm_invoice}
												sx={{
													borderRadius: '30px',
													width: '100%'
												}}
												onClick={handleCommercialDownload}
											>
												Download Invoice
											</Button>
										</Grid>
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<Button
												color="primary"
												variant="contained"
												sx={{
													borderRadius: '30px',
													width: '100%'
												}}
												onClick={() => {
													setDownloaded(true);
												}}
											>
												Done
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</Modal>
	);
}
