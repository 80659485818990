import React from 'react';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { ThemeProvider as AmplifyTheme, Authenticator, createTheme as createAmplifyTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Crisp } from 'crisp-sdk-web';
import { PostHogProvider} from 'posthog-js/react'
import posthog from 'posthog-js';

import './index.css';

import Tracker from './pages/Merchant/Tracker';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import awsExports from './aws-exports';
import TrackerLayout from './components/Layout/TrackerLayout';
import Main from './pages/Main';
import getAmplifyTheme from './util/amplifyTheme';
import Hotjar from '@hotjar/browser';

const siteId: any = process.env.REACT_APP_HOTJAR_SITEID;
const hotjarVersion = 6;

posthog.init(
	process.env.REACT_APP_PUBLIC_POSTHOG_KEY || '',
	{
	  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
	  session_recording: {
        	maskAllInputs: false
    	},
	}
);

Hotjar.init(siteId, hotjarVersion);

const container = document.getElementById('root')!;
const root = createRoot(container);
const theme = createTheme({
	palette: {
		primary: {
			main: '#007BC4'
		},
		secondary: {
			main: '#ffffff'
		},
	},
	typography: {
		fontSize: 12,
		fontFamily: ['Nunito', 'sans-serif'].join(',')
	},
	// make  dropdowns have font size 10
	components: {
		MuiSelect: {
			styleOverrides: {
				select: {
					fontSize: 12
				}
			}
		}
	}
});

const amplifyTheme = createAmplifyTheme(getAmplifyTheme(theme));

Crisp.configure('3eb766dc-3955-4f82-a6c1-e3c967765c8a');
Amplify.configure(awsExports);

root.render(
	<>
		<Provider store={store}>
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<CssBaseline>
						<AmplifyTheme theme={ amplifyTheme }>
							{window.location.pathname.split('/')[1] === 'tracker' ? (
								<TrackerLayout>
									<Routes>
										<Route path="/tracker/:trackerId" element={<Tracker />} />
										<Route path="/tracker/" element={<Tracker />} />
									</Routes>
								</TrackerLayout>
							) : (
								<Authenticator.Provider>
									<PostHogProvider client={posthog} >
										<Main/>
									</PostHogProvider>
								</Authenticator.Provider>
							)}
						</AmplifyTheme>
					</CssBaseline>
				</ThemeProvider>
			</BrowserRouter>
		</Provider>
	</>
);
