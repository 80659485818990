import {
	Alert,
	AlertTitle,
	Button,
	CircularProgress,
	FormControlLabel,
	Grid,
	MenuItem,
	Switch,
	TextField,
	Typography
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 } from 'uuid';
import AuthenticationContainer from '../../components/Layout/AuthenticationContainer';
import MerchantAddress from '../../components/Merchant/MerchantAddress';
import { axiosConfig, useAuthToken } from '../../constants/axios';
import {
	ALERT,
	BUTTON,
	CREATE_MERCHANT,
	createElementId,
	DROPDOWN,
	FORM_CONTROL,
	FORM_CONTROL_LABEL,
	HEADING,
	INFO,
	LABEL,
	LINK,
	MENU_ITEM,
	SELECT,
	SWITCH,
	TEXTFIELD,
	TITLE
} from '../../constants/id';

export default function CreateMerchant(props: any) {
	const {
		register,
		setValue,
		watch,
		trigger,
		formState: { errors },
		handleSubmit
	} = useForm({
		mode: 'all',
		defaultValues: {
			store_domain: '',
			user_id: props.user.user_id,
			merchant_id: v4(),
			merchant_name: '',
			merchant_email: '',
			merchant_phone: '',
			merchant_vat_number: '',
			merchant_personal_id: '',
			merchant_exporters_code: '',
			merchant_heard_about_from: '',
			merchant_heard_about_from_other: '',
			merchant_shipments_per_month: ''
		}
	});

	const [loading, setLoading] = useState(false);
	const [hasVATCode, setHasVATCode] = useState(false);
	const [hasExportersCode, setHasExportersCode] = useState(true);
	const token = useAuthToken();
	const axiosInstance = axiosConfig(token);

	const handleFormSubmit = async (event: any) => {
		setLoading(true);
		try {
			let response = await axiosInstance.post('/merchant/createMerchant', [event]);
			const merchant = response.data.merchantRecords[0];
			props.setMerchant(merchant);

			sessionStorage.setItem('activeUserMerchant', JSON.stringify(merchant));
			sessionStorage.setItem('userMerchants', JSON.stringify(response.data.merchantRecords));
			sessionStorage.setItem('merchant', JSON.stringify(merchant));
			//add user to crisp
			await axiosInstance.post(`/user/createCRMUser?userId=${props.user.user_id}`, event);

			setLoading(false);
			window.location.reload();
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	const heardAboutFrom = watch('merchant_heard_about_from');

	return (
		<div>
			<AuthenticationContainer text="Sign up for free and book your first parcel in under 2 minutes.">
				<Grid
					container
					direction="column"
					alignContent="center"
					justifyContent="center"
					lineHeight={5}
					paddingTop={3}
					paddingLeft={3}
					spacing={2}
					sx={{ width: '100%' }}
				>
					<form
						onSubmit={handleSubmit(handleFormSubmit)}
						style={{
							width: '100%',
							height: 'auto',
							maxHeight: 'auto',
							maxWidth: 'auto',
							overflow: 'scroll'
						}}
					>
						<Typography
							id={createElementId([CREATE_MERCHANT, FORM_CONTROL_LABEL, HEADING, 'company_information'])}
							variant="h4"
							textAlign="center"
						>
							Company Information
						</Typography>
						<Typography
							id={createElementId([
								CREATE_MERCHANT,
								FORM_CONTROL,
								LABEL,
								'company_information_description'
							])}
							fontWeight="bold"
							textAlign="center"
						>
							Provide your company information to create your free account and get shipping in seconds.
						</Typography>
						<Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} mt={2} mb={2}>
							<TextField
								id={createElementId([CREATE_MERCHANT, FORM_CONTROL, TEXTFIELD, 'company_name'])}
								required
								label="Company Name"
								fullWidth
								{...register('merchant_name', {})}
								error={!!errors?.merchant_name}
								helperText={
									errors?.merchant_name
										? (errors.merchant_name.message as string)
										: "This will appear on shipping documents. Don't worry, you can change it later"
								}
							/>
						</Grid>
						<Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} mb={2}>
							<TextField
								id={createElementId([CREATE_MERCHANT, FORM_CONTROL, TEXTFIELD, 'merchant_email'])}
								required
								label="Accounts Email"
								{...register('merchant_email', {
									pattern: {
										value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
										message: 'Invalid email address'
									}
								})}
								error={!!errors?.merchant_email}
								helperText={
									errors?.merchant_email
										? (errors.merchant_email.message as string)
										: 'We will send invoices/statements to this email address'
								}
								fullWidth
							/>
						</Grid>
						<Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} mb={2}>
							<TextField
								id={createElementId([CREATE_MERCHANT, FORM_CONTROL, TEXTFIELD, 'merchant_phone'])}
								required
								label="Phone"
								{...register('merchant_phone', {
									pattern: {
										value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/im,
										message: 'Invalid Phone number'
									}
								})}
								helperText={
									errors?.merchant_phone
										? (errors.merchant_phone.message as string)
										: 'We will call this number if we need to confirm any shipment details'
								}
								fullWidth
							/>
						</Grid>
						<Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}>
							<Grid container direction="column" spacing={0} marginBottom={2}>
								<FormControlLabel
									id={createElementId([CREATE_MERCHANT, FORM_CONTROL, FORM_CONTROL_LABEL, 'vat'])}
									label="Are you registered for VAT?"
									control={
										<Switch
											id={createElementId([
												CREATE_MERCHANT,
												FORM_CONTROL,
												FORM_CONTROL_LABEL,
												SWITCH,
												'vat'
											])}
											checked={hasVATCode}
											onChange={e => setHasVATCode(e.target.checked)}
										/>
									}
								/>

								<FormControlLabel
									id={createElementId([
										CREATE_MERCHANT,
										FORM_CONTROL,
										FORM_CONTROL_LABEL,
										'exporters_code'
									])}
									label="Do you have an Exporters code?"
									control={
										<Switch
											id={createElementId([
												CREATE_MERCHANT,
												FORM_CONTROL,
												SWITCH,
												'exporters_code'
											])}
											checked={hasExportersCode}
											onChange={e => setHasExportersCode(e.target.checked)}
										/>
									}
								/>
							</Grid>
							{hasVATCode && (
								<Grid item>
									<TextField
										id={createElementId([CREATE_MERCHANT, FORM_CONTROL, TEXTFIELD, 'vat_number'])}
										label="Vat Number"
										fullWidth
										{...register('merchant_vat_number', {})}
									/>
								</Grid>
							)}
							{hasExportersCode && (
								<Grid item>
									<TextField
										id={createElementId([
											CREATE_MERCHANT,
											FORM_CONTROL,
											TEXTFIELD,
											'exporters_code'
										])}
										label="Exporter's Code"
										required={hasExportersCode}
										{...register('merchant_exporters_code', {
											pattern: {
												value: /^[a-zA-Z0-9]{8,10}$/im,
												message: 'Invalid Exporters Code'
											}
										})}
										error={!!errors?.merchant_exporters_code}
										fullWidth
									/>
								</Grid>
							)}
							{!hasExportersCode && (
								<Grid item>
									<TextField
										id={createElementId([CREATE_MERCHANT, FORM_CONTROL, TEXTFIELD, 'id_number'])}
										required={!hasExportersCode}
										label="ID Number"
										fullWidth
										{...register('merchant_personal_id', {
											pattern: {
												value: /^[0-9]{13}$/im,
												message: 'Invalid Id Number'
											}
										})}
										error={!!errors?.merchant_personal_id}
										helperText={
											errors?.merchant_personal_id
												? (errors.merchant_personal_id.message as string)
												: null
										}
									/>
								</Grid>
							)}
						</Grid>
						<Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}>
							<Grid container direction="column" spacing={0}>
								<Typography
									id={createElementId([CREATE_MERCHANT, FORM_CONTROL, LABEL, 'physical_address'])}
									fontWeight="bold"
									textAlign="center"
									mb={2}
								>
									Provide your physical address. This is required for getting shipping rates and
									scheduling pickups.
								</Typography>
								<MerchantAddress
									errors={errors}
									register={register}
									setValue={setValue}
									trigger={trigger}
									watch={watch}
								/>
							</Grid>
						</Grid>
						<Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} mb={2}>
							<Grid container direction="column" spacing={0}>
								<Typography
									id={createElementId([CREATE_MERCHANT, FORM_CONTROL, LABEL, 'shipment_information'])}
									fontWeight="bold"
									textAlign="center"
								>
									How many shipments are you currently exporting every month?
								</Typography>
								<Typography
									id={createElementId([
										CREATE_MERCHANT,
										FORM_CONTROL,
										LABEL,
										'shipment_information_description'
									])}
									textAlign="center"
									mb={2}
								>
									Your response won't impact our rates, it simply helps us understand you better.
								</Typography>
								<TextField
									id={createElementId([
										CREATE_MERCHANT,
										TEXTFIELD,
										SELECT,
										DROPDOWN,
										'shipments_per_month'
									])}
									select
									label="Number of shipments"
									fullWidth
									defaultValue={'None'}
									value={watch('merchant_shipments_per_month')}
									{...register('merchant_shipments_per_month', {})}
								>
									<MenuItem
										id={createElementId([
											CREATE_MERCHANT,
											TEXTFIELD,
											SELECT,
											DROPDOWN,
											MENU_ITEM,
											'none'
										])}
										value="None"
									>
										I am currently not shipping internationally
									</MenuItem>
									<MenuItem
										id={createElementId([
											CREATE_MERCHANT,
											TEXTFIELD,
											SELECT,
											DROPDOWN,
											MENU_ITEM,
											'1-5'
										])}
										value="1 to 5"
									>
										1 - 5 shipments per month
									</MenuItem>
									<MenuItem
										id={createElementId([
											CREATE_MERCHANT,
											TEXTFIELD,
											SELECT,
											DROPDOWN,
											MENU_ITEM,
											'5-10'
										])}
										value="5 to 10"
									>
										5 - 10 shipments per month
									</MenuItem>
									<MenuItem
										id={createElementId([
											CREATE_MERCHANT,
											TEXTFIELD,
											SELECT,
											DROPDOWN,
											MENU_ITEM,
											'10-20'
										])}
										value="10 to 20"
									>
										10 - 20 shipments per month
									</MenuItem>
									<MenuItem
										id={createElementId([
											CREATE_MERCHANT,
											TEXTFIELD,
											SELECT,
											DROPDOWN,
											MENU_ITEM,
											'20-50'
										])}
										value="20 to 50"
									>
										20 - 50 shipments per month
									</MenuItem>
									<MenuItem
										id={createElementId([
											CREATE_MERCHANT,
											TEXTFIELD,
											SELECT,
											DROPDOWN,
											MENU_ITEM,
											'50+'
										])}
										value="50+"
									>
										50+ shipments per month
									</MenuItem>
								</TextField>
							</Grid>
						</Grid>
						<Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'} mb={2}>
							<Grid container direction="column" spacing={0}>
								<Typography
									id={createElementId([CREATE_MERCHANT, FORM_CONTROL, LABEL, 'store_domain'])}
									fontWeight="bold"
									textAlign="center"
								>
									Store Domain
								</Typography>
								<Typography
									id={createElementId([
										CREATE_MERCHANT,
										FORM_CONTROL,
										LABEL,
										'store_domain_description'
									])}
									textAlign="center"
									mb={2}
								>
									Please provide the domain of your store. This will help merchants find your store.
								</Typography>
								<TextField
									id={createElementId([
										CREATE_MERCHANT,
										TEXTFIELD,
										SELECT,
										DROPDOWN,
										'store_domain'
									])}
									label="Store Domain"
									fullWidth
									{...register('store_domain', {
										pattern: {
											value: /^(http|https):\/\/[^ "]+$/im,
											message: 'Invalid Store Domain'
										}
									})}
									error={!!errors?.store_domain}
									helperText={
										errors?.store_domain
											? (errors.store_domain.message as string)
											: 'Please provide the domain of your store'
									}
								/>
							</Grid>
						</Grid>
						<Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}>
							<Grid container direction="column" spacing={1}>
								<Grid item>
									<Typography fontWeight="bold" textAlign="center">
										How did you hear about TUNL? This enables us to help more merchants like you
									</Typography>
								</Grid>
								<Grid item>
									<TextField
										id={createElementId([
											CREATE_MERCHANT,
											TEXTFIELD,
											SELECT,
											DROPDOWN,
											'heard_about_from'
										])}
										select
										label="Please choose one..."
										value={watch('merchant_heard_about_from')}
										{...register('merchant_heard_about_from', {})}
										fullWidth
									>
										<MenuItem
											id={createElementId([
												CREATE_MERCHANT,
												TEXTFIELD,
												SELECT,
												DROPDOWN,
												MENU_ITEM,
												'customer_referral'
											])}
											value="customer_referral"
										>
											Referral from existing customer
										</MenuItem>
										<MenuItem
											id={createElementId([
												CREATE_MERCHANT,
												TEXTFIELD,
												SELECT,
												DROPDOWN,
												MENU_ITEM,
												'email_marketing'
											])}
											value="email_marketing"
										>
											Email Marketing
										</MenuItem>
										<MenuItem
											id={createElementId([
												CREATE_MERCHANT,
												TEXTFIELD,
												SELECT,
												DROPDOWN,
												MENU_ITEM,
												'social_media'
											])}
											value="social_media"
										>
											Social Media (LinkedIn, Instagram, etc.)
										</MenuItem>
										<MenuItem
											id={createElementId([
												CREATE_MERCHANT,
												TEXTFIELD,
												SELECT,
												DROPDOWN,
												MENU_ITEM,
												'search_engine'
											])}
											value="search_engine"
										>
											Search Engine (Google, Yahoo, etc.)
										</MenuItem>
										<MenuItem
											id={createElementId([
												CREATE_MERCHANT,
												TEXTFIELD,
												SELECT,
												DROPDOWN,
												MENU_ITEM,
												'other'
											])}
											value="other"
										>
											Other
										</MenuItem>
									</TextField>
								</Grid>
								{(heardAboutFrom === 'other' || heardAboutFrom === 'customer_referral') && (
									<Grid item>
										<TextField
											id={createElementId([CREATE_MERCHANT, TEXTFIELD, 'heard_about_from_other'])}
											label={
												heardAboutFrom === 'other'
													? 'Tell us how you heard about TUNL'
													: 'Let us know who referred you'
											}
											{...register('merchant_heard_about_from_other', {})}
											fullWidth
										/>
									</Grid>
								)}
							</Grid>
						</Grid>
						<Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'} xl={'auto'}>
							<Typography fontWeight="bold" textAlign="center">
								<span>
									By creating an account you agree to our{' '}
									<a
										id={createElementId([CREATE_MERCHANT, LINK, 'terms_of_service'])}
										href="https://www.tunl.to/terms"
										rel="noreferrer"
										target="_blank"
									>
										Terms of Service
									</a>
								</span>
							</Typography>
						</Grid>
						<Grid item mt={1} mb={1}>
							<Alert id={createElementId([CREATE_MERCHANT, ALERT, INFO, 'eligibility'])} severity="info">
								<AlertTitle
									id={createElementId([CREATE_MERCHANT, ALERT, INFO, TITLE, 'eligibility_title'])}
								>
									Eligibility Criteria
								</AlertTitle>
								We exclusively assist South African businesses, artists, makers, and manufacturers
								selling products internationally. We do not support food manufacturers, individuals
								seeking to send gifts, or primarily importers. For assistance, please reach out to{' '}
								<a
									id={createElementId([CREATE_MERCHANT, LINK, 'support'])}
									href="mailto:support@tunl.to"
								>
									support@tunl.to
								</a>
								.
							</Alert>
						</Grid>
						<Grid
							item
							style={{
								paddingTop: '0px'
							}}
						>
							<Button
								id={createElementId([CREATE_MERCHANT, BUTTON, 'create_account'])}
								fullWidth
								type="submit"
								variant="contained"
								disabled={loading}
							>
								{loading && (
									<CircularProgress
										size="1rem"
										color="inherit"
										sx={{
											marginRight: '10px'
										}}
									/>
								)}
								Create Account
							</Button>
						</Grid>
					</form>
				</Grid>
			</AuthenticationContainer>
		</div>
	);
}
