import {
	AccountCircle,
	Add,
	Flight,
	Handyman,
	Home,
	HouseSharp,
	Inventory,
	Logout,
	MenuBook,
	QuestionMark,
	Storefront,
	SupervisedUserCircle
} from '@mui/icons-material';
import PinDropIcon from '@mui/icons-material/PinDrop';
import {
	Alert,
	AlertColor,
	Backdrop,
	Box,
	CircularProgress,
	Grid,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Snackbar,
	SwipeableDrawer,
	Toolbar,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { toLower } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../app/store';
import { axiosConfig, useAuthToken } from '../../constants/axios';
import { ADMIN, BUTTON, createElementId, LIST_ITEM, SIDEMENU } from '../../constants/id';
import { setAlertBoolean } from '../../stores/alert';
import { setMerchant } from '../../stores/merchant';
import Footer from './Footer';
import Header from './Header';

interface AppProps {
	children: React.ReactNode;
	user: any;
	signOut: any;
	selectedMerchant: any;
}

export default function Layout({ children, user, signOut, selectedMerchant }: AppProps) {
	const user_value = JSON.parse(sessionStorage.getItem('user') as string);
	const token = useAuthToken();
	const axiosInstance = axiosConfig(token);
	const [merchants, setMerchants] = useState(JSON.parse(sessionStorage.getItem('merchant') as string));
	const [loading, setLoading] = useState(true);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [apiResponse, setApiResponse] = useState<{
		type: AlertColor;
		message: string;
	}>({ type: 'success', message: '' });
	const theme = useTheme();
	const isXsSmMd = useMediaQuery(theme.breakpoints.up('md'));
	const [open, setState] = useState(isXsSmMd ? true : false);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [selectedIndex, setSelectedIndex] = useState<number>();
	const alertBoolean = useSelector((state: RootState) => state.alert.alertBoolean);
	const { pathname } = useLocation();

	if (pathname === '/shipment/details' || pathname === '/shipment/details') {
		dispatch(setAlertBoolean('false'));
	}

	const alertUser = (e: { preventDefault: () => void; returnValue: string }) => {
		e.preventDefault();
		e.returnValue = '';
	};

	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (
			event &&
			event.type === 'keydown' &&
			((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
		) {
			return;
		}
		setState(open);
	};

	/**
	 *
	 * @param event Handles when the menu items are clicked to navigate to the page
	 * @param index
	 */

	const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		setSelectedIndex(index);
		if (index === 8) {
			sessionStorage.clear();
			dispatch(setMerchant({}));
			signOut();
		}
		if (index === 7) {
			window.open('https://www.tunl.to/tools?signedin=true', '_blank');
		}
		index === 0
			? navigate('/')
			: index === 1
			? navigate('/shipment')
			: index === 2
			? navigate('/products')
			: index === 3
			? navigate('/profile')
			: index === 4
			? navigate('/users')
			: index === 5
			? navigate('/live-rates')
			: index === 6
			? navigate('/tracker')
			: navigate('/');
	};

	const handleCustomsListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		setSelectedIndex(index);
		if (index === 11) {
			sessionStorage.clear();
			dispatch(setMerchant({}));
			signOut();
		}
		if (index === 10) {
			window.open('https://www.tunl.to/tools?signedin=true', '_blank');
		}
		index === 0
			? navigate('/')
			: index === 1
			? navigate('/customs/dashboard')
			: index === 2
			? navigate('/customs/shipments')
			: index === 3
			? navigate('/customs/booking/create')
			: index === 4
			? navigate('/shipment')
			: index === 5
			? navigate('/products')
			: index === 6
			? navigate('/profile')
			: index === 7
			? navigate('/users')
			: index === 8
			? navigate('/live-rates')
			: index === 9
			? navigate('/tracker')
			: navigate('/customs/dashboard');
	};

	const list = (
		<Box
			role="presentation"
			onClick={isXsSmMd ? toggleDrawer(true) : toggleDrawer(!open)}
			onKeyDown={isXsSmMd ? toggleDrawer(true) : toggleDrawer(!open)}
			sx={{
				backgroundColor: 'whitesmoke',
				width: isXsSmMd ? '100%' : '100%',
				alignItems: 'center',
				alignContent: 'center',
				justifyContent: 'center'
			}}
		>
			<List>
				{user_value?.admin_access === true ? (
					<>
						{[
							'Dashboard',
							'Customs Dashboard',
							'View Shipments',
							'Create New Booking',
							'Create Shipment',
							'Product Library',
							'Merchant Profile',
							'Manage Users',
							'Sales Channels',
							'Tracker',
							'Tools',
							'Logout'
						].map((text, index) => (
							<ListItem
								id={createElementId([SIDEMENU, ADMIN, BUTTON, toLower(text).replace(' ', '-')])}
								key={text}
								disablePadding
							>
								<ListItemButton
									id={createElementId([SIDEMENU, ADMIN, BUTTON, toLower(text)])}
									selected={index === selectedIndex}
									onClick={event => handleCustomsListItemClick(event, index)}
								>
									<ListItemIcon>
										{index === 0 ? (
											<Home />
										) : index === 1 ? (
											<HouseSharp />
										) : index === 2 ? (
											<Flight />
										) : index === 3 ? (
											<MenuBook />
										) : index === 4 ? (
											<Add />
										) : index === 5 ? (
											<Inventory />
										) : index === 6 ? (
											<AccountCircle />
										) : index === 7 ? (
											<SupervisedUserCircle />
										) : index === 8 ? (
											<Storefront />
										) : index === 9 ? (
											<PinDropIcon />
										) : index === 10 ? (
											<Handyman />
										) : index === 11 ? (
											<Logout />
										) : (
											<QuestionMark />
										)}
									</ListItemIcon>
									<ListItemText primary={text} />
								</ListItemButton>
							</ListItem>
						))}
					</>
				) : (
					<>
						{[
							'Dashboard',
							'Create Shipment',
							'Product Library',
							'Merchant Profile',
							'Manage Users',
							'Sales Channels',
							'Tracker',
							'Tools',
							'Logout'
						].map((text, index) => (
							<ListItem
								id={createElementId([SIDEMENU, LIST_ITEM, toLower(text).replace(' ', '-')])}
								key={text}
								disablePadding
							>
								<ListItemButton
									id={createElementId([SIDEMENU, BUTTON, toLower(text)])}
									selected={index === selectedIndex}
									onClick={event => handleListItemClick(event, index)}
								>
									<ListItemIcon>
										{index === 0 ? (
											<Home />
										) : index === 1 ? (
											<Add />
										) : index === 2 ? (
											<Inventory />
										) : index === 3 ? (
											<AccountCircle />
										) : index === 4 ? (
											<SupervisedUserCircle />
										) : index === 5 ? (
											<Storefront />
										) : index === 6 ? (
											<PinDropIcon />
										) : index === 7 ? (
											<Handyman />
										) : index === 8 ? (
											<Logout />
										) : (
											<QuestionMark />
										)}
									</ListItemIcon>
									<ListItemText primary={text} />
								</ListItemButton>
							</ListItem>
						))}
					</>
				)}
			</List>
		</Box>
	);

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackBar(false);
	};

	useEffect(() => {
		async function fetchUser() {
			setLoading(true);

			try {
				const response = await axiosInstance.post('/user/getUser', {
					user_email: user.attributes?.email || ''
				});

				if (!response.data.data.user) {
					try {
						const userCreateResponse = await axiosInstance.post('/user/user', {
							user_email: user.attributes.email,
							user_name: user.attributes.name,
							user_id: user.username,
							user_merchants: []
						});

						sessionStorage.setItem('user', JSON.stringify(userCreateResponse.data.data));
					} catch (ex) {
						console.log(ex);
						setApiResponse({
							type: 'error',
							message: 'Error: Failed to get user'
						});
						setOpenSnackBar(true);
					}
				} else {
					sessionStorage.setItem('user', JSON.stringify(response.data.data.user));
				}

				setLoading(false);

				if (response.data.data.userMerchants && response.data.data.userMerchants.length === 1) {
					sessionStorage.setItem('userMerchants', JSON.stringify(response.data.data.userMerchants[0]));
					setMerchants(response.data.data.userMerchants[0]);
				}

				sessionStorage.setItem('userMerchants', JSON.stringify(response.data.data.userMerchants));
			} catch (error) {
				console.log(error);
			}
		}

		if (
			!JSON.parse(sessionStorage.getItem('user') as string) ||
			!JSON.parse(sessionStorage.getItem('userMerchants') as string) ||
			!JSON.parse(sessionStorage.getItem('merchant') as string)
		) {
			fetchUser();
		} else {
			setLoading(false);
		}
	}, []);

	return (
		<div>
			{!loading ? (
				<>
					{(selectedMerchant || merchants) && (
						<Header
							open={open}
							signOut={signOut}
							user={JSON.parse(sessionStorage.getItem('user') as string)}
							isXsSmMd={isXsSmMd}
							toggleDrawer={toggleDrawer}
							marginBottom={'75px'}
						/>
					)}
					<Grid container mt={selectedMerchant || merchants ? 8 : 0}>
						<Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
							{(selectedMerchant || merchants) && (
								<Grid container>
									<Grid item xs={1} sm={1} md={1} lg={2.5} xl={1.65}>
										{isXsSmMd ? (
											<Box sx={{ height: '100%', width: '220px', position: 'fixed', backgroundColor: 'whitesmoke' }}>{list}</Box>
										) : (
											<SwipeableDrawer
												variant="persistent"
												anchor={isXsSmMd ? 'left' : 'top'}
												open={isXsSmMd ? open : !open}
												onClose={toggleDrawer(false)}
												onOpen={toggleDrawer(true)}
												sx={{
													'& .MuiDrawer-paper': {
														top: '70px',
														height: '100%',
														// bottom: isXsSmMd ? '0px' : '10%',
														width: isXsSmMd ? 'auto' : '100%',
														alignItems: 'center',
														alignContent: 'center',
														justifyContent: 'center',
														backgroundColor: 'whitesmoke'
													}
												}}
											>
												<Toolbar
													sx={{
														backgroundColor: 'whitesmoke',
														height: '20%',
														mb: -35,
														width: '100%'
													}}
												/>
												{list}
											</SwipeableDrawer>
										)}
									</Grid>
								</Grid>
							)}
							{isXsSmMd ? (
								<Grid container ml={selectedMerchant || merchants ? 30 : 0}>
									<Grid item sx={{ width: '100%' }}>
										{children}
									</Grid>
								</Grid>
							) : (
								<Grid container ml={selectedMerchant || merchants ? 3 : 0}>
									<Grid item sx={{ width: '100%' }}>
										{children}
									</Grid>
								</Grid>
							)}
						</Grid>
					</Grid>

					{(selectedMerchant || merchants) && (
						<Footer
							username={user.attributes.email}
							merchant={merchants?.merchant_name || selectedMerchant?.merchant_name}
							adminBool={user_value?.admin_access ? true : false}
						/>
					)}

					<Snackbar
						open={openSnackBar}
						autoHideDuration={2000}
						onClose={handleAlertClose}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					>
						<Alert onClose={handleAlertClose} severity={apiResponse.type} sx={{ width: '100%' }}>
							{apiResponse.message}
						</Alert>
					</Snackbar>
				</>
			) : (
				<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
					<CircularProgress color="inherit" />
				</Backdrop>
			)}
		</div>
	);
}
