import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

export default function SinglePage(props: any) {
	const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page
	pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

	const onDocumentLoadSuccess = () => {
		setPageNumber(1);
	};

	const { pdf } = props;

	return (
			<Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
				<Page pageNumber={pageNumber} height={300} />
			</Document>
	);
}
