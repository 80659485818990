import { CircularProgressProps, Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
	return (
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
			<CircularProgress
				size="5rem"
				variant="determinate"
				{...props}
				color={
					props.value <= 35
						? 'inherit'
						: props.value < 50
						? 'error'
						: props.value < 71
						? 'warning'
						: props.value < 90
						? 'info'
						: 'success'
				}
			/>
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
					height: '100%'
				}}
			>
				<Typography variant="caption" component="div" color="text.secondary">{`${Math.round(
					props.value
				)}%`}</Typography>
			</Box>
		</Box>
	);
}
export default function CircularWithValueLabel(value: any) {
	const [progress, setProgress] = React.useState(0);
	//useEffect to calculate persentage of progress using the value passed in
	React.useEffect(() => {
		const timer = setInterval(() => {
			const persentage = value.value * 100;
			setProgress(prevProgress => (prevProgress >= persentage ? persentage : prevProgress + 0.2));
		}, 5);
		return () => {
			clearInterval(timer);
		};
	}, [value]);
	return (
		<div>
			<CircularProgressWithLabel value={progress} />
		</div>
	);
}