import {
	Menu
} from '@mui/icons-material';
import {
	AppBar,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	IconButton,
	Modal,
	styled,
	Toolbar,
	Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAlertBoolean } from '../../stores/alert';
import { setMerchant } from '../../stores/merchant';
const StyledAppBar = styled(AppBar)({
	backgroundColor: '#007BC4',
	position: 'sticky'
});
const Img = styled('img')({
	display: 'block',
	maxWidth: '170px',
	maxHeight: '170px'
});
export default function Header(props: any) {
	const path = window.location.pathname.split('/');
	const { isXsSmMd, toggleDrawer, open } = props;
	const [selectedIndex, setSelectedIndex] = useState<number>();
	const [modalOpen, setModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (!!path && path[1]?.toLowerCase() === 'customs')
			setSelectedIndex(path[2] === 'booking' ? 1 : path[2] === 'shipments' ? 2 : 0);
		else setSelectedIndex(path[1] === 'shipment' ? 1 : path[1] === 'profile' ? 2 : 0);		
	}, []);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	if (pathname === '/shipment/details' || pathname === '/shipment/details') {
		dispatch(setAlertBoolean('false'));
	}
	const resetModalStyle = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3
	};
	const leavePage = () => {
		dispatch(setAlertBoolean('false'));
		setModalOpen(false);
		setLoading(true);
		if (selectedIndex === 8) {
			sessionStorage.clear();
			dispatch(setMerchant({}));
			props.signOut();
		}
		selectedIndex === 0
			? navigate('/')
			: selectedIndex === 1
			? navigate('/shipment')
			: selectedIndex === 2
			? navigate('/products')
			: selectedIndex === 3
			? navigate('/profile')
			: selectedIndex === 4
			? navigate('/users')
			: selectedIndex === 5
			? navigate('/live-rates')
			: navigate('/');
		setLoading(false);
	};
	return (
		<div>
			<StyledAppBar sx={{ zIndex: theme => theme.zIndex.drawer + 1, position: 'fixed', overflow: 'initial' }}>
				<Toolbar
					sx={{
						justifyContent: 'space-between',
						alignItems: 'center',
						position: 'fixed',
						overflow: 'initial',
						backgroundColor: '#007BC4',
						width: '100%'
					}}
				>
					<IconButton
						edge="start"
						aria-label="menu"
						onClick={isXsSmMd ? toggleDrawer(true) : toggleDrawer(!open)}
					>
						{!isXsSmMd ? <Menu color="secondary" /> : null}
					</IconButton>
					<Img src="../img/tunl-logo-cream.png" />
				</Toolbar>
			</StyledAppBar>
			{modalOpen && (
				<Modal
					open={modalOpen}
					onClose={() => setModalOpen(false)}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={resetModalStyle} style={{ border: 'none', boxShadow: '1px 1px solid gray' }}>
						<Typography id="modal-modal-title" variant="h6" color="gray" component="h2">
							Exit form?
						</Typography>
						<Typography id="modal-modal-description" sx={{ mt: 2, color: 'gray', fontSize: '14px' }}>
							Are you sure you want to leave the form? This will clear all shipment details you have
							entered so far.
						</Typography>
						<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
							<Button onClick={() => setModalOpen(false)} sx={{ mr: 1 }} color="primary">
								Stay here
							</Button>
							<Box sx={{ flex: '1 1 auto' }} />
							<Button color="error" sx={{ fontSize: '14px' }} onClick={leavePage}>
								I want to leave
							</Button>
						</Box>
					</Box>
				</Modal>
			)}
			<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
}
