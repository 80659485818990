import Dialog from '@material-ui/core/Dialog';
import { AddCircleOutline, CloudUpload, RemoveCircleOutline } from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Autocomplete,
	Box,
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	InputBase,
	Link,
	MenuItem,
	TextField,
	Tooltip,
	Typography,
	styled
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { axiosConfig, useAuthToken } from '../../../../constants/axios';
import {
	AUTOCOMPLETE,
	BUTTON,
	CHECKBOX,
	CREATE_SHIPMENTS,
	CUSTOMS_INFO,
	CUSTOM_ITEM,
	DROPDOWN,
	ECONOMY,
	EXPRESS,
	FORM_CONTROL_LABEL,
	HEADING,
	LABEL,
	MENU_ITEM,
	PDF_VIEWER,
	TEXTFIELD,
	createElementId
} from '../../../../constants/id';
import { invalidChapters, warningChapters } from '../../../../pages/Products/HsCodeRestrictions';
import { ProductRecord } from '../../../../types/product';
import LearnMore from '../../../Common/LearnMore';
import CircularProgressWithLabel from '../ConfidenceRating';
import PdfViewer from '../PdfViewer';
import CustomsSampleModal from './CustomsSampleModal';

export default function CustomsForm(props: any) {
	const [selectedContents, setSelectedContents] = useState(props.getValues('customs_info.contents_type'));
	const [selectedRestriction, setSelectedRestriction] = useState(props.getValues('customs_info.restriction_type'));
	const shipment_type = props.getValues('shipment_type');
	const [selectedCurrency, setSelectedCurrency] = useState(
		shipment_type === 'economy'
			? 'USD'
			: props.getValues('selected_currency')
			? props.getValues('selected_currency')
			: 'placeholder'
	);

	const products: ProductRecord[] = props.products;
	const [itemDescription, setItemDescription] = useState('');
	const [classificationResult, setClassificationResult] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [customsDescription, setCustomsDescription] = useState('');
	const [showCustomsDescription, setShowCustomsDescription] = useState(false);
	const [showCustomsCode, setShowCustomsCode] = useState('');
	const [hsCodeError, setHsCodeError] = useState<string[]>([]);
	const [hsCodeWarning, setHsCodeWarning] = useState<string[]>([]);
	const { codeError, setCodeError, codeWarning, setCodeWarning, to_address } = props;
	const [confidence, setConfidence] = useState(0);
	const theme = useTheme();
	const isXsSmMd = useMediaQuery(theme.breakpoints.up('md'));
	const [hsCodeExistsList, setHsCodeExists] = useState<boolean[]>([false]);
	const [hsCodeValueList, setHsCodeValueList] = useState<string[]>([]);
	const [openModal, SetCloseModal] = useState(false);
	const pdfId = createElementId([CREATE_SHIPMENTS, CUSTOMS_INFO, PDF_VIEWER, ECONOMY, 'document']);
	const token = useAuthToken();
	const axiosInstance = axiosConfig(token);

	const classifyItem = async (index: number) => {
		setIsLoading(true);
		try {
			const response = await axiosInstance.post('/zonos/zonosClassify', {
				item: {
					id: '',
					description: props.watch(`customs_info.customs_items[${index}].description`)
				},
				ship_to_country: to_address.address_to_country
			});
			const formattedHtsCode = response.data.data.codes[0].code.replace(/\./g, '');
			const chapter = response.data.data.codes[3].code;
			setConfidence(response.data.data.confidence_score.toFixed(2));
			setCustomsDescription(response.data.data.combinedDescription);
			const hsCode = checkCodeStartsWith(chapter);
			const warningCode = checkWarningCodes(chapter);
			setHsCodeError(prevErrors => {
				const newErrors = [...prevErrors];
				newErrors[index] = hsCode;
				return newErrors;
			});
			setHsCodeWarning(prevWarnings => {
				const newWarnings = [...prevWarnings];
				newWarnings[index] = warningCode;
				return newWarnings;
			});
			props.setValue(`customs_info.customs_items[${index}].hs_tariff_number`, formattedHtsCode);
			updateHsCodeValue(index, formattedHtsCode);
		} catch (error) {
			console.error('API request error:', error);
			setClassificationResult('Error: Unable to classify item');
		} finally {
			setIsLoading(false);
			setShowCustomsDescription(true);
			setShowCustomsCode(props.watch(`customs_info.customs_items[${index}].hs_tariff_number`));
		}
	};

	const updateHsCodeValue = (index: number, newValue: string) => {
		setHsCodeValueList(prevList => {
			const updatedList = [...prevList];
			updatedList[index] = newValue;
			return updatedList;
		});
	};

	const updateHsCodeValueExist = (index: number, newValue: boolean) => {
		setHsCodeExists(prevList => {
			const updatedList = [...prevList];
			updatedList[index] = newValue;
			return updatedList;
		});
	};

	const handleRemove = (index: number) => {
		const checkCodes = props.getValues(`customs_info.customs_items[${index}].hs_tariff_number`) === showCustomsCode;
		if (index !== 0) {
			props.remove(index);
			removeHsCodeValue(index);
			removeHsCodeValueExist(index);
		}
	};

	const removeHsCodeValue = (index: number) => {
		setHsCodeValueList(prevList => prevList.filter((_, i) => i !== index));
	};

	const removeHsCodeValueExist = (index: number) => {
		setHsCodeExists(prevList => prevList.filter((_, i) => i !== index));
	};

	useEffect(() => {
		if (selectedRestriction === 0) props.clearErrors('customs_info.restriction_comments');
		props.setValue('customs_info.restriction_type', selectedRestriction);
	}, [selectedRestriction]);

	useEffect(() => {
		props.setValue('customs_info.contents_type', selectedContents);
	}, [selectedRestriction]);

	useEffect(() => {
		if (shipment_type === 'economy') {
			props.setValue('selected_currency', 'USD');
			props.setValue(`customs_info.customs_items[${props.fields.length - 1}].currency`, 'USD');
		} else {
			setSelectedCurrency(
				props.getValues('selected_currency') ? props.getValues('selected_currency') : 'placeholder'
			);
		}
	}, []);

	// set label font color to red if error and blue if no error
	const BootstrapInput = styled(InputBase)(({ theme, error }) => ({
		'label + &': {
			marginTop: theme.spacing(3)
		},
		'& .MuiInputBase-input': {
			borderRadius: 4,
			position: 'relative',
			backgroundColor: theme.palette.background.paper,
			color: error ? 'red' : 'black',
			border: `1px solid ${error ? 'red' : '#ced4da'}`,
			fontSize: 16,
			padding: '10px 26px 10px 12px',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			// Use the system font instead of the default Roboto font.
			fontFamily: [].join(','),
			'&:focus': {
				borderRadius: 4,
				borderColor: error ? 'error' : '#80bdff',
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
			}
		}
	}));

	const handleSearch = (product: any, index: number) => {
		const quantity = props.getValues(`customs_info.customs_items[${index}].quantity`);
		//TODO: check up on the shipment through the hs code flow
		updateHsCodeValue(index, shipment_type === 'economy' ? product.hts_code : product.hs_code);
		props.setValue(`customs_info.customs_items[${index}].description`, product.description);
		props.setValue(
			`customs_info.customs_items[${index}].hs_tariff_number`,
			shipment_type === 'economy' ? product.hts_code : product.hs_code
		);
		props.setValue(`customs_info.customs_items[${index}].origin_country`, product.origin_country);
		props.setValue(`customs_info.customs_items[${index}].sortKey`, product._id);
		props.setValue(`customs_info.customs_items[${index}].weight`, product.unit_weight * quantity);
		props.setValue(`customs_info.customs_items[${index}].value`, product.unit_price * quantity);
		if (product.unit_price && product.unit_weight && quantity) {
			props.setValue(`customs_info.customs_items[${index}].weight`, product.unit_weight * quantity);
			props.setValue(`customs_info.customs_items[${index}].value`, product.unit_price * quantity);
		}
	};

	const handleSaveProduct = (e: any, index: number) => {
		props.setValue(`customs_info.customs_items[${index}].save_customs_line_item`, e?.target?.checked);
	};

	const calculateQuantityChange = async (e: any, index: number) => {
		const quantity = e.target.value;
		const customs_item = await props.getValues(`customs_info.customs_items[${index}]`);
		const product = await products.find(product => product._id === customs_item.sortKey);

		if (product) {
			props.setValue(`customs_info.customs_items[${index}].description`, product.description);
			props.setValue(
				`customs_info.customs_items[${index}].hs_tariff_number`,
				shipment_type === 'economy' ? product.hts_code : product.hs_code
			);
			props.setValue(`customs_info.customs_items[${index}].origin_country`, product.origin_country);
			props.setValue(`customs_info.customs_items[${index}].sortKey`, product._id);
			if (product.unit_price && product.unit_weight && quantity) {
				props.setValue(`customs_info.customs_items[${index}].weight`, product.unit_weight * quantity);
				props.setValue(`customs_info.customs_items[${index}].value`, product.unit_price * quantity);
			}
		}
	};

	useEffect(() => {
		if (props.watch(`customs_info.customs_items[${props.fields.length - 1}].description`) === '') {
			setShowCustomsDescription(false);
		}
	}, [props.watch(`customs_info.customs_items[${props.fields.length - 1}].description`)]);

	const handleCurrencyChange = (value: string) => {
		for (let i = 0; i < props.fields.length; i++) {
			props.setValue(`customs_info.customs_items[${i}].currency`, value);
		}
		setSelectedCurrency(value);
		props.setValue('selected_currency', value);
	};

	const handleContentTypeChange = (value: String) => {
		setSelectedContents(value);
		props.setValue('customs_info.contents_type', value);
		if (value === 'sample') {
			SetCloseModal(true);
		}
	};

	const StyledBox = styled(Box)({
		border: props.pdfError ? '1px dashed red' : '1px dashed',
		borderRadius: '4px',
		padding: '80px'
	});

	useEffect(() => {
		if (shipment_type === 'economy') {
			if (!props.pdf) {
				props.setError('pdf', { type: 'custom', message: 'Invoice PDF is required' });
			} else {
				props.clearErrors('pdf');
			}
		}
	}, [props.pdf]);

	useEffect(() => {
		hsCodeError.some(code => code === 'Invalid Code')
			? setCodeError(true)
			: hsCodeWarning.some(code => code === 'Warning Code')
			? setCodeWarning(true)
			: setCodeError(false) || setCodeWarning(false);
	}, [hsCodeError, hsCodeWarning]);

	useEffect(() => {
		const hsCode = checkChapterRestriction(
			props.watch(`customs_info.customs_items[${props.fields.length - 1}].hs_tariff_number`)
		);
		if (hsCode === 'Invalid Code') {
			setHsCodeError(prevErrors => {
				const newErrors = [...prevErrors];
				newErrors[props.fields.length - 1] = hsCode;
				return newErrors;
			});
		}
		if (hsCode === 'Invalid length') {
			setHsCodeError(prevErrors => {
				const newErrors = [...prevErrors];
				newErrors[props.fields.length - 1] = hsCode;
				return newErrors;
			});
		}
	}, []);

	useEffect(() => {
		checkDuplicateHSCode();
	}, [hsCodeValueList]);

	useEffect(() => {
		if (props.watch(`reference`) === '') {
			props.setError('reference', { type: 'custom', message: 'Invoice Number is required' });
		}
	}, []);

	useEffect(() => {
		if (props.watch(`customs_info.customs_items[${props.fields.length - 1}].description`) === '') {
			props.setValue(`customs_info.customs_items[${props.fields.length - 1}].hs_tariff_number`, '');
			setCodeError(false);
			setCodeWarning(false);
			checkDuplicateHSCode();
		}
	}, [props.watch(`customs_info.customs_items`)]);

	const resetErrors = () => {
		setCodeError(false);
		setCodeWarning(false);
		setHsCodeError(prevErrors => {
			const newErrors = [...prevErrors];
			newErrors[props.fields.length - 1] = '';
			return newErrors;
		});
		setHsCodeWarning(prevWarnings => {
			const newWarnings = [...prevWarnings];
			newWarnings[props.fields.length - 1] = '';
			return newWarnings;
		});
	};

	const checkCodeStartsWith = (code: string): string => {
		const getInvalidChapters = invalidChapters;
		return getInvalidChapters.includes(code) ? 'Invalid Code' : '';
	};

	const checkWarningCodes = (code: string): string => {
		const getWarningChapters = warningChapters;
		return getWarningChapters.includes(code) ? 'Warning Code' : '';
	};

	const checkChapterRestriction = (code: string): string => {
		const getInvalidChapters = invalidChapters;
		return code.length > 5
			? getInvalidChapters.includes(code.slice(0, 2))
				? 'Invalid Code'
				: ''
			: 'Invalid length';
	};

	const checkChapterWarning = (code: string): string => {
		const getWarningChapters = warningChapters;
		return code.length > 5
			? getWarningChapters.includes(code.slice(0, 2))
				? 'Warning Code'
				: ''
			: 'Invalid length';
	};

	const checkDuplicateHSCode = () => {
		const customsItemList = props.getValues(`customs_info.customs_items`);
		for (let i = 0; i < customsItemList.length; i++) {
			updateHsCodeValueExist(i, false);
		}

		for (let i = 0; i < customsItemList.length; i++) {
			for (let j = 0; j < customsItemList.length; j++) {
				if (
					i !== j &&
					customsItemList[i].hs_tariff_number === customsItemList[j].hs_tariff_number &&
					customsItemList[i].hs_tariff_number !== '' &&
					customsItemList[j].hs_tariff_number !== ''
				) {
					console.log(`match_found: ${customsItemList[i].hs_tariff_number}`);
					updateHsCodeValueExist(i, true);
				}
			}
		}
	};

	return (
		<>
			<Grid
				container
				direction="column"
				alignContent="center"
				justifyContent="center"
				textAlign={'center'}
				paddingBottom={3}
			>
				<Typography variant="h5" mb={2} mt={5}>
					Customs Info
				</Typography>
				{shipment_type === 'economy' && (
					<>
						<Grid item alignItems="center" justifyContent="center">
							{props.pdf ? (
								<>
									<PdfViewer pdf={props.pdf} />
									<hr />
								</>
							) : (
								<></>
							)}
						</Grid>
						<Grid item>
							<Grid container direction="row" alignContent={'center'} justifyContent={'center'}>
								<Grid item>
									<input
										hidden
										accept="application/pdf"
										type="file"
										id={createElementId([CREATE_SHIPMENTS, CUSTOMS_INFO, ECONOMY, 'pdf'])}
										onChange={e => {
											props.clearErrors('pdf');
											props.handlePdfUpload(e);
										}}
									/>
								</Grid>
								<Grid item>
									<div
										id="drop_zone"
										onDrop={e => props.handleDropPdf(e)}
										onDragOver={e => props.handleDragOver(e)}
									>
										{props.pdf ? (
											<IconButton color="primary" component="span" edge="end" size="large">
												<label
													htmlFor={createElementId([
														CREATE_SHIPMENTS,
														CUSTOMS_INFO,
														ECONOMY,
														'pdf'
													])}
												>
													<CloudUpload sx={{ fontSize: 40 }} />
													{props.pdf ? (
														<Typography>Upload Different File</Typography>
													) : (
														<Typography>Upload PDF Invoice*</Typography>
													)}
												</label>
											</IconButton>
										) : (
											<StyledBox>
												<IconButton color="primary" component="span" edge="end" size="large">
													<label
														htmlFor={createElementId([
															CREATE_SHIPMENTS,
															CUSTOMS_INFO,
															ECONOMY,
															'pdf'
														])}
													>
														<CloudUpload
															sx={{ fontSize: 60 }}
															color={props.pdfError ? 'error' : 'primary'}
														/>
														{props.pdf ? (
															<Typography>Upload Different File</Typography>
														) : (
															<Typography color={props.pdfError ? 'error' : 'primary'}>
																Upload PDF Invoice
															</Typography>
														)}
													</label>
												</IconButton>
											</StyledBox>
										)}
									</div>
								</Grid>
							</Grid>
						</Grid>
					</>
				)}
			</Grid>
			<Grid container spacing={2} ml={isXsSmMd ? -5 : -7} justifyContent="space-evenly">
				<Grid item direction={'column'} spacing={2} alignContent={'center'} alignSelf={'center'}>
					<Grid item>
						<Grid container direction={'row'} alignContent={'center'} alignSelf={'center'} spacing={1}>
							<Grid item xs={12} sm={12} md={8} lg={8} xl={8} mb={2} ml={'20%'}>
								<Alert severity="warning">
									<AlertTitle>
										<strong>Important:</strong>
									</AlertTitle>
									The values entered in this screen are used to generate and submit a commercial
									invoice to customs. Please enter accurate customs info to avoid delays and extra
									customs charges.
								</Alert>
							</Grid>
						</Grid>
						<Grid
							container
							direction={'row'}
							spacing={1}
							alignContent={'center'}
							justifyContent="center"
							mt={2}
							xs={12}
							sm={12}
							md={10}
							lg={10}
							xl={10}
							sx={{ ml: '10%', mr: '5%' }}
						>
							<Grid item xs={10} sm={9.5} md={3} lg={3} xl={3}>
								<TextField
									required
									select
									label="Content Type"
									onChange={(e: any) => handleContentTypeChange(e.target.value)}
									value={selectedContents}
									fullWidth
								>
									<MenuItem value={'merchandise'}>Merchandise</MenuItem>
									<MenuItem value={'documents'}>Documents</MenuItem>
									<MenuItem value={'sample'}>Sample</MenuItem>
								</TextField>
							</Grid>
							<Grid item xs={10} sm={9.5} md={3} lg={3} xl={3}>
								<Tooltip
									title={'Invoice/order number from sale of goods - this will appear on the label.'}
								>
									<TextField
										id={createElementId([
											CREATE_SHIPMENTS,
											CUSTOMS_INFO,
											EXPRESS,
											TEXTFIELD,
											'invoice_number'
										])}
										label="Invoice Number"
										required
										{...props.register('reference', {
											required: 'Required'
										})}
										error={!!props.errors?.reference}
										helperText={
											props.errors?.reference ? (props.errors.reference.message as string) : null
										}
										fullWidth
										inputProps={{ 'data-hj-allow': true }}
									/>
								</Tooltip>
							</Grid>{' '}
							<Grid item xs={10} sm={9.5} md={3} lg={3} xl={3}>
								<Tooltip title={'Optional - SKU or other internal product identifier.'}>
									<TextField
										id={createElementId([
											CREATE_SHIPMENTS,
											CUSTOMS_INFO,
											EXPRESS,
											TEXTFIELD,
											'product_reference'
										])}
										label="Product Reference"
										{...props.register(`product_reference`)}
										error={!!props.errors?.product_reference}
										helperText={
											props.errors?.product_reference
												? (props.errors?.product_reference.message as string)
												: null
										}
										fullWidth
										inputProps={{ 'data-hj-allow': true }}
									/>
								</Tooltip>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{props.fields.map((field: any, index: number) => (
				<>
					<Typography
						id={createElementId([
							CREATE_SHIPMENTS,
							CUSTOMS_INFO,
							EXPRESS,
							CUSTOM_ITEM,
							`${index + 1}`,
							LABEL,
							HEADING
						])}
						align="center"
						fontWeight={'bold'}
						mt={2}
						mb={1}
					>
						Customs Item {index + 1}
					</Typography>
					{
						<Grid container direction={'row'} spacing={2} ml={-7} justifyContent="space-evenly">
							{!hsCodeExistsList[index] &&
								showCustomsDescription &&
								props.getValues(`customs_info.customs_items[${index}].hs_tariff_number`) ===
									showCustomsCode && (
									<Grid
										item
										direction={'column'}
										xs={10}
										sm={10}
										md={7}
										lg={7}
										xl={7}
										marginLeft={'80px'}
									>
										<Alert
											severity={
												confidence < 0.35
													? 'warning'
													: confidence < 0.5
													? 'warning'
													: confidence < 0.71
													? 'warning'
													: confidence < 0.9
													? 'info'
													: 'success'
											}
										>
											<Grid container alignItems="space-between">
												<Grid
													item
													direction={'row'}
													margin={'auto'}
													padding={'auto'}
													xs={12}
													sm={12}
													md={7}
													lg={7}
													xl={7}
												>
													<AlertTitle>
														<strong>Customs Description</strong>
													</AlertTitle>
													{customsDescription}
												</Grid>
												<Grid
													item
													xs={12}
													sm={12}
													md={4}
													lg={4}
													xl={4}
													margin={'auto'}
													padding={'auto'}
													alignSelf={'center'}
													justifyContent="flex-end"
													alignItems="flex-start"
												>
													<AlertTitle>
														<strong>Confidence Score</strong>
													</AlertTitle>
													<Grid
														item
														direction={'row'}
														marginLeft={'24px'}
														justifyContent="flex-end"
														alignItems="flex-start"
														mt={1}
													>
														<CircularProgressWithLabel value={confidence} />
													</Grid>
												</Grid>
											</Grid>
										</Alert>
									</Grid>
								)}
							{hsCodeError[index] && codeError && (
								<Grid item xs={10} sm={10} md={7} lg={7} xl={7} marginLeft={'80px'}>
									<Alert severity="error">
										<AlertTitle>
											<strong>Restricted Code</strong>
										</AlertTitle>
										TUNL doesn't ship commodities under this HS/HTS code. For assistance or help,
										please contact <Link href="mailto:support@tunl.to">support@tunl.to</Link>.
									</Alert>
								</Grid>
							)}
							{hsCodeWarning[index] && codeWarning && (
								<Grid item xs={10} sm={10} md={7} lg={7} xl={7} marginLeft={'80px'}>
									<Alert severity="warning">
										<AlertTitle>
											<strong>Warning Code</strong>
										</AlertTitle>
										The commodities{' '}
										<strong>
											{shipment_type === 'economy' ? '(HTS Code: ' : '(HS Code: '}
											{props.getValues(`customs_info.customs_items[${index}].hs_tariff_number`)})
										</strong>{' '}
										may require additional permissions or documentation for shipping to the
										destination country. By proceeding with this shipment, you confirm that you have
										obtained all necessary documentation and certifications required for customs
										clearance.
									</Alert>
								</Grid>
							)}
							{hsCodeExistsList[index] && (
								<Grid item xs={10} sm={10} md={7} lg={7} xl={7} marginLeft={'80px'}>
									<Alert severity="warning">
										<AlertTitle>
											<strong>Duplicate HS Code</strong>
										</AlertTitle>
										Only one custom item required per HS Code. Please remove duplicate items.{' '}
										<strong>
											HS Code (
											{props.getValues(`customs_info.customs_items[${index}].hs_tariff_number`)})
										</strong>{' '}
										<LearnMore
											style={{ color: '#50B4BD' }}
											href="https://help.tunl.to/en/article/duplicate-hs-codes-16khn4r/"
										/>
									</Alert>
								</Grid>
							)}
							<Grid item direction={'column'} spacing={2} alignContent={'center'} sx={{ ml: '20%' }}>
								<Grid item>
									<Grid container direction={'row'} alignContent={'center'} spacing={1}>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={2}>
											<Autocomplete
												//id="product-filter"
												id={createElementId([
													CREATE_SHIPMENTS,
													CUSTOMS_INFO,
													EXPRESS,
													CUSTOM_ITEM,
													`${index + 1}`,
													AUTOCOMPLETE,
													'saved_product_list'
												])}
												options={products}
												getOptionLabel={(option: any) => option.description}
												disabled={products.length === 0 ? true : false}
												value={{
													merchant_id: '',
													merchant_name: '',
													description: '',
													origin_country: '',
													hs_code: '',
													hts_code: '',
													unit_weight: 0,
													unit_price: 0
												}}
												onChange={(event: any, newValue: any) => {
													if (products.indexOf(newValue) !== -1) {
														handleSearch(products[products.indexOf(newValue)], index);
														setShowCustomsDescription(false);
														resetErrors();
													}
												}}
												renderInput={params => (
													<TextField
														//id={createElementId([CREATE_SHIPMENTS, CUSTOMS_INFO, EXPRESS, CUSTOM_ITEM, `${index + 1}`, AUTOCOMPLETE, TEXTFIELD, 'selected_product'])}
														{...params}
														label={
															products.length === 0
																? 'No saved products. Add one below  and save for later.'
																: 'Select an existing product from your library'
														}
													/>
												)}
											/>
										</Grid>
										{isXsSmMd ? (
											<Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
												{' '}
												<TextField
													id={createElementId([
														CREATE_SHIPMENTS,
														CUSTOMS_INFO,
														EXPRESS,
														CUSTOM_ITEM,
														`${index + 1}`,
														TEXTFIELD,
														'product_description'
													])}
													required
													label="Enter a new product description"
													{...props.register(
														`customs_info.customs_items[${index}].description`,
														{
															required: 'Required'
														}
													)}
													error={
														!!props.errors?.customs_info?.customs_items?.[index]
															?.description
													}
													helperText={
														props.errors?.customs_info?.customs_items?.[index]?.description
															? (props.errors.customs_info?.customs_items[index]
																	?.description.message as string)
															: null
													}
													value={
														itemDescription ||
														props.watch(
															`customs_info.customs_items[${index}].description`
														) ||
														''
													}
													fullWidth
													InputProps={{
														endAdornment:
															props.watch(
																`customs_info.customs_items[${index}].description`
															) === '' &&
															props.watch(
																`customs_info.customs_items[${index}].hs_tariff_number`
															) === '' ? (
																<Tooltip title="Enter a product description to get an HS code.">
																	<span>
																		<Button
																			id={createElementId([
																				CREATE_SHIPMENTS,
																				CUSTOMS_INFO,
																				EXPRESS,
																				CUSTOM_ITEM,
																				`${index + 1}`,
																				BUTTON,
																				'get_hs_code'
																			])}
																			variant="contained"
																			color="primary"
																			disabled={
																				isLoading ||
																				props.watch(
																					`customs_info.customs_items[${index}].hs_tariff_number`
																				) ||
																				props.watch(
																					`customs_info.customs_items[${index}].description`
																				) === ''
																			}
																			style={{ marginBottom: '1px' }}
																			sx={{ whiteSpace: 'nowrap' }}
																		>
																			Get HS Code
																		</Button>
																	</span>
																</Tooltip>
															) : (props.watch(
																	`customs_info.customs_items[${index}].hs_tariff_number`
															  ) &&
																	props.watch(
																		`customs_info.customs_items[${index}].description`
																	)) ||
															  props.watch(
																	`customs_info.customs_items[${index}].hs_tariff_number`
															  ) ? (
																<Tooltip title="Clear HS Code field to enable Button.">
																	<span>
																		<Button
																			id={createElementId([
																				CREATE_SHIPMENTS,
																				CUSTOMS_INFO,
																				EXPRESS,
																				CUSTOM_ITEM,
																				`${index + 1}`,
																				BUTTON,
																				'get_hs_code'
																			])}
																			variant="contained"
																			color="primary"
																			disabled={
																				isLoading ||
																				props.watch(
																					`customs_info.customs_items[${index}].hs_tariff_number`
																				) ||
																				props.watch(
																					`customs_info.customs_items[${index}].description`
																				) === ''
																			}
																			style={{ marginBottom: '1px' }}
																			sx={{ whiteSpace: 'nowrap' }}
																		>
																			Get HS Code
																		</Button>
																	</span>
																</Tooltip>
															) : (
																<span>
																	<Button
																		id={createElementId([
																			CREATE_SHIPMENTS,
																			CUSTOMS_INFO,
																			EXPRESS,
																			CUSTOM_ITEM,
																			`${index + 1}`,
																			BUTTON,
																			'get_hs_code'
																		])}
																		variant="contained"
																		color="primary"
																		onClick={() => {
																			classifyItem(index);
																			props.clearErrors(
																				`customs_info.customs_items[${index}].hs_tariff_number`
																			);
																		}}
																		disabled={
																			isLoading ||
																			props.watch(
																				`customs_info.customs_items[${index}].hs_tariff_number`
																			) ||
																			props.watch(
																				`customs_info.customs_items[${index}].description`
																			) === ''
																		}
																		style={{ marginBottom: '1px' }}
																		sx={{ whiteSpace: 'nowrap' }}
																	>
																		Get HS Code
																	</Button>
																</span>
															)
													}}
												/>
											</Grid>
										) : (
											<Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
												{' '}
												<TextField
													id={createElementId([
														CREATE_SHIPMENTS,
														CUSTOMS_INFO,
														EXPRESS,
														CUSTOM_ITEM,
														`${index + 1}`,
														TEXTFIELD,
														'product_description'
													])}
													required
													label="Enter a new product description"
													{...props.register(
														`customs_info.customs_items[${index}].description`,
														{
															required: 'Required'
														}
													)}
													error={
														!!props.errors?.customs_info?.customs_items?.[index]
															?.description
													}
													helperText={
														props.errors?.customs_info?.customs_items?.[index]?.description
															? (props.errors.customs_info?.customs_items[index]
																	?.description.message as string)
															: null
													}
													value={
														itemDescription ||
														props.watch(
															`customs_info.customs_items[${index}].description`
														) ||
														''
													}
													fullWidth
													inputProps={{ 'data-hj-allow': true }}
												/>
												<Button
													id={createElementId([
														CREATE_SHIPMENTS,
														CUSTOMS_INFO,
														EXPRESS,
														CUSTOM_ITEM,
														`${index + 1}`,
														TEXTFIELD,
														'hs_code'
													])}
													variant="contained"
													color="primary"
													onClick={() => {
														classifyItem(index);
														props.clearErrors(
															`customs_info.customs_items[${index}].hs_tariff_number`
														);
													}}
													disabled={
														isLoading ||
														props.watch(
															`customs_info.customs_items[${index}].hs_tariff_number`
														) ||
														props.watch(
															`customs_info.customs_items[${index}].description`
														) === ''
													}
													style={{ marginBottom: '1px', marginTop: '10px' }}
													fullWidth
												>
													Get HS Code
												</Button>
											</Grid>
										)}
										<Grid
											item
											xs={12}
											sm={12}
											md={3}
											lg={3}
											xl={3}
											// sx={{ whiteSpace: 'nowrap' }}
										>
											{' '}
											<Tooltip
												title={
													<span>
														Global customs identifier of goods used to determine customs
														duties and import tax.{' '}
														<LearnMore
															href="https://help.tunl.to/en/article/what-is-an-hs-code-and-hts-code-sez7qh/"
															inline={true}
															style={{ color: 'white' }}
														/>
													</span>
												}
											>
												<TextField
													id={createElementId([
														CREATE_SHIPMENTS,
														CUSTOMS_INFO,
														EXPRESS,
														CUSTOM_ITEM,
														`${index + 1}`,
														TEXTFIELD,
														'hs_code'
													])}
													required
													label="HS Code"
													type="string"
													{...props.register(
														`customs_info.customs_items[${index}].hs_tariff_number`,
														{
															required: 'Required'
														}
													)}
													onChange={e => {
														updateHsCodeValue(index, e.target.value.trim());
														const hsCode = checkChapterRestriction(e.target.value.trim());
														const warningCode = checkChapterWarning(e.target.value.trim());
														setHsCodeError(prevErrors => {
															const newErrors = [...prevErrors];
															newErrors[index] = hsCode;
															return newErrors;
														});
														setHsCodeWarning(prevWarnings => {
															const newWarnings = [...prevWarnings];
															newWarnings[index] = warningCode;
															return newWarnings;
														});
														props.setValue(
															`customs_info.customs_items[${index}].hs_tariff_number`,
															e.target.value
														);
													}}
													error={
														!!props.errors?.customs_info?.customs_items?.[index]
															?.hs_tariff_number || hsCodeError[index]
													}
													helperText={
														props.errors?.customs_info?.customs_items?.[index]
															?.hs_tariff_number
															? (props.errors.customs_info?.customs_items[index]
																	.hs_tariff_number.message as string)
															: null || hsCodeError[index]
													}
													value={
														props
															.watch(
																`customs_info.customs_items[${index}].hs_tariff_number`
															)
															.trim() || ''
													}
													fullWidth
													inputProps={{ 'data-hj-allow': true }}
												/>
											</Tooltip>
										</Grid>
									</Grid>
								</Grid>
								<Grid item mt={2}>
									<Grid container direction={'row'} spacing={1} alignContent={'center'}>
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											{' '}
											<TextField
												id={createElementId([
													CREATE_SHIPMENTS,
													CUSTOMS_INFO,
													EXPRESS,
													CUSTOM_ITEM,
													`${index + 1}`,
													TEXTFIELD,
													'quantity'
												])}
												required
												label="Quantity"
												type="number"
												{...props.register(`customs_info.customs_items[${index}].quantity`, {
													required: 'Required',
													min: { value: 1, message: 'Quantity must be greater than 0' },
													valueAsNumber: true
												})}
												onChange={(e: any) => {
													e.target.value = Math.floor(e.target.value);
													calculateQuantityChange(e, index);
												}}
												error={!!props.errors?.customs_info?.customs_items?.[index]?.quantity}
												helperText={
													props.errors?.customs_info?.customs_items?.[index]?.quantity
														? (props.errors.customs_info?.customs_items[index]?.quantity
																.message as string)
														: null
												}
												fullWidth
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											{' '}
											<TextField
												id={createElementId([
													CREATE_SHIPMENTS,
													CUSTOMS_INFO,
													EXPRESS,
													CUSTOM_ITEM,
													`${index + 1}`,
													TEXTFIELD,
													'total_value'
												])}
												required
												label="Total Value"
												type="number"
												{...props.register(`customs_info.customs_items[${index}].value`, {
													required: 'Required',
													min: { value: 1, message: 'Total value must be greater than 0' },
													valueAsNumber: true
												})}
												error={!!props.errors?.customs_info?.customs_items?.[index]?.value}
												helperText={
													props.errors?.customs_info?.customs_items?.[index]?.value
														? (props.errors.customs_info?.customs_items[index]?.value
																.message as string)
														: null
												}
												fullWidth
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											<TextField
												id={createElementId([
													CREATE_SHIPMENTS,
													CUSTOMS_INFO,
													EXPRESS,
													CUSTOM_ITEM,
													`${index + 1}`,
													DROPDOWN,
													'currency'
												])}
												select
												label="Currency"
												error={selectedCurrency === 'placeholder'}
												disabled={shipment_type === 'economy' ? true : false}
												value={selectedCurrency}
												onChange={(e: any) => handleCurrencyChange(e.target.value)}
												placeholder="Select Currency"
												fullWidth
											>
												<MenuItem value={'placeholder'} disabled>
													Select Currency
												</MenuItem>
												<MenuItem
													id={createElementId([
														CREATE_SHIPMENTS,
														CUSTOMS_INFO,
														EXPRESS,
														CUSTOM_ITEM,
														`${index + 1}`,
														DROPDOWN,
														'currency',
														MENU_ITEM,
														'usd'
													])}
													value={'USD'}
												>
													USD
												</MenuItem>
												<MenuItem
													id={createElementId([
														CREATE_SHIPMENTS,
														CUSTOMS_INFO,
														EXPRESS,
														CUSTOM_ITEM,
														`${index + 1}`,
														DROPDOWN,
														'currency',
														MENU_ITEM,
														'zar'
													])}
													value={'ZAR'}
												>
													ZAR
												</MenuItem>
												<MenuItem
													id={createElementId([
														CREATE_SHIPMENTS,
														CUSTOMS_INFO,
														EXPRESS,
														CUSTOM_ITEM,
														`${index + 1}`,
														DROPDOWN,
														'currency',
														MENU_ITEM,
														'eur'
													])}
													value={'EUR'}
												>
													EUR
												</MenuItem>
												<MenuItem
													id={createElementId([
														CREATE_SHIPMENTS,
														CUSTOMS_INFO,
														EXPRESS,
														CUSTOM_ITEM,
														`${index + 1}`,
														DROPDOWN,
														'currency',
														MENU_ITEM,
														'cad'
													])}
													value={'CAD'}
												>
													CAD
												</MenuItem>
												<MenuItem
													id={createElementId([
														CREATE_SHIPMENTS,
														CUSTOMS_INFO,
														EXPRESS,
														CUSTOM_ITEM,
														`${index + 1}`,
														DROPDOWN,
														'currency',
														MENU_ITEM,
														'gbp'
													])}
													value={'GBP'}
												>
													GBP
												</MenuItem>
												<MenuItem
													id={createElementId([
														CREATE_SHIPMENTS,
														CUSTOMS_INFO,
														EXPRESS,
														CUSTOM_ITEM,
														`${index + 1}`,
														DROPDOWN,
														'currency',
														MENU_ITEM,
														'aud'
													])}
													value={'AUD'}
												>
													AUD
												</MenuItem>
											</TextField>
											<FormHelperText>Currency</FormHelperText>
										</Grid>
									</Grid>
								</Grid>
								<Grid item mb={2}>
									<Grid container direction={'row'} spacing={1}>
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											{' '}
											<TextField
												id={createElementId([
													CREATE_SHIPMENTS,
													CUSTOMS_INFO,
													EXPRESS,
													CUSTOM_ITEM,
													`${index + 1}`,
													TEXTFIELD,
													'total_weight'
												])}
												required
												label="Total Weight (kg)"
												onWheel={e => (e.target as HTMLElement).blur()}
												type={'number'}
												{...props.register(`customs_info.customs_items[${index}].weight`, {
													required: 'Required',
													min: {
														value: 0.1,
														message: 'Parcel cannot be lighter than 0.1kg'
													},
													max: { value: 70, message: 'Parcel cannot be heavier than 70kg' },
													valueAsNumber: true
												})}
												onChange={e => {
													const value = e.target.value;
													const decimalIndex = value.indexOf('.');
													if (decimalIndex !== -1) {
														const decimalPlaces = value.substring(decimalIndex + 1);
														if (decimalPlaces.length > 1) {
															e.target.value = value.substring(0, decimalIndex + 2);
														}
													}
												}}
												error={!!props.errors?.customs_info?.customs_items?.[index]?.weight}
												helperText={
													props.errors?.customs_info?.customs_items?.[index]?.weight
														? (props.errors.customs_info?.customs_items[index].weight
																.message as string)
														: null
												}
												fullWidth
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
											{' '}
											<TextField
												id={createElementId([
													CREATE_SHIPMENTS,
													CUSTOMS_INFO,
													EXPRESS,
													CUSTOM_ITEM,
													`${index + 1}`,
													TEXTFIELD,
													'origin_country'
												])}
												required
												label="Origin Country"
												{...props.register(
													`customs_info.customs_items[${index}].origin_country`,
													{
														required: 'Required',
														pattern: {
															value: /^[a-z][A-Z]{1}$/i,
															message: 'Invalid input. Country must be 2 letter code'
														}
													}
												)}
												error={
													!!props.errors?.customs_info?.customs_items?.[index]?.origin_country
												}
												helperText={
													props.errors?.customs_info?.customs_items?.[index]?.origin_country
														? (props.errors.customs_info?.customs_items[index]
																?.origin_country.message as string)
														: null
												}
												fullWidth
												inputProps={{ 'data-hj-allow': true }}
											/>
										</Grid>
										<Grid
											item
											xs={12}
											sm={12}
											md={3}
											lg={3}
											xl={3}
											alignSelf={'center'}
											justifyContent={'center'}
											marginLeft={'10px'}
										>
											<FormControlLabel
												id={createElementId([
													CREATE_SHIPMENTS,
													CUSTOMS_INFO,
													EXPRESS,
													CUSTOM_ITEM,
													`${index + 1}`,
													FORM_CONTROL_LABEL,
													'save_product'
												])}
												control={
													<Checkbox
														id={createElementId([
															CREATE_SHIPMENTS,
															CUSTOMS_INFO,
															EXPRESS,
															CUSTOM_ITEM,
															`${index + 1}`,
															FORM_CONTROL_LABEL,
															CHECKBOX,
															'save_product'
														])}
														{...props.register(
															`customs_info.customs_items[${index}].save_customs_line_item`
														)}
														checked={
															props.watch(
																`customs_info.customs_items[${index}].save_customs_line_item`
															) || false
														}
														onChange={(event: any) => {
															handleSaveProduct(event, index);
														}}
													/>
												}
												label="Save Product"
											/>
										</Grid>
									</Grid>
								</Grid>
								{isXsSmMd ? <Divider /> : ''}
							</Grid>
							{index > 0 ? (
								<Grid
									container
									xs={8.8}
									sm={8.8}
									md={1}
									lg={1}
									xl={1}
									sx={isXsSmMd ? { ml: 0 } : { ml: '94px', mt: 1 }}
									direction="column"
									alignItems="center"
									justifyContent="center"
								>
									{isXsSmMd ? (
										<Grid item>
											<Tooltip title="Remove customs item.">
												<IconButton
													id={createElementId([
														CREATE_SHIPMENTS,
														CUSTOMS_INFO,
														EXPRESS,
														CUSTOM_ITEM,
														`${index + 1}`,
														BUTTON,
														'remove_item'
													])}
													onClick={() => handleRemove(index)}
													sx={{ mt: 1 }}
												>
													<RemoveCircleOutline />
												</IconButton>
											</Tooltip>
										</Grid>
									) : (
										<Button
											id={createElementId([
												CREATE_SHIPMENTS,
												CUSTOMS_INFO,
												EXPRESS,
												CUSTOM_ITEM,
												`${index + 1}`,
												BUTTON,
												'remove_item'
											])}
											variant="outlined"
											color="primary"
											fullWidth
											onClick={() => handleRemove(index)}
										>
											Remove Item
										</Button>
									)}
								</Grid>
							) : (
								<Grid
									container
									xs={8.8}
									sm={8.8}
									md={1}
									lg={1}
									xl={1}
									sx={isXsSmMd ? { ml: 0 } : { ml: '94px', mt: 1 }}
									direction="column"
									alignItems="center"
									justifyContent="center"
								></Grid>
							)}
						</Grid>
					}
				</>
			))}
			{props.customsTotalValue > 800 && shipment_type === 'economy' && (
				<Grid container alignContent="center" justifyContent="center" direction="column">
					<Grid item mt={2}>
						<Alert severity="error">
							The combined total value of your customs items should be less than $800
						</Alert>
					</Grid>
				</Grid>
			)}
			<Grid container alignContent="center" justifyContent="center" direction="column">
				<Grid container direction="row" alignContent="center" justifyContent="center" mt={2}>
					{isXsSmMd ? (
						<Grid item>
							<Tooltip title="Add customs item.">
								<IconButton
									id={createElementId([
										CREATE_SHIPMENTS,
										CUSTOMS_INFO,
										EXPRESS,
										CUSTOM_ITEM,
										BUTTON,
										'add_item'
									])}
									onClick={() => {
										props.append({
											sortKey: '',
											description: '',
											quantity: 1,
											weight: 0,
											value: 0,
											hs_tariff_number: '',
											origin_country: 'ZA',
											currency: selectedCurrency
										});
									}}
								>
									<AddCircleOutline />
								</IconButton>
							</Tooltip>
						</Grid>
					) : (
						<Grid item xs={9} sm={9}>
							<Divider sx={{ mb: 2 }} />
							<Button
								id={createElementId([
									CREATE_SHIPMENTS,
									CUSTOMS_INFO,
									EXPRESS,
									CUSTOM_ITEM,
									BUTTON,
									'add_item'
								])}
								variant="outlined"
								color="primary"
								fullWidth
								onClick={() => {
									props.append({
										sortKey: '',
										description: '',
										quantity: 1,
										weight: 0,
										value: 0,
										hs_tariff_number: '',
										origin_country: 'ZA',
										currency: selectedCurrency
									});
								}}
							>
								Add Item
							</Button>
						</Grid>
					)}
					{/* <Grid item>
						<IconButton
							onClick={() => (props.fields.length > 1 ? props.remove(props.fields.length - 1) : {})}
						>
							<Remove></Remove>
						</IconButton>
					</Grid> */}

					<Dialog open={openModal} onClose={() => SetCloseModal(false)}>
						<CustomsSampleModal
							openModal={openModal}
							SetCloseModal={SetCloseModal}
							handleContentTypeChange={handleContentTypeChange}
						/>
					</Dialog>
				</Grid>
			</Grid>
		</>
	);
}
