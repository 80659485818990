import { InfoOutlined } from '@mui/icons-material';
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	InputBase,
	MenuItem,
	Paper,
	Select,
	Typography,
	styled
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { axiosConfig, useAuthToken } from '../../constants/axios';
import { formatDateTime, pickupDateFedex, pickupDateUPS } from '../../util/date';

export default function PickupModal(props: any) {
	const shipment: any = props.shipment;
	const { today, tomorrow, monday } = {
		...(shipment.source_api_carrier === 'FedEx' ? pickupDateFedex() : pickupDateUPS())
	};
	const { setSnackAlert, setOpenSnackBar } = props;
	const [loading, setLoading] = useState(false);
	const token = useAuthToken();
	const axiosInstance = axiosConfig(token);

	const { setValue, getValues, clearErrors } = useForm<any>({
		mode: 'all',
		defaultValues: {
			pickup_info: {
				slot: 0,
				pickup_selected: 1,
				reference: '',
				min_datetime: '',
				max_datetime: '',
				is_account_address: false
			},
			from_address: {
				address_from_city_locality: shipment.address_from_city_locality,
				address_from_country: shipment.address_from_country,
				address_from_email: shipment.address_from_email,
				address_from_name: shipment.address_from_name,
				address_from_phone: shipment.address_from_phone,
				address_from_state_province: shipment.address_from_state_province,
				address_from_street_1: shipment.address_from_street_1,
				address_from_street_2: shipment.address_from_street_2,
				address_from_zip_postal: shipment.address_from_zip_postal
			},
			reference: shipment.shipment_reference,
			shipment_id: shipment.shipment_id
		}
	});
	const [selectedSlot, setSelectedSlot] = useState(0);

	const [pickup, setPickup] = useState({
		id: '',
		min_datetime: '',
		max_datetime: '',
		confirmation: ''
	});

	useEffect(() => {
		const getPickup = async () => {
			try {
				const response = await axiosInstance.post('/pickup/getExpressPickup', {
					pickup_id: shipment?.pickup_id
				});

				const { id, min_datetime, max_datetime, confirmation } = response.data.data;

				setPickup({
					id: id,
					min_datetime: formatDateTime(min_datetime),
					max_datetime: formatDateTime(max_datetime),
					confirmation: confirmation
				});
				setLoading(false);
			} catch (error) {
				console.error('Error fetching express pickup:', error);
			}
		};
		if (shipment.pickup_id) {
			getPickup();
		}
	}, []);

	const handlePickupSlotChange = (event: any) => {
		const slot = event.target.value;
		setSelectedSlot(slot);
		setValue('pickup_info.slot', slot);
		setValue(
			'pickup_info.min_datetime',
			slot === 1
				? today.morning.minTime
				: slot === 2
				? today.afternoon.minTime
				: slot === 3
				? tomorrow.morning.minTime
				: slot === 4
				? tomorrow.afternoon.minTime
				: slot === 5
				? monday.morning.minTime
				: slot === 6
				? monday.afternoon.minTime
				: 0
		);
		setValue(
			'pickup_info.max_datetime',
			slot === 1
				? today.morning.maxTime
				: slot === 2
				? today.afternoon.maxTime
				: slot === 3
				? tomorrow.morning.maxTime
				: slot === 4
				? tomorrow.afternoon.maxTime
				: slot === 5
				? monday.morning.maxTime
				: slot === 6
				? monday.afternoon.maxTime
				: 0
		);
		clearErrors('pickup_info.slot');
	};

	const BootstrapInput = styled(InputBase)(({ theme, error }) => ({
		'label + &': {
			marginTop: theme.spacing(3)
		},
		'& .MuiInputBase-input': {
			borderRadius: 4,
			position: 'relative',
			backgroundColor: theme.palette.background.paper,
			border: `1px solid ${error ? 'red' : '#ced4da'}`,
			fontSize: 16,
			padding: '10px 26px 10px 12px',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			// Use the system font instead of the default Roboto font.
			fontFamily: [].join(','),
			'&:focus': {
				borderRadius: 4,
				borderColor: error ? 'error' : '#80bdff',
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
			}
		}
	}));

	const handleSubmission = async () => {
		try {
			setLoading(true);
			const response = await axiosInstance.post('/pickup/createAndBuyPickup', getValues());

			if (props.setPickupId) {
				props.setPickupId(response.data.data.id);
			}

			setSnackAlert({ type: 'success', message: response.data.message });
			setOpenSnackBar(true);
			props.setPickupModalOpen(false);
		} catch (error: any) {
			console.error('Error creating express pickup:', error);
			setSnackAlert({
				type: 'error',
				message: error?.response?.data ? error.response.data.data : 'Error: An error has occurred'
			});
			setOpenSnackBar(true);
			setLoading(false);
		}
	};

	return (
		<>
			<Grid container alignItems="center" justifyContent={'center'} justifySelf={'center'} direction="column">
				<Paper
					sx={{
						padding: '40px'
					}}
				>
					{loading ? (
						<Box sx={{ display: 'flex', justifyContent: 'center' }} m={3}>
							<CircularProgress disableShrink size={30} />
						</Box>
					) : (
						<>
							<Grid item textAlign={'center'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography variant="h5" mb={5} mt={5} ml={4} mr={4}>
									Parcel Collection Date
								</Typography>
							</Grid>
							<Grid item>
								{!shipment.pickup_id ? (
									<Typography variant="body1">No scheduled Pickup</Typography>
								) : (
									<>
										{pickup?.id !== '' && (
											<>
												<Typography variant="body1">
													Pickup Min: {pickup?.min_datetime}
												</Typography>
												<Typography variant="body1">
													Pickup Max: {pickup?.max_datetime}
												</Typography>
												<Typography variant="body1">
													Confirmation Code: {pickup?.confirmation}
												</Typography>
											</>
										)}
									</>
								)}
							</Grid>
							<Grid item textAlign={'center'} xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
								<Box style={{ border: 'none', boxShadow: '1px 1px solid gray' }}>
									<>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<FormControl variant={'standard'} error fullWidth>
													<Select
														// disabled={pickup === 0 || pickup === 7 ? true : false}
														value={selectedSlot}
														onChange={handlePickupSlotChange}
														input={<BootstrapInput />}
														fullWidth
													>
														<MenuItem disabled value={0}>
															<em>Please Select Timeslot</em>
														</MenuItem>
														{today?.morning ? (
															<MenuItem value={1}>Today (Morning)</MenuItem>
														) : (
															<></>
														)}
														{today?.afternoon ? (
															<MenuItem value={2}>Today (Afternoon)</MenuItem>
														) : (
															<></>
														)}
														{tomorrow?.morning ? (
															<MenuItem value={3}>Tomorrow (Morning)</MenuItem>
														) : (
															<></>
														)}
														{tomorrow?.afternoon ? (
															<MenuItem value={4}>Tomorrow (Afternoon)</MenuItem>
														) : (
															<></>
														)}
														{monday?.morning ? (
															<MenuItem value={5}>Monday (Morning)</MenuItem>
														) : (
															<></>
														)}
														{monday?.afternoon ? (
															<MenuItem value={6}>Monday (Afternoon)</MenuItem>
														) : (
															<></>
														)}
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</>
								</Box>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} lineHeight={1} mt={2}>
								<Grid container direction="column" alignContent="center" justifyContent="center">
									<Grid item alignSelf="center">
										<InfoOutlined fontSize="small" sx={{ color: '#1abc9c' }} />
									</Grid>
									<Grid item>
										<Typography variant="caption" color={'#1abc9c'}>
											Morning Pickups 8am - 12pm{' '}
										</Typography>
									</Grid>
									<Grid item>
										<Typography variant="caption" color={'#1abc9c'}>
											Afternoon Pickups 12pm - 5pm
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} lineHeight={1} mt={2}>
								<Grid container direction="row" alignContent="center" justifyContent="space-around">
									<Grid item>
										<Button
											disabled={selectedSlot === 0 ? true : false}
											sx={{
												backgroundColor: '#03C988',
												':hover': {
													backgroundColor: '#057852'
												},
												whiteSpace: 'nowrap'
											}}
											variant="contained"
											size="small"
											onClick={() => {
												handleSubmission();
											}}
										>
											{shipment.pickup_id ? 'Reschedule Pickup' : 'Schedule Pickup'}
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</>
					)}
				</Paper>
			</Grid>
		</>
	);
}
