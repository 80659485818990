import { Authenticator, Button, Flex, Heading, useAuthenticator, useTheme, View } from '@aws-amplify/ui-react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Box, CircularProgress, Stack, Typography, useTheme as useMuiTheme } from '@mui/material';
import { I18n } from 'aws-amplify';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthenticationContainer from '../components/Layout/AuthenticationContainer';
import Layout from '../components/Layout/Layout';
import CreateBooking from './Customs/Booking';
import CustomsDashboard from './Customs/CustomsDashboard';
import CustomsPushtrackers from './Customs/CustomsPushtrackers';
import CustomsShipments from './Customs/CustomsShipments';
import ShipmentReview from './Customs/ShipmentReview';
import Home from './Home/Home';
import NotFound from './Home/NotFound';
import Shipment from './Merchant/CreateShipment';
import MerchantProfile from './Merchant/MerchantProfile';
import Profile from './Merchant/Profile';
import SalesChannels from './Merchant/SalesChannels';
import ShipmentSummary from './Merchant/ShipmentSummary';
import TrackerMerchant from './Merchant/TrackerMerchant';
import ProductLibrary from './Products/ProductLibrary';
import UsersDashboard from './Users/UsersDashboard';

export default function Main() {
	const muiTheme = useMuiTheme();
	const { tokens } = useTheme();
	const [selectedMerchant, setSelectedMerchant] = useState(null);

	const { authStatus, user, signOut, route } = useAuthenticator(context => [
		context.authStatus,
		context.user,
		context.route
	]);
/*

justifyContent: 
*/
	function authHeader(text: string) {
		return (
			<Flex
				justifyContent="center"
				alignItems="center"
				direction="column"
				padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
				color={tokens.colors.font.primary}
				textAlign="center"
				placeholder="" // Add missing properties with default values
      			onPointerEnterCapture={() => {}} // Provide no-op handlers if needed
     			onPointerLeaveCapture={() => {}}
			>
				<Box display={{ md: 'none' }}>
					<img style={{ height: '150px' }} src="./img/tunl-logo-blue.png" alt="TUNL logo" />
				</Box>
				<Avatar sx={{ m: 1, bgcolor: muiTheme.palette.error.main }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h4">
					{text}
				</Typography>
			</Flex>
		);
	}

	const components = {
		Header() {
			const { tokens } = useTheme();

			return <View textAlign="center" padding={tokens.space.large}></View>;
		},

		SignIn: {
			Header() {
				return authHeader('Sign in');
			},
			Footer() {
				const { toResetPassword, toSignUp } = useAuthenticator();

				return (
					<Flex 
						padding={`0 ${tokens.space.xl} 0 ${tokens.space.xl}`} 
						justifyContent="space-between"
						placeholder="" // Add missing properties with default values
      					onPointerEnterCapture={() => {}} // Provide no-op handlers if needed
     					onPointerLeaveCapture={() => {}}
						>
						<Button
							fontWeight="normal"
							padding="0px"
							onClick={toResetPassword}
							size="small"
							variation="link"
							placeholder=""
							onPointerEnterCapture={() => {}}
							onPointerLeaveCapture={() => {}}
						>
							Forgot Password?
						</Button>
						<Button 
							fontWeight="normal" 
							padding="0px" 
							onClick={toSignUp} 
							size="small" 
							variation="link"
							placeholder=""
							onPointerEnterCapture={() => {}}
							onPointerLeaveCapture={() => {}}
							>
							Don't have an account? <b style={{ paddingLeft: '5px' }}>Sign up</b>
						</Button>
					</Flex>
				);
			}
		},

		SignUp: {
			Header() {
				return authHeader('Sign up');
			},
			Footer() {
				const { toSignIn } = useAuthenticator();

				return (
					<View textAlign="center">
						<Button 
							fontWeight="normal" 
							onClick={toSignIn} 
							size="small" 
							variation="link"
							placeholder=""
							onPointerEnterCapture={() => {}}
							onPointerLeaveCapture={() => {}}
							>
							Already have an account? <b style={{ paddingLeft: '5px' }}>Sign in</b>
						</Button>
					</View>
				);
			}
		},
		ConfirmSignUp: {
			Header() {
				const { tokens } = useTheme();
				return (
					<Heading 
						padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} 
						level={3}
						placeholder=""
						onPointerEnterCapture={() => {}}
						onPointerLeaveCapture={() => {}}
						>
						Enter Information:
					</Heading>
				);
			}
		},
		SetupTOTP: {
			Header() {
				const { tokens } = useTheme();
				return (
					<Heading 
						padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} 
						level={3}
						placeholder=""
						onPointerEnterCapture={() => {}}
						onPointerLeaveCapture={() => {}}
						>
						Enter Information:
					</Heading>
				);
			}
		},
		ConfirmSignIn: {
			Header() {
				const { tokens } = useTheme();
				return (
					<Heading 
						padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}
						placeholder=""
						onPointerEnterCapture={() => {}}
						onPointerLeaveCapture={() => {}}
						>
						Enter Information:
					</Heading>
				);
			}
		},
		ResetPassword: {
			Header() {
				const { tokens } = useTheme();
				return (
					<Heading 
						padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} 
						level={3}
						placeholder=""
						onPointerEnterCapture={() => {}}
						onPointerLeaveCapture={() => {}}
						>
						Enter Information:
					</Heading>
				);
			}
		},
		ConfirmResetPassword: {
			Header() {
				const { tokens } = useTheme();
				return (
					<Heading 
						padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} 
						level={3}
						placeholder=""
						onPointerEnterCapture={() => {}}
						onPointerLeaveCapture={() => {}}
						>
						Enter Information:
					</Heading>
				);
			}
		}
	};

	I18n.putVocabulariesForLanguage('en', {
		Email: 'Email Address*',
		Password: 'Password*',
		'Confirm Password': 'Confirm Password*',
		'Create Account': 'Sign up'
	});

	const formFields = {
		signUp: {
			name: {
				order: 1,
				label: 'Full Name',
				placeholder: 'Full Name'
			},
			'custom:job_title': {
				order: 2,
				placeholder: 'Job Title',
				labelHidden: true
			}
		}
	};

	function isSignIn(): boolean {
		let params = new URLSearchParams(document.location.search);
		let signUpParam = params.get('s');

		if (signUpParam && signUpParam === 'signup') {
			return false;
		}

		return true;
	}

	function getInitialState(): 'signIn' | 'signUp' | 'resetPassword' {
		let initialState: 'signIn' | 'signUp' | 'resetPassword' = 'signIn';

		if (isSignIn() === false) {
			initialState = 'signUp';
		}

		return initialState;
	}

	let params = new URLSearchParams(document.location.search);;
	let shopifyParams: {
		code: string | null,
		state: string | null,
		shop: string | null
	};

	shopifyParams = {
		code:  null,
		state: null,
		shop:  null
	}

	if (params.get('code') !== null && params.get('state') !== null && params.get('shop') !== null) {
		shopifyParams = {
			code: params.get('code') ,
			state: params.get('state'),
			shop: params.get('shop')
		}

		sessionStorage.setItem('shopifyParams', JSON.stringify(shopifyParams));
	}
	if (authStatus !== 'authenticated') {
		return (
			<AuthenticationContainer
				text={
					isSignIn()
						? 'Sign in and start shipping.'
						: 'Sign up for free and book your first parcel in under 2 minutes.'
				}
			>
				<Authenticator initialState={getInitialState()} formFields={formFields} components={components} />
				{(authStatus === 'configuring' || route === 'idle' || route === 'setup' || route === 'signOut') && (
					<Stack alignItems="center" pt={12}>
						<CircularProgress size="2rem" color="primary" />
						<Typography
							style={{ fontFamily: 'NeueMachinaUltraBold' }}
							pt={4}
							px={8}
							variant="h4"
							color={muiTheme.palette.primary.main}
							textAlign="center"
						>
							One moment...
						</Typography>
					</Stack>
				)}
			</AuthenticationContainer>
		);
	} else {
		return (
			<Authenticator initialState={ getInitialState() } formFields={formFields} components={components}>
				{({ signOut, user }) => (
					<Layout user={user} signOut={signOut} selectedMerchant={selectedMerchant}>
						<Routes>
							<Route path="/" element={<Home user={user} setSelectedMerchant={setSelectedMerchant} />} />
							{/* <Route path="/auth/shopify/callback/*" element={<SalesChannels/>} /> */}
							<Route path="/shipment" element={<Shipment user={user} />} />
							<Route path="/products" element={<ProductLibrary />} />
							<Route path="/shipment/details" element={<ShipmentSummary />} />
							<Route path="/profile" element={<Profile user={user} />} />
							<Route path="/merchant" element={<MerchantProfile user={user} />} />
							<Route path="/users" element={<UsersDashboard />} />
							<Route path="/live-rates" element={<SalesChannels />} />
							<Route path="/customs/shipments/review" element={<ShipmentReview />} />
							<Route path="/customs/booking/create" element={<CreateBooking />} />
							<Route path="/customs/dashboard" element={<CustomsDashboard />} />
							<Route path="/customs/push_trackers" element={<CustomsPushtrackers />} />
							<Route path="/customs/shipments" element={<CustomsShipments />} />
							<Route path="/Tracker" element={<TrackerMerchant />} />
							{/* Catch all path */}
							<Route path="*" element={<NotFound />} />
						</Routes>
					</Layout>
				)}
			</Authenticator>
		);
	}
}
