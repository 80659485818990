import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { throttle } from 'lodash';
import { Loader } from '@googlemaps/js-api-loader';
import { useEffect } from 'react';
import { AddressState, setAddress } from '../../../../stores/address';
import { store } from '../../../../app/store';

const loader = new Loader({
	apiKey: 'AIzaSyBbkDqASG29sSuhLABJD17nzuGsPyCBcbQ',
	version: 'weekly',
	libraries: ['places']
});

const autocompleteService = { current: null, map: null };
let googleApi: any = null;

interface MainTextMatchedSubstrings {
	offset: number;
	length: number;
}
interface StructuredFormatting {
	main_text: string;
	secondary_text: string;
	main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
	description: string;
	place_id: string;
	structured_formatting: StructuredFormatting;
}

export default function AddressAutoComplete(props: any) {
	const [value, setValue] = React.useState<PlaceType | null>(null);
	const [inputValue, setInputValue] = React.useState('');
	const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
	const loaded = React.useRef(false);
	const {addressId} = props;

	loader.load().then(google => {
		googleApi = google;
		autocompleteService.map = new googleApi.maps.Map(document.getElementById('map') as HTMLElement, {
			center: { lat: -34.397, lng: 150.644 },
			zoom: 8
		});
		loaded.current = true;
	});

	const fetch = React.useMemo(
		() =>
			throttle((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
				const bounds = new googleApi.maps.LatLngBounds(
					new googleApi.maps.LatLng(24.7433195, -124.7844079), // Southwest coordinates of the USA
					new googleApi.maps.LatLng(49.3457868, -66.9513812) // Northeast coordinates of the USA
				);
				const options = {
					input: request.input,
					bounds: props.shipment_type === 'economy' ? bounds : null,
					componentRestrictions: props.shipment_type === 'economy' && { country: 'us' }
				};
				(autocompleteService.current as any).getPlacePredictions(options, (predictions: PlaceType[]) => {
					const poBoxRegex = /\b[P|p]\.?\s*[O|o]\.?\s*[B|b][O|o|0][X|x]\b/;
					const filteredPredictions = predictions.filter(prediction => {
						return !poBoxRegex.test(prediction.description);
					});
					callback(filteredPredictions);
				});
			}, 200),
		[]
	);

	useEffect(() => {
		const address_fields = {
			address_street_1: '',
			address_street_2: '',
			address_zip_postal: '',
			address_city_locality: '',
			address_state_province: '',
			address_country: ''
		};
		let active = true;

		if (!autocompleteService.current && googleApi) {
			const autocompleteOptions = {
				componentRestrictions: { country: 'us' }
			};
			autocompleteService.current = new googleApi.maps.places.AutocompleteService(autocompleteOptions);
		}
		if (!autocompleteService.current) {
			return undefined;
		}

		if (inputValue === '') {
			setOptions(value ? [value] : []);
			return undefined;
		}

		fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
			if (active) {
				let newOptions: readonly PlaceType[] = [];

				if (value) {
					newOptions = [value];
					new googleApi.maps.places.PlacesService(autocompleteService.map).getDetails(
						{ placeId: newOptions[0].place_id },
						(place: any) => {
							for (const component of place.address_components) {
								// @ts-ignore remove once typings fixed
								const componentType = component.types[0];

								switch (componentType) {
									case 'street_number': {
										address_fields.address_street_1 = `${component.long_name} `;
										break;
									}

									case 'route': {
										address_fields.address_street_1 += component.long_name;
										break;
									}

									case 'subpremise': {
										address_fields.address_street_2 = `${component.long_name}`;
										break;
									}

									case 'postal_code': {
										address_fields.address_zip_postal = `${component.long_name}${address_fields.address_zip_postal}`;
										break;
									}
									case 'locality':
										address_fields.address_city_locality = component.long_name;
										break;
									case 'postal_town':
										address_fields.address_city_locality = component.long_name;
										break;
									case 'administrative_area_level_1': {
										address_fields.address_state_province =
											component.short_name === 'KZN' ? 'ZN' : component.short_name;
										break;
									}
									case 'country':
										address_fields.address_country = component.short_name;
										break;
								}
							}
							if (props.setMerchantAddressDetails) {
								props.setMerchantAddressDetails({
									merchant_street_1: address_fields.address_street_1,
									merchant_zip_postal: address_fields.address_zip_postal,
									merchant_city_locality: address_fields.address_city_locality,
									merchant_state_province: address_fields.address_state_province,
									merchant_country: address_fields.address_country
								});
							} else {
								props.setToAddressDetails
									? props.setToAddressDetails({
											address_to_street_1: address_fields.address_street_1,
											address_to_street_2: address_fields.address_street_2,
											address_to_zip_postal: address_fields.address_zip_postal,
											address_to_city_locality: address_fields.address_city_locality,
											address_to_state_province: address_fields.address_state_province,
											address_to_country: address_fields.address_country
									  })
									: props.setFromAddressDetails({
											address_from_street_1: address_fields.address_street_1,
											address_from_zip_postal: address_fields.address_zip_postal,
											address_from_city_locality: address_fields.address_city_locality,
											address_from_state_province: address_fields.address_state_province,
											address_from_country: address_fields.address_country
									  });
							}
						}
					);
				}

				if (results) {
					newOptions = [...newOptions, ...results];
				}
				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};
	}, [value, inputValue, fetch]);

	return (
		<>
			<div hidden id="map"></div>
			<div>
				<Autocomplete
				    id={addressId}
					getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
					filterOptions={x => x}
					options={options}
					autoComplete
					includeInputInList
					filterSelectedOptions
					value={value}
					onChange={(event: any, newValue: PlaceType | null) => {
						setOptions(newValue ? [newValue, ...options] : options);
						setValue(newValue);
					}}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
					}}
					renderInput={params => (
						<TextField
						{...params}
						  required={props.required}
						  label="Start typing to autocomplete address..."
						  fullWidth
						/>
					)}
					renderOption={(props, option) => {
						const matches = option.structured_formatting.main_text_matched_substrings;
						const parts = matches
							? parse(
									option.structured_formatting.main_text,
									matches.map((match: any) => [match.offset, match.offset + match.length])
							  )
							: [];

						return (
							<li {...props}>
								<Grid container alignItems="center">
									<Grid item>
										<Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
									</Grid>
									<Grid id={addressId + '.optionItem'} item xs>
										{parts.map((part, index) => (
											<span
												key={index}
												style={{
													fontWeight: part.highlight ? 700 : 400
												}}
											>
												{part.text}
											</span>
										))}
										<Typography variant="body2" color="text.secondary">
											{option.structured_formatting.secondary_text}
										</Typography>
									</Grid>
								</Grid>
							</li>
						);
					}}
				/>
			</div>
		</>
	);
}
