export type Tracker = {
	carrier_origin_country?: string;
	carrier_service: string;
	carrier_track_code: string;
	carrier_track_url: string;
	carrier?: string;
	date_created: string;
	date_est_delivery: string | null;
	date_last_update: string;
	event_carrier_datetime: string;
	event_city_locality: string | null;
	event_country: string | null;
	event_data_source?: string;
	event_description: string | null;
	event_message?: string | null;
	event_state_province: string | null;
	event_status_detail: string;
	event_status: string;
	event_utc_datetime?: string;
	event_zip_postal?: string | null;
	shipment_id: string;
	source_carrier?: string | null;
	source_shipment_id?: string;
	source_track_id?: string;
	source_track_url?: string;
	source?: string;
	tracker_id: string;
	tracker_mode?: string;
	tracker_status_description?: string;
	tracker_url: string;
};

export type TrackerRecord = Tracker & {
	sortKey?: string;
	partitionKey?: string;
};

export enum TrackingStatus {
	Incomplete = '',
	PreTransit = 'pre_transit',
	InTransit = 'in_transit',
	Cancelled = 'cancelled',
	OutForDelivery = 'out_for_delivery',
	Delivered = 'delivered',
	AvailableForPickUp = 'available_for_pickup',
	PendingCancel = 'pending_cancel',
	ReturnToSender = 'return_to_sender',
	Failure = 'failure',
}
