import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	AlertColor,
	Backdrop,
	Box,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputBase,
	Link,
	MenuItem,
	Paper,
	Popover,
	Select,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography,
	styled,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	CHECKBOX,
	CHECKOUT,
	CREATE_SHIPMENTS,
	CUSTOMS_INFO,
	DFT,
	ECONOMY,
	EXPRESS,
	FEE_OPTION,
	FORM_CONTROL,
	FORM_CONTROL_LABEL,
	HEADING,
	KEY,
	LABEL,
	LINK,
	MENU_ITEM,
	PAPER,
	PARCEL_COLLECTION,
	PICK_UP_OPTION,
	PICK_UP_TIME_SLOT,
	SELECT,
	SHIPMENT_COST,
	SHIPMENT_PROTECTION,
	SHIPMENT_SUMMARY,
	SHIPPING,
	SIGNATURE_REQUIRED,
	TABLE,
	TABLE_BODY,
	TABLE_CELL,
	TABLE_HEAD,
	TABLE_ROW,
	TEXTFIELD,
	VALUE,
	createElementId
} from '../../../../constants/id';

import { axiosConfig, useAuthToken } from '../../../../constants/axios';
import text from '../../../../constants/text';
import { MerchantRecord } from '../../../../types/merchant';
import { CustomsItem, ShipmentData } from '../../../../types/shipment';
import { pickupDateFedex, pickupDateUPS } from '../../../../util/date';
import LearnMore from '../../../Common/LearnMore';
import TNAlerts from '../../../Common/TNAlerts';

export default function CheckoutForm(props: any) {
	const token = useAuthToken();
	const axiosInstance = axiosConfig(token);
	const customsItems = props.getValues().customs_info.customs_items;
	const shippingRate = props.getValues().rate;
	const {
		watch,
		getValues,
		setError,
		clearErrors,
		setValue,
		setPickupSlot,
		insuranceValue,
		consent,
		setInsuranceValue,
		setValidInsurance,
		setAddedInsurance,
		setConsent,
		invalidLandedCosts,
		setInvalidLandedCosts
	} = props;
	const shipment: ShipmentData = props.getValues();
	const shipmentVol = getValues('parcel_details.volumetric_weight');
	const merchant: MerchantRecord = JSON.parse(sessionStorage.getItem('merchant') as string);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [waiting, setWaiting] = useState(true);
	const [pickup, setPickup] = useState<0 | 1 | 7>(getValues('pickup.pickup_selected'));
	const [landedCostsError, setLandedCostsError] = useState(false);
	const [landedCostsErrorMessage, setLandedCostsErrorMessage] = useState<string>('Landed Costs Error');
	const [noDuties, setNoDuties] = useState(false);
	const [merchantDDP, setMerchantDDP] = useState(merchant.merchant_settings.ddp);
	const [useOwnTaxId, setUseOwnTaxId] = useState(merchant.merchant_settings.use_own_tax_id);
	const merchantSettings: any = merchant.merchant_settings;
	const [shipmentDeliveryConfirmation, setShipmentDeliveryConfirmation] = useState(
		getValues('delivery_confirmation') !== true ? false : true
	);
	const [deliveryConfirmationCost, setDeliveryConfirmationCost] = useState(shipmentDeliveryConfirmation ? 50.0 : 0.0);
	const [landedCostsTotal, setLandedCostsTotal] = useState<number>();
	const [landedCosts, setLandedCosts] = useState({
		duties: 0.0,
		fees: 0.0,
		taxes: 0.0
	});
	const landedCostCurrency =
		customsItems[0].currency === 'ZAR'
			? 'R'
			: customsItems[0].currency === 'GBP'
			? '£'
			: customsItems[0].currency === 'EUR'
			? '€'
			: customsItems[0].currency === 'USD'
			? '$'
			: customsItems[0].currency === 'CAD'
			? 'C$'
			: customsItems[0].currency === 'AUD'
			? 'A$'
			: '';

	const [landedCostsBreakdown, setLandedCostsBreakdown] = useState({
		duties: [],
		fees: [],
		taxes: []
	});
	const [deMinimis, setDeMinimis] = useState('');

	const [selectedSlot, setSelectedSlot] = useState(getValues('pickup.slot'));
	const { today, tomorrow, monday } = {
		...(shipment.service === 'FEDEX_INTERNATIONAL_PRIORITY' ? pickupDateFedex() : pickupDateUPS())
	};

	const [loading, setLoading] = useState(false);
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.only('xs'));
	const isSm = useMediaQuery(theme.breakpoints.only('sm'));
	const isMd = useMediaQuery(theme.breakpoints.only('md'));

	const [selectedService, setSelectedService] = useState('');
	const [apiResponse, setApiResponse] = useState<{
		type: AlertColor;
		message: string;
	}>({ type: 'success', message: '' });

	const [addInsurance, setAddInsurance] = useState(true);
	const [open, setOpen] = useState(false);

	const calculateCustomItems = () => {
		let customsValues: number[] = [];

		customsItems.forEach((item: any) => {
			if (item.currency === 'ZAR') {
				customsValues.push(item.value);
			}
		});

		const totalValues: any = customsValues.reduce((a: number, b: number) => a + b, 0).toString();

		return totalValues === '0' ? '' : totalValues;
	};

	const validateInsuredValue = (value: any) => {
		return (value < 0 || !value) && addInsurance;
	};

	const calculateInsuredValue = () => {
		let formattedInsuranceValue = insuranceValue?.replace(/,/g, '');

		if (formattedInsuranceValue > 0) {
			if (formattedInsuranceValue > 2000)
				return !isNaN(formattedInsuranceValue) ? formattedInsuranceValue * 0.025 : 50.0;
			else {
				return 50.0;
			}
		} else {
			if (!addInsurance) {
				return 0.0;
			} else {
				return parseFloat(formattedInsuranceValue) === 0 || isNaN(formattedInsuranceValue) ? 0.0 : 50.0;
			}
		}
	};

	const canBuyShipment = () => {
		const updatedInsuranceValue = insuranceValue === undefined ? calculateCustomItems() : insuranceValue;
		return isNaN(updatedInsuranceValue) || !(updatedInsuranceValue === '');
	};

	const Img = styled('img')({
		display: 'block',
		maxWidth: '170px',
		maxHeight: '170px'
	});
	const navigate = useNavigate();

	useEffect(() => {
		setInsuranceValue(calculateCustomItems);
		setValidInsurance(canBuyShipment);
		setInvalidLandedCosts(false);
	}, []);

	useEffect(() => {
		if (pickup === 1 && selectedSlot === 0) {
			setError('pickup.slot', { type: 'custom', message: 'Timeslot is required' });
		} else {
			clearErrors('pickup.slot');
		}
		if (pickup === 1 && selectedSlot === 7) {
			setSelectedSlot(0);
			setError('pickup.slot', { type: 'custom', message: 'Timeslot is required' });
		} else {
			clearErrors('pickup.slot');
		}
	}, [pickup]);

	const getExchangeRate = async () => {
		const sourceCurrencyCode = customsItems[0].currency;
		const targetCurrencyCode = 'ZAR';
		!useOwnTaxId
			? await axiosInstance
					.post('/zonos/zonosExchangeRate', {
						source_currency: sourceCurrencyCode,
						target_currency: targetCurrencyCode
					})
					.then(response => {
						if (response.data?.data) {
							setLandedCostsTotal(
								Number(
									(
										(landedCosts.duties + landedCosts.fees + landedCosts.taxes) *
										response.data?.data?.rate
									).toFixed(2)
								)
							);
							setValue('rates_breakdown.ddp.exchange_rate', response.data?.data?.rate);
							setValue('rates_breakdown.ddp.base_currency', response.data?.data?.sourceCurrencyCode);
							setValue(
								'rates_breakdown.ddp.landed_costs_total_base',
								landedCosts.duties + landedCosts.fees + landedCosts.taxes
							);
							setValue(
								'rates_breakdown.ddp.landed_costs_total_rands',
								Number(
									(
										(landedCosts.duties + landedCosts.fees + landedCosts.taxes) *
										response.data?.data?.rate
									).toFixed(2)
								)
							);
							setValue('rates_breakdown.delivery_confirmation', deliveryConfirmationCost.toFixed(2));
						}
					})
					.catch(err => {
						console.log(err);
					})
					.finally(() => {
						setWaiting(false);
					})
			: setWaiting(false);
	};

	const handlePickupChange = (event: any) => {
		if (event.target.value === 0) {
			setValue('pickup.slot', 0);
			setValue('pickup.min_datetime', '');
			setValue('pickup.max_datetime', '');
			setSelectedSlot(0);
		}
		if (event.target.value === 7) {
			setValue('pickup.slot', 7);
			setValue('pickup.min_datetime', '');
			setValue('pickup.max_datetime', '');
			setSelectedSlot(0);
		}
		setSelectedSlot(0);
		setValue('pickup.pickup_selected', event.target.value);
		setPickup(event.target.value);
	};

	const handleDutiesChange = (event: any) => {
		if (event.target.value === 'true') {
			setMerchantDDP(true);
			setValue('customs_incoterm', 'DDP');
			setLandedCostsError(false);
			if (invalidLandedCosts) setInvalidLandedCosts(false);
		} else {
			setMerchantDDP(false);
			setValue('customs_incoterm', 'DAP');
			setLandedCostsTotal(undefined);
			setValue('rates_breakdown.ddp.landed_costs_total_rands', 0);
			setLandedCostsError(false);
			if (invalidLandedCosts) setInvalidLandedCosts(false);
		}
	};

	const handleShipmentDeliveryConfirmationChange = (event: any) => {
		if (event.target.value === 'true') {
			setShipmentDeliveryConfirmation(true);
			setDeliveryConfirmationCost(50.0);
			setValue('delivery_confirmation', true);
		} else {
			setShipmentDeliveryConfirmation(false);
			setDeliveryConfirmationCost(0.0);
			setValue('delivery_confirmation', false);
		}
	};

	const handlePickupSlotChange = (event: any) => {
		const slot = event.target.value;
		setSelectedSlot(slot);
		setValue('pickup.slot', slot);
		setValue(
			'pickup.min_datetime',
			slot === 1
				? today.morning.minTime
				: slot === 2
				? today.afternoon.minTime
				: slot === 3
				? tomorrow.morning.minTime
				: slot === 4
				? tomorrow.afternoon.minTime
				: slot === 5
				? monday.morning.minTime
				: slot === 6
				? monday.afternoon.minTime
				: 0
		);
		setValue(
			'pickup.max_datetime',
			slot === 1
				? today.morning.maxTime
				: slot === 2
				? today.afternoon.maxTime
				: slot === 3
				? tomorrow.morning.maxTime
				: slot === 4
				? tomorrow.afternoon.maxTime
				: slot === 5
				? monday.morning.maxTime
				: slot === 6
				? monday.afternoon.maxTime
				: 0
		);
		clearErrors('pickup.slot');
	};

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackBar(false);
	};

	const BootstrapInput = styled(InputBase)(({ theme, error }) => ({
		'label + &': {
			marginTop: theme.spacing(3)
		},
		'& .MuiInputBase-input': {
			borderRadius: 4,
			position: 'relative',
			backgroundColor: theme.palette.background.paper,
			border: `1px solid ${error ? 'red' : '#ced4da'}`,
			fontSize: 16,
			padding: '10px 26px 10px 12px',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			// Use the system font instead of the default Roboto font.
			fontFamily: [].join(','),
			'&:focus': {
				borderRadius: 4,
				borderColor: error ? 'error' : '#80bdff',
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
			}
		}
	}));

	const checkConsent = () => {
		consent === false ? setConsent(true) : setConsent(false);
		return consent;
	};

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const handleLandedCostsErrors = (error: any) => {
		setLandedCostsError(true);
		setInvalidLandedCosts(true);
		error.response.data.error && !merchantDDP
			? setLandedCostsErrorMessage('Landed Costs call Failed')
			: setLandedCostsErrorMessage(
					'Reason: ' + error.response.data.error + '\n Only DAP available for this country.'
			  );
	};

	const getLandedCosts = async () => {
		setWaiting(true);
		const items: any = [];
		shipment.customs_info.customs_items.map((item: CustomsItem, index: number) => {
			items.push({
				id: 'item ' + (index + 1),
				amount: item.value,
				country_of_origin: item.origin_country,
				hs_code: item.hs_tariff_number,
				quantity: 1
			});
		});
		const landedCostData = {
			currency: customsItems[0].currency,
			discounts: [
				{
					id: 'sale124',
					amount: 0.0,
					detail: null
				}
			],
			items: items,
			landed_cost: merchantDDP ? 'delivery_duty_paid' : 'delivery_duty_unpaid',
			ship_from_country: 'ZA',
			ship_to: {
				city: shipment.to_address.address_to_city_locality,
				country: shipment.to_address.address_to_country,
				postal_code: shipment.to_address.address_to_zip_postal,
				state: shipment.to_address.address_to_state_province
			},
			shipping: {
				amount: 0,
				service_level:
					shipment.service === 'FEDEX_INTERNATIONAL_PRIORITY'
						? 'fedex_international_priority'
						: shipment.service === 'UPSSaver'
						? 'ups_express_saver'
						: ''
			},
			tariff_rate: 'maximum'
		};

		!useOwnTaxId
			? await axiosInstance
					.post('/zonos/zonosLandedCosts', landedCostData)
					.then(response => {
						if (response.data) {
							setLandedCosts({ ...response.data?.data?.amountSubtotals });
							setValue('rates_breakdown.ddp', {
								duties: response.data?.data?.duties,
								fees: response.data?.data?.fees,
								taxes: response.data?.data?.taxes,
								amount_subtotals_base: response.data?.data?.amountSubtotals
							});
							setLandedCostsBreakdown({
								duties: response.data?.data?.duties,
								fees: response.data?.data?.fees,
								taxes: response.data?.data?.taxes
							});
							setDeMinimis(
								response.data?.data?.deMinimis.find((item: any) => item.type === 'DUTY')?.note
							);
							setValue('rates_breakdown.ddp.landed_cost_id', response.data?.data?.id);
						}
					})
					.catch(err => {
						console.log(err);
						handleLandedCostsErrors(err);
						setWaiting(false);
					})
			: setWaiting(false);
	};

	// useEffect(() => {
	// 	getLandedCosts();
	// 	setWaiting(false);
	// }, []);

	useEffect(() => {
		getLandedCosts();
		if (!merchantDDP) {
			setWaiting(false);
		}
	}, [merchantDDP]);

	useEffect(() => {
		if (merchantDDP) getExchangeRate();
	}, [landedCosts]);

	return (
		<>
			<div
				style={{
					display: 'block',
					justifyContent: 'center',
					alignContent: 'center',
					marginBottom: '20px',
					maxWidth: 998,
					marginLeft: 'auto',
					marginRight: 'auto'
				}}
			>
				<Grid container direction={'column'} textAlign={'center'}>
					<Typography
						id={createElementId([CREATE_SHIPMENTS, CHECKOUT, HEADING, LABEL, CHECKOUT])}
						variant="h4"
						mb={2}
						mt={2}
					>
						Checkout{' '}
					</Typography>
				</Grid>
				<Grid
					container
					direction="column"
					alignContent="space-between"
					justifyContent="space-between"
					textAlign={'left'}
					lineHeight={1}
					spacing={1}
				>
					<Grid container direction="row" sx={{ marginBottom: '10px', marginTop: '10px' }}></Grid>
					<Grid container direction="row" justifyContent="space-between" sx={{ paddingBottom: '20px' }}>
						{/* TODO: look at bringing Typography */}
						<Grid item>Reference: {shipment.reference}</Grid>
					</Grid>
				</Grid>
				<Grid container direction="column" alignContent="center" justifyContent="center" lineHeight={5}>
					<Grid item width={'100%'}>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Accordion expanded={true}>
								<AccordionSummary>
									<Typography
										id={createElementId([
											CREATE_SHIPMENTS,
											CHECKOUT,
											SHIPMENT_SUMMARY,
											HEADING,
											LABEL,
											KEY,
											'to'
										])}
										variant="h6"
										sx={{
											fontWeight: 'bold',
											width: '30%',
											flexShrink: 0
										}}
									>
										To:
									</Typography>
									<Typography
										id={createElementId([
											CREATE_SHIPMENTS,
											CHECKOUT,
											SHIPMENT_SUMMARY,
											HEADING,
											LABEL,
											VALUE,
											'to'
										])}
										variant="body1"
										sx={{
											width: '50%',
											flexShrink: 0
										}}
									>
										{shipment.to_address.address_to_name},{shipment.to_address.address_to_company},
										{shipment.to_address.address_to_street_1},
										{shipment.to_address.address_to_street_2},
										{shipment.to_address.address_to_city_locality},{' '}
										{shipment.to_address.address_to_state_province},{' '}
										{shipment.to_address.address_to_zip_postal},{' '}
										{shipment.to_address.address_to_country},{' '}
										<Link
											id={createElementId([
												CREATE_SHIPMENTS,
												CHECKOUT,
												SHIPMENT_SUMMARY,
												HEADING,
												LABEL,
												VALUE,
												LINK,
												'to'
											])}
											href={'mailto:' + shipment.to_address.address_to_email}
											color="inherit"
											underline="always"
										>
											{shipment.to_address.address_to_email},
										</Link>
										{shipment.to_address.address_to_phone}
									</Typography>
								</AccordionSummary>
								<AccordionDetails></AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Accordion expanded={true}>
								<AccordionSummary>
									<Typography
										id={createElementId([
											CREATE_SHIPMENTS,
											CHECKOUT,
											SHIPMENT_SUMMARY,
											HEADING,
											LABEL,
											KEY,
											'from'
										])}
										variant="h6"
										sx={{
											fontWeight: 'bold',
											width: '30%',
											flexShrink: 0
										}}
									>
										From:
									</Typography>
									<Typography
										id={createElementId([
											CREATE_SHIPMENTS,
											CHECKOUT,
											SHIPMENT_SUMMARY,
											HEADING,
											LABEL,
											VALUE,
											'from'
										])}
										variant="body1"
										sx={{
											width: '50%',
											flexShrink: 0
										}}
									>
										{shipment.from_address.address_from_name},
										{shipment.from_address.address_from_street_1},
										{shipment.from_address.address_from_street_2},
										{shipment.from_address.address_from_city_locality},{' '}
										{shipment.from_address.address_from_state_province},{' '}
										{shipment.from_address.address_from_zip_postal},{' '}
										{shipment.from_address.address_from_country},{' '}
										<Link
											id={createElementId([
												CREATE_SHIPMENTS,
												CHECKOUT,
												SHIPMENT_SUMMARY,
												HEADING,
												LABEL,
												VALUE,
												LINK,
												'from'
											])}
											href={'mailto:' + shipment.from_address.address_from_email}
											color="inherit"
											underline="always"
										>
											{shipment.from_address.address_from_email},
										</Link>
										{shipment.from_address.address_from_phone}
									</Typography>
								</AccordionSummary>
								<AccordionDetails></AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Accordion expanded={true}>
								<AccordionSummary>
									<Typography
										id={createElementId([
											CREATE_SHIPMENTS,
											CHECKOUT,
											SHIPMENT_SUMMARY,
											HEADING,
											LABEL,
											KEY,
											'parcel'
										])}
										variant="h6"
										sx={{
											fontWeight: 'bold',
											width: '30%',
											flexShrink: 0
										}}
									>
										Parcel:
									</Typography>
									<Typography
										id={createElementId([
											CREATE_SHIPMENTS,
											CHECKOUT,
											SHIPMENT_SUMMARY,
											HEADING,
											LABEL,
											VALUE,
											'parcel'
										])}
										variant="body1"
									>
										{shipment.parcel_details.parcel_length_cm}
										{'x'}
										{shipment.parcel_details.parcel_height_cm}
										{'x'}
										{shipment.parcel_details.parcel_width_cm}
										{'cm'}, Actual Weight {shipment.parcel_details.parcel_weight_kg}
										{'kg'}, Volumetric Weight {shipmentVol}
										{'kg'}
									</Typography>
								</AccordionSummary>
								<AccordionDetails></AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Accordion expanded={true}>
								<AccordionSummary>
									<Typography
										id={createElementId([
											CREATE_SHIPMENTS,
											CHECKOUT,
											SHIPMENT_SUMMARY,
											HEADING,
											LABEL,
											KEY,
											'service'
										])}
										variant="h6"
										sx={{
											fontWeight: 'bold',
											width: '30%',
											flexShrink: 0
										}}
									>
										Service:
									</Typography>
									<Typography
										id={createElementId([
											CREATE_SHIPMENTS,
											CHECKOUT,
											SHIPMENT_SUMMARY,
											HEADING,
											LABEL,
											VALUE,
											'service'
										])}
										variant="body1"
									>
										{shipment.service === 'FEDEX_INTERNATIONAL_PRIORITY'
											? 'Fedex International Priority'
											: shipment.service === 'UPSSaver'
											? 'UPS Saver'
											: shipment.service === 'Ground'
											? 'TUNL Economy'
											: ''}
									</Typography>
								</AccordionSummary>
								<AccordionDetails></AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Accordion expanded={true} sx={{ width: '100%' }}>
								<AccordionSummary sx={{ width: '100%' }}>
									<TableContainer
										component={Paper}
										sx={{
											xs: '30%',
											sm: '50%',
											md: '60%',
											lg: '70%',
											mr: isMd ? '0%' : isSm ? '10%' : isXs ? '10%' : '0%',
											ml: isMd ? '0%' : isSm ? '15%' : isXs ? '29%' : '0%',
											width: isMd ? '100%' : isSm ? '75%' : isXs ? '42%' : '100%'
										}}
									>
										<Table
											id={createElementId([
												CREATE_SHIPMENTS,
												CHECKOUT,
												SHIPMENT_SUMMARY,
												TABLE,
												CUSTOMS_INFO
											])}
											size="small"
											sx={{ width: '100%' }}
										>
											<Grid
												container
												direction={'row'}
												justifyContent={'flex-start'}
												alignItems={'center'}
											>
												<Grid item ml={5}>
													<TableHead
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															SHIPMENT_SUMMARY,
															TABLE,
															TABLE_HEAD,
															TABLE_ROW,
															TABLE_HEAD,
															CUSTOMS_INFO
														])}
													>
														<Typography
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																SHIPMENT_SUMMARY,
																TABLE,
																TABLE_HEAD,
																TABLE_ROW,
																TABLE_HEAD,
																LABEL,
																HEADING,
																CUSTOMS_INFO
															])}
															variant="h6"
															fontWeight={'bold'}
														>
															Customs Info:
														</Typography>
													</TableHead>
												</Grid>
											</Grid>

											<TableBody>
												<TableRow
													id={createElementId([
														CREATE_SHIPMENTS,
														CHECKOUT,
														SHIPMENT_SUMMARY,
														TABLE,
														TABLE_ROW
													])}
												>
													<TableCell
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															SHIPMENT_SUMMARY,
															TABLE,
															TABLE_BODY,
															TABLE_ROW,
															TABLE_CELL,
															'item_no'
														])}
													>
														<Typography
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																SHIPMENT_SUMMARY,
																TABLE,
																TABLE_BODY,
																TABLE_ROW,
																TABLE_CELL,
																LABEL,
																KEY,
																'item_no'
															])}
															fontWeight={'bold'}
														>
															Item No.
														</Typography>
													</TableCell>
													<TableCell
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															SHIPMENT_SUMMARY,
															TABLE,
															TABLE_BODY,
															TABLE_ROW,
															TABLE_CELL,
															KEY,
															'item_name'
														])}
													>
														<Typography
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																SHIPMENT_SUMMARY,
																TABLE,
																TABLE_BODY,
																TABLE_ROW,
																TABLE_CELL,
																LABEL,
																KEY,
																'item_name'
															])}
															fontWeight={'bold'}
														>
															Item Name
														</Typography>
													</TableCell>
													<TableCell
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															SHIPMENT_SUMMARY,
															TABLE,
															TABLE_BODY,
															TABLE_ROW,
															TABLE_CELL,
															KEY,
															'hs_code'
														])}
													>
														<Typography
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																SHIPMENT_SUMMARY,
																TABLE,
																TABLE_BODY,
																TABLE_ROW,
																TABLE_CELL,
																LABEL,
																KEY,
																'hs_code'
															])}
															fontWeight={'bold'}
														>
															HS Code
														</Typography>
													</TableCell>
													<TableCell
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															SHIPMENT_SUMMARY,
															TABLE,
															TABLE_BODY,
															TABLE_ROW,
															TABLE_CELL,
															KEY,
															'currency'
														])}
													>
														<Typography
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																SHIPMENT_SUMMARY,
																TABLE,
																TABLE_BODY,
																TABLE_ROW,
																TABLE_CELL,
																LABEL,
																KEY,
																'currency'
															])}
															fontWeight={'bold'}
														>
															Currency
														</Typography>
													</TableCell>
													<TableCell
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															SHIPMENT_SUMMARY,
															TABLE,
															TABLE_BODY,
															TABLE_ROW,
															TABLE_CELL,
															KEY,
															'country'
														])}
													>
														<Typography
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																SHIPMENT_SUMMARY,
																TABLE,
																TABLE_BODY,
																TABLE_ROW,
																TABLE_CELL,
																LABEL,
																KEY,
																'country'
															])}
															fontWeight={'bold'}
														>
															Country
														</Typography>
													</TableCell>
													<TableCell
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															SHIPMENT_SUMMARY,
															TABLE,
															TABLE_BODY,
															TABLE_ROW,
															TABLE_CELL,
															KEY,
															'value'
														])}
													>
														<Typography
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																SHIPMENT_SUMMARY,
																TABLE,
																TABLE_BODY,
																TABLE_ROW,
																TABLE_CELL,
																LABEL,
																KEY,
																'value'
															])}
															fontWeight={'bold'}
														>
															Value
														</Typography>
													</TableCell>
													<TableCell
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															SHIPMENT_SUMMARY,
															TABLE,
															TABLE_BODY,
															TABLE_ROW,
															TABLE_CELL,
															KEY,
															'quantity'
														])}
														align="center"
													>
														<Typography fontWeight={'bold'}>Quantity</Typography>
													</TableCell>
													<TableCell
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															SHIPMENT_SUMMARY,
															TABLE,
															TABLE_BODY,
															TABLE_ROW,
															TABLE_CELL,
															KEY,
															'weight'
														])}
													>
														<Typography
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																SHIPMENT_SUMMARY,
																TABLE,
																TABLE_BODY,
																TABLE_ROW,
																TABLE_CELL,
																LABEL,
																KEY,
																'weight'
															])}
															fontWeight={'bold'}
														>
															Weight
														</Typography>
													</TableCell>
												</TableRow>
												{shipment.customs_info.customs_items.map(
													(item: CustomsItem, index: number) => (
														<TableRow
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																SHIPMENT_SUMMARY,
																TABLE,
																TABLE_BODY,
																TABLE_ROW,
																CUSTOMS_INFO,
																`${index + 1}`
															])}
														>
															<TableCell
																id={createElementId([
																	CREATE_SHIPMENTS,
																	CHECKOUT,
																	SHIPMENT_SUMMARY,
																	TABLE,
																	TABLE_BODY,
																	TABLE_ROW,
																	TABLE_CELL,
																	CUSTOMS_INFO,
																	`${index + 1}`,
																	VALUE,
																	'currency'
																])}
															>
																<Typography
																	id={createElementId([
																		CREATE_SHIPMENTS,
																		CHECKOUT,
																		SHIPMENT_SUMMARY,
																		TABLE,
																		TABLE_BODY,
																		TABLE_ROW,
																		TABLE_CELL,
																		CUSTOMS_INFO,
																		`${index + 1}`,
																		LABEL,
																		VALUE,
																		'currency'
																	])}
																	variant="body1"
																>
																	{`${index + 1}.`}
																</Typography>
															</TableCell>
															<TableCell
																id={createElementId([
																	CREATE_SHIPMENTS,
																	CHECKOUT,
																	SHIPMENT_SUMMARY,
																	TABLE,
																	TABLE_BODY,
																	TABLE_ROW,
																	TABLE_CELL,
																	CUSTOMS_INFO,
																	`${index + 1}`,
																	VALUE,
																	'description'
																])}
															>
																<Typography
																	id={createElementId([
																		CREATE_SHIPMENTS,
																		CHECKOUT,
																		SHIPMENT_SUMMARY,
																		TABLE,
																		TABLE_BODY,
																		TABLE_ROW,
																		TABLE_CELL,
																		CUSTOMS_INFO,
																		`${index + 1}`,
																		LABEL,
																		VALUE,
																		'description'
																	])}
																	variant="body1"
																>
																	{item.description}
																</Typography>
															</TableCell>
															<TableCell
																id={createElementId([
																	CREATE_SHIPMENTS,
																	CHECKOUT,
																	SHIPMENT_SUMMARY,
																	TABLE,
																	TABLE_BODY,
																	TABLE_ROW,
																	TABLE_CELL,
																	CUSTOMS_INFO,
																	`${index + 1}`,
																	VALUE,
																	'hs_tariff_number'
																])}
															>
																<Typography
																	id={createElementId([
																		CREATE_SHIPMENTS,
																		CHECKOUT,
																		SHIPMENT_SUMMARY,
																		TABLE,
																		TABLE_BODY,
																		TABLE_ROW,
																		TABLE_CELL,
																		CUSTOMS_INFO,
																		`${index + 1}`,
																		LABEL,
																		VALUE,
																		'hs_tariff_number'
																	])}
																	variant="body1"
																	align="left"
																>
																	{item.hs_tariff_number}
																</Typography>
															</TableCell>
															<TableCell
																id={createElementId([
																	CREATE_SHIPMENTS,
																	CHECKOUT,
																	SHIPMENT_SUMMARY,
																	TABLE,
																	TABLE_BODY,
																	TABLE_ROW,
																	TABLE_CELL,
																	CUSTOMS_INFO,
																	`${index + 1}`,
																	VALUE,
																	'currency'
																])}
															>
																<Typography
																	id={createElementId([
																		CREATE_SHIPMENTS,
																		CHECKOUT,
																		SHIPMENT_SUMMARY,
																		TABLE,
																		TABLE_BODY,
																		TABLE_ROW,
																		TABLE_CELL,
																		CUSTOMS_INFO,
																		`${index + 1}`,
																		VALUE,
																		'currency'
																	])}
																	variant="body1"
																>
																	{item.currency}
																</Typography>
															</TableCell>
															<TableCell
																id={createElementId([
																	CREATE_SHIPMENTS,
																	CHECKOUT,
																	SHIPMENT_SUMMARY,
																	TABLE,
																	TABLE_BODY,
																	TABLE_ROW,
																	TABLE_CELL,
																	CUSTOMS_INFO,
																	`${index + 1}`,
																	VALUE,
																	'origin_country'
																])}
															>
																<Typography
																	id={createElementId([
																		CREATE_SHIPMENTS,
																		CHECKOUT,
																		SHIPMENT_SUMMARY,
																		TABLE,
																		TABLE_BODY,
																		TABLE_ROW,
																		TABLE_CELL,
																		CUSTOMS_INFO,
																		`${index + 1}`,
																		VALUE,
																		'origin_country'
																	])}
																	variant="body1"
																>
																	{item.origin_country}
																</Typography>
															</TableCell>
															<TableCell
																id={createElementId([
																	CREATE_SHIPMENTS,
																	CHECKOUT,
																	SHIPMENT_SUMMARY,
																	TABLE,
																	TABLE_BODY,
																	TABLE_ROW,
																	TABLE_CELL,
																	CUSTOMS_INFO,
																	`${index + 1}`,
																	VALUE,
																	'value'
																])}
															>
																<Typography
																	id={createElementId([
																		CREATE_SHIPMENTS,
																		CHECKOUT,
																		SHIPMENT_SUMMARY,
																		TABLE,
																		TABLE_BODY,
																		TABLE_ROW,
																		TABLE_CELL,
																		CUSTOMS_INFO,
																		`${index + 1}`,
																		VALUE,
																		'value'
																	])}
																	variant="body1"
																>
																	{item.currency === 'ZAR'
																		? 'R '
																		: item.currency === 'GBP'
																		? '£ '
																		: item.currency === 'EUR'
																		? '€ '
																		: item.currency === 'USD'
																		? '$ '
																		: item.currency === 'CAD'
																		? 'C$ '
																		: item.currency === 'AUD'
																		? 'A$ '
																		: ''}
																	{item.value.toFixed(2)}
																</Typography>
															</TableCell>
															<TableCell
																id={createElementId([
																	CREATE_SHIPMENTS,
																	CHECKOUT,
																	SHIPMENT_SUMMARY,
																	TABLE,
																	TABLE_BODY,
																	TABLE_ROW,
																	TABLE_CELL,
																	CUSTOMS_INFO,
																	`${index + 1}`,
																	VALUE,
																	'quantity'
																])}
																align="center"
															>
																<Typography
																	id={createElementId([
																		CREATE_SHIPMENTS,
																		CHECKOUT,
																		SHIPMENT_SUMMARY,
																		TABLE,
																		TABLE_BODY,
																		TABLE_ROW,
																		TABLE_CELL,
																		CUSTOMS_INFO,
																		`${index + 1}`,
																		VALUE,
																		'quantity'
																	])}
																	variant="body1"
																>
																	{item.quantity}
																</Typography>
															</TableCell>
															<TableCell
																id={createElementId([
																	CREATE_SHIPMENTS,
																	CHECKOUT,
																	SHIPMENT_SUMMARY,
																	TABLE,
																	TABLE_BODY,
																	TABLE_ROW,
																	TABLE_CELL,
																	CUSTOMS_INFO,
																	`${index + 1}`,
																	VALUE,
																	'weight'
																])}
															>
																<Typography
																	id={createElementId([
																		CREATE_SHIPMENTS,
																		CHECKOUT,
																		SHIPMENT_SUMMARY,
																		TABLE,
																		TABLE_BODY,
																		TABLE_ROW,
																		TABLE_CELL,
																		CUSTOMS_INFO,
																		`${index + 1}`,
																		VALUE,
																		'weight'
																	])}
																	variant="body1"
																>
																	{item.weight.toFixed(2)}
																	{' Kg'}
																</Typography>
															</TableCell>
														</TableRow>
													)
												)}
												<TableRow>
													<TableCell></TableCell>
													<TableCell></TableCell>
													<TableCell></TableCell>
													<TableCell></TableCell>
													<TableCell
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															SHIPMENT_SUMMARY,
															TABLE,
															TABLE_BODY,
															TABLE_ROW,
															TABLE_CELL,
															CUSTOMS_INFO,
															KEY,
															'total'
														])}
													>
														<Typography
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																SHIPMENT_SUMMARY,
																TABLE,
																TABLE_BODY,
																TABLE_ROW,
																TABLE_CELL,
																CUSTOMS_INFO,
																LABEL,
																KEY,
																'total'
															])}
															fontWeight={'bold'}
														>
															Total
														</Typography>
													</TableCell>
													<TableCell
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															SHIPMENT_SUMMARY,
															TABLE,
															TABLE_BODY,
															TABLE_ROW,
															TABLE_CELL,
															CUSTOMS_INFO,
															KEY,
															'total_value'
														])}
													>
														<Typography
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																SHIPMENT_SUMMARY,
																TABLE,
																TABLE_BODY,
																TABLE_ROW,
																TABLE_CELL,
																CUSTOMS_INFO,
																LABEL,
																VALUE,
																'total_value'
															])}
															fontWeight={'bold'}
														>
															{shipment.customs_info.customs_items.find(
																items => items.currency
															)?.currency === 'ZAR'
																? 'R '
																: shipment.customs_info.customs_items.find(
																		items => items.currency
																  )?.currency === 'GBP'
																? '£ '
																: shipment.customs_info.customs_items.find(
																		items => items.currency
																  )?.currency === 'EUR'
																? '€ '
																: shipment.customs_info.customs_items.find(
																		items => items.currency
																  )?.currency === 'USD'
																? '$'
																: shipment.customs_info.customs_items.find(
																		items => items.currency
																  )?.currency === 'CAD'
																? 'C$ '
																: shipment.customs_info.customs_items.find(
																		items => items.currency
																  )?.currency === 'AUD'
																? 'A$ '
																: ''}
															{shipment.customs_info.customs_items
																.reduce((a: number, b: CustomsItem) => a + b.value, 0)
																.toFixed(2)}
														</Typography>
													</TableCell>
													<TableCell
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															SHIPMENT_SUMMARY,
															TABLE,
															TABLE_BODY,
															TABLE_ROW,
															TABLE_CELL,
															CUSTOMS_INFO,
															KEY,
															'total_quantity'
														])}
														align="center"
													>
														<Typography
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																SHIPMENT_SUMMARY,
																TABLE,
																TABLE_BODY,
																TABLE_ROW,
																TABLE_CELL,
																CUSTOMS_INFO,
																LABEL,
																VALUE,
																'total_value'
															])}
															fontWeight={'bold'}
														>
															{shipment.customs_info.customs_items.reduce(
																(a: number, b: CustomsItem) => a + b.quantity,
																0
															)}
														</Typography>
													</TableCell>
													<TableCell
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															SHIPMENT_SUMMARY,
															TABLE,
															TABLE_BODY,
															TABLE_ROW,
															TABLE_CELL,
															CUSTOMS_INFO,
															KEY,
															'total_weight'
														])}
													>
														<Typography
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																SHIPMENT_SUMMARY,
																TABLE,
																TABLE_BODY,
																TABLE_ROW,
																TABLE_CELL,
																CUSTOMS_INFO,
																LABEL,
																VALUE,
																'total_weight'
															])}
															fontWeight={'bold'}
														>
															{shipment.customs_info.customs_items
																.reduce((a: number, b: CustomsItem) => a + b.weight, 0)
																.toFixed(2)}
															{' Kg'}
														</Typography>
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</AccordionSummary>
								<AccordionDetails></AccordionDetails>
							</Accordion>
						</Grid>
					</Grid>
				</Grid>
				{shipment.shipment_type === 'economy' ? (
					<Grid item justifyContent={'flex-start'} alignContent={'flex-start'} textAlign={'center'} mb={5}>
						<Paper
							id={createElementId([CREATE_SHIPMENTS, CHECKOUT, PAPER, ECONOMY, PARCEL_COLLECTION])}
							elevation={4}
							square={false}
						>
							<Box p={4} mt={5}>
								<Typography
									id={createElementId([
										CREATE_SHIPMENTS,
										CHECKOUT,
										PAPER,
										ECONOMY,
										PARCEL_COLLECTION,
										LABEL,
										KEY
									])}
									variant="h5"
									ml={4}
									mr={4}
								>
									Drop-Off
								</Typography>
								<Typography
									id={createElementId([
										CREATE_SHIPMENTS,
										CHECKOUT,
										PAPER,
										ECONOMY,
										PARCEL_COLLECTION,
										LABEL,
										VALUE
									])}
									variant="body1"
									padding={2}
									textAlign={'center'}
								>
									Please note that TUNL Economy is a drop-off only service. Please send your parcel to
									TUNL, UNIT 52, 22 Cumberland Road, Paarden Eiland, Cape Town, 7405
								</Typography>
							</Box>
						</Paper>
					</Grid>
				) : (
					<>
						<Paper
							id={createElementId([CREATE_SHIPMENTS, CHECKOUT, PAPER, EXPRESS, PARCEL_COLLECTION])}
							elevation={4}
							square={false}
						>
							<Box p={4} mt={5}>
								<Grid item textAlign={'center'}>
									<Typography variant="h5" mb={5} ml={4} mr={4}>
										<ParcelCollectionDatePopover getValues={getValues} />
									</Typography>
									<Grid container spacing={1}>
										<Grid item xs={10} sm={10} md={5} lg={4} xl={4}>
											<FormControl
												id={createElementId([
													CREATE_SHIPMENTS,
													CHECKOUT,
													PAPER,
													EXPRESS,
													PARCEL_COLLECTION,
													FORM_CONTROL,
													PICK_UP_OPTION
												])}
												variant={'standard'}
												fullWidth
											>
												<Select
													id={createElementId([
														CREATE_SHIPMENTS,
														CHECKOUT,
														PAPER,
														EXPRESS,
														PARCEL_COLLECTION,
														FORM_CONTROL,
														SELECT,
														PICK_UP_OPTION
													])}
													value={pickup}
													onChange={handlePickupChange}
													input={<BootstrapInput error={false} />}
													fullWidth
												>
													<MenuItem
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															PAPER,
															EXPRESS,
															PARCEL_COLLECTION,
															FORM_CONTROL,
															SELECT,
															PICK_UP_OPTION,
															MENU_ITEM,
															'yes'
														])}
														value={1}
													>
														Yes
													</MenuItem>
													<MenuItem
														value={7}
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															PAPER,
															EXPRESS,
															PARCEL_COLLECTION,
															FORM_CONTROL,
															SELECT,
															PICK_UP_OPTION,
															MENU_ITEM,
															'no_tunl_pick_up'
														])}
													>
														No: I am sending my parcel to TUNL for pick up
													</MenuItem>
													<MenuItem
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															PAPER,
															EXPRESS,
															PARCEL_COLLECTION,
															FORM_CONTROL,
															SELECT,
															PICK_UP_OPTION,
															MENU_ITEM,
															'no_my_loc_pick_up'
														])}
														value={0}
													>
														No: I already have a pick up scheduled at my location
													</MenuItem>
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={10} sm={10} md={5} lg={8} xl={8}>
											<FormControl
												id={createElementId([
													CREATE_SHIPMENTS,
													CHECKOUT,
													PAPER,
													EXPRESS,
													PARCEL_COLLECTION,
													FORM_CONTROL,
													PICK_UP_TIME_SLOT
												])}
												variant={'standard'}
												error
												fullWidth
											>
												<Select
													id={createElementId([
														CREATE_SHIPMENTS,
														CHECKOUT,
														PAPER,
														EXPRESS,
														PARCEL_COLLECTION,
														FORM_CONTROL,
														SELECT,
														PICK_UP_TIME_SLOT
													])}
													disabled={pickup === 0 || pickup === 7 ? true : false}
													value={selectedSlot}
													onChange={handlePickupSlotChange}
													input={
														<BootstrapInput error={pickup === 1 && selectedSlot === 0} />
													}
													fullWidth
												>
													<MenuItem
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															PAPER,
															EXPRESS,
															PARCEL_COLLECTION,
															FORM_CONTROL,
															SELECT,
															PICK_UP_TIME_SLOT,
															MENU_ITEM,
															'default'
														])}
														disabled
														value={0}
													>
														<em>Please Select Timeslot</em>
													</MenuItem>
													{today?.morning ? (
														<MenuItem
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																PAPER,
																EXPRESS,
																PARCEL_COLLECTION,
																FORM_CONTROL,
																SELECT,
																PICK_UP_TIME_SLOT,
																MENU_ITEM,
																'today_morning'
															])}
															value={1}
														>
															Today (Morning)
														</MenuItem>
													) : (
														<></>
													)}
													{today?.afternoon ? (
														<MenuItem
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																PAPER,
																EXPRESS,
																PARCEL_COLLECTION,
																FORM_CONTROL,
																SELECT,
																PICK_UP_TIME_SLOT,
																MENU_ITEM,
																'today_afternoon'
															])}
															value={2}
														>
															Today (Afternoon)
														</MenuItem>
													) : (
														<></>
													)}
													{tomorrow?.morning ? (
														<MenuItem
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																PAPER,
																EXPRESS,
																PARCEL_COLLECTION,
																FORM_CONTROL,
																SELECT,
																PICK_UP_TIME_SLOT,
																MENU_ITEM,
																'tomorrow_morning'
															])}
															value={3}
														>
															Tomorrow (Morning)
														</MenuItem>
													) : (
														<></>
													)}
													{tomorrow?.afternoon ? (
														<MenuItem
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																PAPER,
																EXPRESS,
																PARCEL_COLLECTION,
																FORM_CONTROL,
																SELECT,
																PICK_UP_TIME_SLOT,
																MENU_ITEM,
																'tomorrow_morning'
															])}
															value={4}
														>
															Tomorrow (Afternoon)
														</MenuItem>
													) : (
														<></>
													)}
													{monday?.morning ? (
														<MenuItem
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																PAPER,
																EXPRESS,
																PARCEL_COLLECTION,
																FORM_CONTROL,
																SELECT,
																PICK_UP_TIME_SLOT,
																MENU_ITEM,
																'monday_morning'
															])}
															value={5}
														>
															Monday (Morning)
														</MenuItem>
													) : (
														<></>
													)}
													{monday?.afternoon ? (
														<MenuItem
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																PAPER,
																EXPRESS,
																PARCEL_COLLECTION,
																FORM_CONTROL,
																SELECT,
																PICK_UP_TIME_SLOT,
																MENU_ITEM,
																'monday_afternoon'
															])}
															value={6}
														>
															Monday (Afternoon)
														</MenuItem>
													) : (
														<></>
													)}
												</Select>
											</FormControl>
										</Grid>
									</Grid>{' '}
								</Grid>
								<Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
									<Grid
										container
										direction="column"
										alignContent="center"
										justifyContent="center"
										padding={2}
									></Grid>
								</Grid>
							</Box>
						</Paper>
					</>
				)}
				{shipment.shipment_type === 'express' && (
					<Grid item textAlign={'center'} xs={12} sm={12} md={12} lg={12} xl={12} mt={5} mb={5}>
						<Paper
							id={createElementId([CREATE_SHIPMENTS, CHECKOUT, PAPER, EXPRESS, DFT])}
							elevation={4}
							square={false}
						>
							<Box p={4}>
								<Typography variant="h5" mb={5} ml={4} mr={4}>
									<DutiesFeesTaxesPopover />
								</Typography>
								<Grid
									container
									direction={'column'}
									spacing={1}
									// alignContent="center"
									justifyContent="flex-end"
								>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid
											container
											direction={'row'}
											justifyItems={'flex-end'}
											alignItems={'center'}
											spacing={4}
										>
											<Grid item xs={10} sm={10} md={8} lg={8} xl={8} lineHeight={1}>
												<Grid container direction={'column'} spacing={1}>
													<Grid item xs={10} sm={10} md={12} lg={12} xl={12} lineHeight={1}>
														<Typography
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																PAPER,
																EXPRESS,
																DFT,
																LABEL,
																DFT
															])}
															variant="body1"
														>
															How do you want to pay import duties, fees and taxes at the
															destination?
														</Typography>
													</Grid>
													<Grid item xs={10} sm={10} md={12} lg={12} xl={12} lineHeight={1}>
														<FormControl
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																PAPER,
																EXPRESS,
																DFT,
																FORM_CONTROL,
																FEE_OPTION
															])}
															variant={'standard'}
															fullWidth
														>
															<Select
																id={createElementId([
																	CREATE_SHIPMENTS,
																	CHECKOUT,
																	PAPER,
																	EXPRESS,
																	DFT,
																	FORM_CONTROL,
																	FEE_OPTION,
																	SELECT
																])}
																value={merchantDDP}
																onChange={handleDutiesChange}
																input={<BootstrapInput error={false} />}
																fullWidth
															>
																<MenuItem
																	id={createElementId([
																		CREATE_SHIPMENTS,
																		CHECKOUT,
																		PAPER,
																		EXPRESS,
																		DFT,
																		FORM_CONTROL,
																		FEE_OPTION,
																		SELECT,
																		'dap'
																	])}
																	value={'false'}
																	disabled={invalidLandedCosts && !merchantDDP}
																>
																	Bill the Receiver (DAP)
																</MenuItem>
																<MenuItem
																	id={createElementId([
																		CREATE_SHIPMENTS,
																		CHECKOUT,
																		PAPER,
																		EXPRESS,
																		DFT,
																		FORM_CONTROL,
																		FEE_OPTION,
																		SELECT,
																		'ddp'
																	])}
																	value={'true'}
																	disabled={invalidLandedCosts && merchantDDP}
																>
																	Bill my account (DDP)
																</MenuItem>
															</Select>
														</FormControl>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={10} sm={10} md={4} lg={4} xl={4} lineHeight={1}>
												<Table size="small">
													{waiting ? (
														<CircularProgress color="inherit" size={90} />
													) : !landedCostsError ? (
														<TableBody>
															{landedCosts.taxes +
																landedCosts.duties +
																landedCosts.fees !==
																0 && (
																<TableRow>
																	<TableCell>
																		<Typography variant="body2">Duties</Typography>
																	</TableCell>

																	<TableCell align="right">
																		<MouseOverPopover
																			amount={`${landedCostCurrency}${landedCosts?.duties?.toFixed(
																				2
																			)}`}
																			info={landedCostsBreakdown.duties}
																			currency={landedCostCurrency}
																		/>
																	</TableCell>
																</TableRow>
															)}
															{landedCosts.taxes +
																landedCosts.duties +
																landedCosts.fees !==
																0 && (
																<TableRow>
																	<TableCell>
																		<Typography variant="body2">Fees</Typography>
																	</TableCell>
																	<TableCell align="right">
																		<MouseOverPopover
																			amount={`${landedCostCurrency}${landedCosts?.fees?.toFixed(
																				2
																			)}`}
																			info={landedCostsBreakdown.fees}
																			currency={landedCostCurrency}
																		/>
																	</TableCell>
																</TableRow>
															)}
															{landedCosts.taxes +
																landedCosts.duties +
																landedCosts.fees !==
																0 && (
																<TableRow>
																	<TableCell>
																		<Typography variant="body2">Taxes</Typography>
																	</TableCell>

																	<TableCell align="right">
																		<MouseOverPopover
																			amount={`${landedCostCurrency}${landedCosts?.taxes?.toFixed(
																				2
																			)}`}
																			info={landedCostsBreakdown.taxes}
																			currency={landedCostCurrency}
																		/>
																	</TableCell>
																</TableRow>
															)}
															{useOwnTaxId ? (
																<TableRow>
																	<TNAlerts
																		severity={'warning'}
																		title={'Notice'}
																		message={
																			'Duties and taxes are not included because a foreign tax ID is configured for your account. To change this setting, visit your merchant profile.'
																		}
																		link="https://help.tunl.to/en/article/are-you-registered-for-tax-in-a-foreign-country-gtsu5b"
																	/>
																</TableRow>
															) : (
																<TableRow>
																	<TableCell>
																		<Typography variant="body2" fontWeight={'bold'}>
																			Total
																		</Typography>
																	</TableCell>
																	<TableCell align="right">
																		<Typography variant="body2" fontWeight={'bold'}>
																			{landedCosts.duties === 0 &&
																			landedCosts.fees === 0 &&
																			landedCosts.taxes === 0 ? (
																				<DeMinimisPopover
																					amount={`${landedCostCurrency}${(
																						Number(landedCosts.duties) +
																						Number(landedCosts.fees) +
																						Number(landedCosts.taxes)
																					)?.toFixed(2)}`}
																					note={deMinimis}
																				/>
																			) : (
																				<Typography
																					variant="body2"
																					fontWeight={'bold'}
																					marginRight={'35px'}
																				>
																					{`${landedCostCurrency}${(
																						Number(landedCosts.duties) +
																						Number(landedCosts.fees) +
																						Number(landedCosts.taxes)
																					)?.toFixed(2)}`}
																				</Typography>
																			)}
																		</Typography>
																	</TableCell>
																</TableRow>
															)}
														</TableBody>
													) : (
														<TNAlerts
															severity={'error'}
															title={'Landed Costs Calculation Failed!'}
															message={landedCostsErrorMessage}
														/>
													)}
												</Table>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Box>
						</Paper>
					</Grid>
				)}

				<Grid item textAlign={'center'} xs={12} sm={12} md={12} lg={12} xl={12} mt={5} mb={5}>
					<Paper elevation={4} square={false}>
						<Box p={4}>
							<Typography variant="h5" mb={5} ml={4} mr={4}>
								<DeliveryConfirmationPopover />
							</Typography>
							<Grid
								container
								direction={'column'}
								spacing={1}
								// alignContent="center"
								justifyContent="flex-end"
							>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Grid
										container
										direction={'row'}
										justifyItems={'flex-end'}
										alignItems={'center'}
										spacing={4}
									>
										<Grid item xs={10} sm={10} md={8} lg={8} xl={8} lineHeight={1}>
											<Grid container direction={'column'} spacing={1}>
												<Grid item xs={10} sm={10} md={12} lg={12} xl={12} lineHeight={1}>
													<Typography variant="body1">
														Request Signature Upon Receipt
													</Typography>
												</Grid>
												<Grid item xs={10} sm={10} md={12} lg={12} xl={12} lineHeight={1}>
													<FormControl
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															PAPER,
															EXPRESS,
															SIGNATURE_REQUIRED,
															FORM_CONTROL
														])}
														variant={'standard'}
														fullWidth
													>
														<Select
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																PAPER,
																EXPRESS,
																SIGNATURE_REQUIRED,
																FORM_CONTROL,
																SELECT
															])}
															value={shipmentDeliveryConfirmation}
															onChange={handleShipmentDeliveryConfirmationChange}
															input={<BootstrapInput error={false} />}
															fullWidth
														>
															<MenuItem
																id={createElementId([
																	CREATE_SHIPMENTS,
																	CHECKOUT,
																	PAPER,
																	EXPRESS,
																	SIGNATURE_REQUIRED,
																	FORM_CONTROL,
																	SELECT,
																	'adult_signature'
																])}
																value={'true'}
															>
																Adult Signature
															</MenuItem>
															<MenuItem
																id={createElementId([
																	CREATE_SHIPMENTS,
																	CHECKOUT,
																	PAPER,
																	EXPRESS,
																	SIGNATURE_REQUIRED,
																	FORM_CONTROL,
																	SELECT,
																	'no_signature'
																])}
																value={'false'}
															>
																No Signature
															</MenuItem>
														</Select>
													</FormControl>
												</Grid>
											</Grid>
										</Grid>

										<Grid item xs={10} sm={10} md={4} lg={4} xl={4} lineHeight={1}>
											<Table size="small">
												<TableBody>
													<TableRow>
														<TableCell>
															<Typography variant="body2" fontWeight={'bold'}>
																Total
															</Typography>
														</TableCell>
														<TableCell align="right">
															<Typography
																variant="body2"
																fontWeight={'bold'}
																marginRight={'35px'}
															>
																{`R${deliveryConfirmationCost.toFixed(2)}`}
															</Typography>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item>
					<Grid container direction="row" spacing={2}>
						<Grid item xs={12} sm={12} md={8} lg={8} xl={8} textAlign={'center'}>
							{' '}
							<Paper
								id={createElementId([CREATE_SHIPMENTS, CHECKOUT, PAPER, SHIPMENT_PROTECTION])}
								elevation={4}
								square={false}
							>
								<Box p={4} sx={{ minHeight: '300px' }}>
									<Typography variant="h5" mb={5} ml={4} mr={4}>
										<ShipmentProtectionPopover />
									</Typography>
									<Grid container spacing={7} alignContent="center" justifyContent="center">
										<Grid item xs={10} sm={10} md={6} lg={6} xl={6} lineHeight={1}>
											<Grid
												container
												direction="column"
												alignContent="center"
												justifyContent="center"
											>
												<Tooltip
													title="*Protection is calculated at 2.5% of the protection value, minimum
													charge is R50.00"
												>
													<FormControlLabel
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															PAPER,
															SHIPMENT_PROTECTION,
															FORM_CONTROL_LABEL
														])}
														control={
															<Checkbox
																id={createElementId([
																	CREATE_SHIPMENTS,
																	CHECKOUT,
																	PAPER,
																	SHIPMENT_PROTECTION,
																	FORM_CONTROL_LABEL,
																	CHECKBOX,
																	'add_protection'
																])}
																checked={addInsurance}
																onChange={
																	((event: any) => {
																		setAddInsurance(event?.target?.checked);
																		setAddedInsurance(event?.target?.checked);
																		setInsuranceValue('');
																		setValidInsurance(!event?.target?.checked);
																	}) || handleClick
																}
															/>
														}
														label="Add Shipment Protection"
													/>
												</Tooltip>
											</Grid>
										</Grid>
										<Grid item xs={10} sm={10} md={6} lg={6} xl={6} lineHeight={1.5}>
											<Grid
												container
												direction="column"
												alignContent="center"
												justifyContent="center"
											>
												<Tooltip
													title="*Calculated based on ZAR customs items only. Click above to edit the
													value of your goods you wish to protect"
												>
													<TextField
														id={createElementId([
															CREATE_SHIPMENTS,
															CHECKOUT,
															PAPER,
															SHIPMENT_PROTECTION,
															TEXTFIELD,
															'protection_value'
														])}
														type="tel"
														required
														error={validateInsuredValue(insuranceValue)}
														label="Protection Value (R)"
														disabled={!addInsurance}
														helperText={
															validateInsuredValue(insuranceValue)
																? 'Value must be greater than 0'
																: null
														}
														value={insuranceValue}
														inputProps={{ 'data-hj-allow': true }}
														onChange={(e: any) => {
															setInsuranceValue(e.target.value);
															setValidInsurance(!validateInsuredValue(e.target.value));
															props.setValue('insuranceValue', e.target.value);
														}}
													/>
												</Tooltip>
											</Grid>
										</Grid>
									</Grid>
								</Box>
							</Paper>
						</Grid>

						<Grid item xs={12} sm={12} md={4} lg={4} xl={4} textAlign={'center'}>
							{' '}
							<Paper
								id={createElementId([CREATE_SHIPMENTS, CHECKOUT, PAPER, SHIPMENT_COST])}
								elevation={4}
								square={false}
							>
								<Box p={4} sx={{ minHeight: '300px' }}>
									<Typography
										id={createElementId([
											CREATE_SHIPMENTS,
											CHECKOUT,
											PAPER,
											SHIPMENT_COST,
											LABEL,
											HEADING
										])}
										variant="h5"
										mb={5}
										alignSelf={'center'}
									>
										Shipment Costs
									</Typography>
									<Grid container textAlign={'center'}>
										<Grid
											item
											xs={10}
											sm={10}
											md={12}
											lg={12}
											xl={12}
											lineHeight={2}
											alignSelf={'center'}
										>
											{waiting ? (
												<CircularProgress color="inherit" size={90} />
											) : (
												<Table size="small">
													<TableBody>
														<TableRow
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																PAPER,
																SHIPMENT_COST,
																TABLE,
																TABLE_BODY,
																TABLE_ROW,
																SHIPPING
															])}
														>
															<TableCell>
																<Typography
																	id={createElementId([
																		CREATE_SHIPMENTS,
																		CHECKOUT,
																		PAPER,
																		SHIPMENT_COST,
																		TABLE,
																		TABLE_BODY,
																		TABLE_ROW,
																		SHIPPING,
																		TABLE_CELL,
																		LABEL,
																		KEY,
																		SHIPPING
																	])}
																	variant="body2"
																>
																	{text.SHIPPING_COST}
																</Typography>
															</TableCell>
															<TableCell align="right">
																<Typography
																	id={createElementId([
																		CREATE_SHIPMENTS,
																		CHECKOUT,
																		PAPER,
																		SHIPMENT_COST,
																		TABLE,
																		TABLE_BODY,
																		TABLE_ROW,
																		SHIPPING,
																		TABLE_CELL,
																		LABEL,
																		VALUE,
																		SHIPPING
																	])}
																	variant="body2"
																>{`R${shippingRate}`}</Typography>
															</TableCell>
														</TableRow>
														{calculateInsuredValue() !== 0 && (
															<TableRow
																id={createElementId([
																	CREATE_SHIPMENTS,
																	CHECKOUT,
																	PAPER,
																	SHIPMENT_COST,
																	TABLE,
																	TABLE_BODY,
																	TABLE_ROW,
																	'protection'
																])}
															>
																<TableCell>
																	<Typography
																		id={createElementId([
																			CREATE_SHIPMENTS,
																			CHECKOUT,
																			PAPER,
																			SHIPMENT_COST,
																			TABLE,
																			TABLE_BODY,
																			TABLE_ROW,
																			SHIPPING,
																			TABLE_CELL,
																			LABEL,
																			KEY,
																			'protection'
																		])}
																		variant="body2"
																	>
																		{text.SHIPPING_PROTECTION}
																	</Typography>
																</TableCell>
																<TableCell align="right">
																	<Typography
																		id={createElementId([
																			CREATE_SHIPMENTS,
																			CHECKOUT,
																			PAPER,
																			SHIPMENT_COST,
																			TABLE,
																			TABLE_BODY,
																			TABLE_ROW,
																			SHIPPING,
																			TABLE_CELL,
																			LABEL,
																			VALUE,
																			'protection'
																		])}
																		variant="body2"
																	>{`R${calculateInsuredValue().toFixed(
																		2
																	)}`}</Typography>
																</TableCell>
															</TableRow>
														)}

														{landedCostsTotal !== 0 &&
															landedCostsTotal !== undefined &&
															landedCostsTotal !== null && (
																<TableRow
																	id={createElementId([
																		CREATE_SHIPMENTS,
																		CHECKOUT,
																		PAPER,
																		SHIPMENT_COST,
																		TABLE,
																		TABLE_BODY,
																		TABLE_ROW,
																		DFT
																	])}
																>
																	<TableCell>
																		<Typography
																			id={createElementId([
																				CREATE_SHIPMENTS,
																				CHECKOUT,
																				PAPER,
																				SHIPMENT_COST,
																				TABLE,
																				TABLE_BODY,
																				TABLE_ROW,
																				SHIPPING,
																				TABLE_CELL,
																				LABEL,
																				KEY,
																				DFT
																			])}
																			variant="body2"
																		>
																			{text.DFT}
																		</Typography>
																	</TableCell>
																	<TableCell align="right">
																		<Typography
																			id={createElementId([
																				CREATE_SHIPMENTS,
																				CHECKOUT,
																				PAPER,
																				SHIPMENT_COST,
																				TABLE,
																				TABLE_BODY,
																				TABLE_ROW,
																				SHIPPING,
																				TABLE_CELL,
																				LABEL,
																				VALUE,
																				DFT
																			])}
																			variant="body2"
																		>{`R${landedCostsTotal?.toFixed(
																			2
																		)}`}</Typography>
																	</TableCell>
																</TableRow>
															)}
														{deliveryConfirmationCost !== 0 && (
															<TableRow>
																<TableCell>
																	<Typography variant="body2">
																		{text.SIGNATURE_REQUIRED}
																	</Typography>
																</TableCell>
																<TableCell align="right">
																	<Typography variant="body2">{`R${deliveryConfirmationCost.toFixed(
																		2
																	)}`}</Typography>
																</TableCell>
															</TableRow>
														)}
														<TableRow
															id={createElementId([
																CREATE_SHIPMENTS,
																CHECKOUT,
																PAPER,
																SHIPMENT_COST,
																TABLE,
																TABLE_BODY,
																TABLE_ROW,
																'total_cost'
															])}
														>
															<TableCell>
																<Typography
																	id={createElementId([
																		CREATE_SHIPMENTS,
																		CHECKOUT,
																		PAPER,
																		SHIPMENT_COST,
																		TABLE,
																		TABLE_BODY,
																		TABLE_ROW,
																		SHIPPING,
																		TABLE_CELL,
																		LABEL,
																		KEY,
																		'total_cost'
																	])}
																	variant="body1"
																	fontWeight={'bold'}
																	color={'#0e8212'}
																>
																	Total Cost
																</Typography>
															</TableCell>
															<TableCell align="right">
																<Typography
																	id={createElementId([
																		CREATE_SHIPMENTS,
																		CHECKOUT,
																		PAPER,
																		SHIPMENT_COST,
																		TABLE,
																		TABLE_BODY,
																		TABLE_ROW,
																		SHIPPING,
																		TABLE_CELL,
																		LABEL,
																		VALUE,
																		'total_cost'
																	])}
																	variant="body1"
																	fontWeight={'bold'}
																	color={'#0e8212'}
																>{`R${(landedCostsTotal
																	? Number(shippingRate) +
																	  landedCostsTotal +
																	  calculateInsuredValue() +
																	  deliveryConfirmationCost
																	: Number(shippingRate) +
																	  calculateInsuredValue() +
																	  deliveryConfirmationCost
																).toFixed(2)}`}</Typography>
															</TableCell>
														</TableRow>
													</TableBody>
												</Table>
											)}
										</Grid>
									</Grid>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
				<Grid item textAlign={'center'} mt={5} mb={5}>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						mt={4}
						ml={2}
					>
						<FormControlLabel
							id={createElementId([CREATE_SHIPMENTS, CHECKOUT, FORM_CONTROL_LABEL, 'terms_of_service'])}
							control={
								<Checkbox
									id={createElementId([
										CREATE_SHIPMENTS,
										CHECKOUT,
										FORM_CONTROL_LABEL,
										CHECKBOX,
										'terms_of_service'
									])}
									sx={{ padding: '0' }}
									checked={consent}
									onChange={checkConsent}
								/>
							}
							sx={{ alignItems: 'flex-start' }}
							label={
								<span>
									I hereby certify that the information provided is true and correct and that the
									contents and value of this shipment are as stated above.{' '}
									<a href="https://www.tunl.to/terms" rel="noreferrer" target="_blank">
										Terms of Service
									</a>
								</span>
							}
						/>
					</Grid>
				</Grid>
				<Snackbar
					open={openSnackBar}
					autoHideDuration={2000}
					onClose={handleAlertClose}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				>
					<Alert onClose={handleAlertClose} severity={apiResponse.type} sx={{ width: '100%' }}>
						{apiResponse.message}
					</Alert>
				</Snackbar>
				<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</div>
		</>
	);
}

function MouseOverPopover(props: any) {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<div>
			<IconButton
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			>
				<Typography variant="body2" mr={1}>
					{props.amount}
				</Typography>
				<InfoOutlinedIcon />
			</IconButton>
			<Popover
				id="mouse-over-popover"
				sx={{
					pointerEvents: 'none'
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Paper>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography sx={{ fontWeight: 'bold' }}>Description</Typography>
									</TableCell>
									<TableCell>
										<Typography sx={{ fontWeight: 'bold' }}>Amount</Typography>
									</TableCell>
									<TableCell>
										<Typography sx={{ fontWeight: 'bold' }}>Formula</Typography>
									</TableCell>
									<TableCell>
										<Typography sx={{ fontWeight: 'bold' }}>Note</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{props.info?.map((item: any) => {
									return (
										<TableRow>
											<TableCell>
												<Typography>{item.description}</Typography>
											</TableCell>
											<TableCell>
												<Typography sx={{ fontWeight: 'bold' }}>{`${
													props.currency
												}${item?.amount?.toFixed(2)}`}</Typography>{' '}
											</TableCell>
											<TableCell>
												<Typography>{item?.formula}</Typography>{' '}
											</TableCell>
											<TableCell>
												<Typography>{item?.note}</Typography>{' '}
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Popover>
		</div>
	);
}

function DeMinimisPopover(props: any) {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<div>
			<IconButton
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			>
				<Typography variant="body2" mr={1} sx={{ fontWeight: 'bold', color: 'black' }}>
					{props.amount}
				</Typography>
				<InfoOutlinedIcon />
			</IconButton>
			<Popover
				id="mouse-over-popover"
				sx={{
					pointerEvents: 'none'
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Paper>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography align={'center'} sx={{ fontWeight: 'bold' }}>
											No duties, fees or taxes under country de minimis
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell>
										<Typography>{props.note}</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Popover>
		</div>
	);
}

function DeliveryConfirmationPopover(props: any) {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<div>
			<IconButton
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onClick={handlePopoverClose}
				// onMouseLeave={handlePopoverClose}
			>
				<Typography variant="h5" mr={1} sx={{ fontWeight: 'bold', color: 'black' }}>
					Signature Required
				</Typography>
				<InfoOutlinedIcon />
			</IconButton>
			<Popover
				id="mouse-over-popover"
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Paper>
					<TableContainer>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>
										<Typography align={'center'}>
											Requesting a signature upon receipt will incur an additional charge on the
											shipping cost. Please be aware of this extra fee when selecting your
											shipping preferences.{' '}
											{/* <LearnMore
												href="https://help.tunl.to/en/article/import-tariffs-will-my-customer-be-charged-any-tax-fees-or-duties-gs7n3p/"
												inline={true}
												style={{ color: '#007bc4' }}
											/> */}
										</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Popover>
		</div>
	);
}

function DutiesFeesTaxesPopover(props: any) {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<div>
			<IconButton
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onClick={handlePopoverClose}
				// onMouseLeave={handlePopoverClose}
			>
				<Typography variant="h5" mr={1} sx={{ fontWeight: 'bold', color: 'black' }}>
					Duties, Fees and Taxes
				</Typography>
				<InfoOutlinedIcon />
			</IconButton>
			<Popover
				id="mouse-over-popover"
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Paper>
					<TableContainer>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>
										<Typography align={'center'}>
											Duties, fees and tax values are estimations provided by a third-party
											service.{' '}
											<LearnMore
												href="https://help.tunl.to/en/article/import-tariffs-will-my-customer-be-charged-any-tax-fees-or-duties-gs7n3p/"
												inline={true}
												style={{ color: '#007bc4' }}
											/>
										</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Popover>
		</div>
	);
}

function ParcelCollectionDatePopover(props: any) {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const shipment: ShipmentData = props.getValues();

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const { today, tomorrow, monday } = {
		...(shipment.service === 'FEDEX_INTERNATIONAL_PRIORITY' ? pickupDateFedex() : pickupDateUPS())
	};

	return (
		<div>
			<IconButton
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			>
				<Typography variant="h5" mr={1} sx={{ fontWeight: 'bold', color: 'black' }}>
					Parcel Collection Date
				</Typography>
				<InfoOutlinedIcon />
			</IconButton>
			<Popover
				id="mouse-over-popover"
				sx={{
					pointerEvents: 'none'
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Paper>
					<TableContainer>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>
										<Typography align={'center'} sx={{ fontWeight: 'bold' }}>
											<Typography textAlign={'start'}>
												{shipment.service === 'UPSSaver'
													? 'Morning Pickups 8am - 3pm'
													: 'Morning Pickups 9am - 12pm'}
											</Typography>
											<Typography textAlign={'start'}>Afternoon Pickups 12pm - 5pm</Typography>
										</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Popover>
		</div>
	);
}

function ShipmentProtectionPopover(props: any) {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<div>
			<IconButton
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onClick={handlePopoverClose}
				// onMouseLeave={handlePopoverClose}
			>
				<Typography variant="h5" mr={1} sx={{ fontWeight: 'bold', color: 'black' }}>
					Shipment Protection
				</Typography>
				<InfoOutlinedIcon />
			</IconButton>
			<Popover
				id="mouse-over-popover"
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Paper>
					<TableContainer>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>
										<Typography align={'center'}>
											Protection is calculated at 2.5% of the protection value, minimum charge is
											R50.00.{' '}
											<LearnMore
												href="https://help.tunl.to/en/article/shipment-protection-xm4b8d/"
												inline={true}
												style={{ color: '#007bc4' }}
											/>
										</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Popover>
		</div>
	);
}
