import HomeIcon from '@mui/icons-material/Home';
import {
	Alert,
	AlertColor,
	AlertTitle,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	Link,
	MenuItem,
	Select,
	SelectChangeEvent,
	Snackbar,
	Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import FirstShipment from '../../components/Merchant/FirstShipment';
import ShipmentsTable from '../../components/Merchant/Shipment/ShipmentsTable';
import { axiosConfig, useAuthToken } from '../../constants/axios';
import { UserMerchantRecord } from '../../types/user';

interface LocationState {
	type: string;
	message: string;
}

export default function MerchantDashboard(props: any) {
	const navigate = useNavigate();
	const location = useLocation();

	const [shipments, setShipments] = useState([]);
	const [shipmentsCount, setShipmentsCount] = useState(0);
	const [loading, setLoading] = useState(true);
	const merchant = JSON.parse(sessionStorage.getItem('merchant') as string);
	const [merchants, setMerchants] = useState(merchant);
	const [userMerchants, setUserMerchants] = useState<UserMerchantRecord[]>(
		JSON.parse(sessionStorage.getItem('userMerchants') as string)
	);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [companyName, setCompanyName] = useState(merchants.merchant_name);

	const [updateOpen, setUpdateOpen] = useState(true);

	const state = location.state as LocationState | undefined;
	const token = useAuthToken();
	const axiosInstance = axiosConfig(token);

	// Check if state exists and contains the 'message' property
	const message = state && state.message ? state.message : '';
	const [apiResponse, setApiResponse] = useState<{
		type: AlertColor;
		message: string;
	}>({ type: 'success', message: '' });

	const displayExporterNotice = () => {
		return merchant.merchant_personal_id !== '' && merchant.merchant_exporters_code === '';
	};

	const setSelectedMerchant = async (userMerchants: UserMerchantRecord) => {
		sessionStorage.setItem('activeUserMerchant', JSON.stringify(userMerchants));
		try {
			setLoading(true);
			const response = await axiosInstance.post('/merchant/getMerchant', {
				merchant_id: userMerchants.merchant_id
			});
			sessionStorage.setItem('merchant', JSON.stringify(response.data.merchantRecord));
			setMerchants(response.data.merchantRecord);
		} catch (error) {
			console.log(error);
		}
	};

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnackBar(false);
	};

	const handleChange = (event: SelectChangeEvent) => {
		setCompanyName(event.target.value as string);
	};

	useEffect(() => {
		axiosInstance
			.post('/shipment/getBatchedShipmentsByMerchant', {
				merchantId: merchant.merchant_id,
				pageSize: 10,
				pageNumber: 0
			})
			.then(response => {
				setShipments(response.data.data);
				setShipmentsCount(response.data.count);
			})
			.catch(err => {
				setApiResponse({
					type: 'error',
					message: 'Error: failed to get shipments'
				});
				setOpenSnackBar(true);
				console.log(err);
			})
			.finally(() => setLoading(false)); // eslint-disable-line react-hooks/exhaustive-deps
	}, [merchants]);

	return (
		<>
			{displayExporterNotice() && (
				<Grid container direction="column" pt={5}>
					<Grid item>
						<Grid container direction="column" justifyContent="center" alignItems="center">
							<Alert severity="warning">
								<AlertTitle>
									<strong>
										It seems you are exporting using your personal ID Number, please take note of
										the following:
									</strong>
								</AlertTitle>
								<ul>
									<li>
										Please be prepared for the courier to request a copy of your ID when they
										collect your shipments.
									</li>
									<li>Exporting with an ID number may delay the export process.</li>
									<li>
										Imports and exports via ID number are limited up to an annual total combined
										threshold of R150,000.
									</li>
									<li>
										It is so much easier to do this with an exporters code (and it's a quick and
										easy application) -{' '}
										<a
											href="https://help.tunl.to/en/article/exporters-code-g9xog2/#:~:text=to%20by%20TUNL.-,Option%203,-%3A%0AThrough%20the"
											rel="noreferrer"
											target="_blank"
										>
											Apply Here
										</a>
										.
									</li>
								</ul>
							</Alert>
						</Grid>
					</Grid>
				</Grid>
			)}
			{!merchant ? (
				<Navigate
					to="/merchant"
					state={{
						snack: { type: 'warning', message: 'Please create a merchant profile before you continue' },
						createMerchant: true
					}}
				/>
			) : (
				<div
					style={{
						display: 'block',
						justifyContent: 'center',
						alignContent: 'center',
						marginBottom: '60px',
						maxWidth: 1200,
						width: 'auto',
						marginLeft: 'auto',
						marginRight: 'auto'
					}}
				>
					<Grid container alignItems="center" direction="column">
						{userMerchants.length > 0 && !loading && (
							<Grid container direction="row" sx={{ marginBottom: '20px', marginTop: '30px' }}>
								<Grid item>
									<HomeIcon sx={{ fontSize: '1.83em' }} />
								</Grid>

								<Grid item>
									<Typography variant="h5">{merchant.merchant_name}</Typography>
								</Grid>

								<Grid container justifyContent="flex-end" sx={{ marginTop: '-40px' }}>
									{userMerchants.length > 1 && !loading ? (
										<Box sx={{ minWidth: 120 }}>
											<FormControl fullWidth>
												<InputLabel id="demo-simple-select-label">Merchant</InputLabel>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={companyName}
													label="Merchant"
													onChange={handleChange}
												>
													{userMerchants.map(userMerchant => (
														<MenuItem
															key={userMerchant.merchant_name}
															onClick={() => setSelectedMerchant(userMerchant)}
															value={userMerchant.merchant_name}
														>
															{userMerchant.merchant_name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Box>
									) : (
										userMerchants && ''
									)}
								</Grid>
							</Grid>
						)}
						{shipments.length !== 0 && !loading && (
							<>
								{/* The tracker button will reside within the grid below */}
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									spacing={2}
									sx={{
										marginBottom: '20px',
										marginTop: '30px',
										minWidth: 'auto',
										maxWidth: 'auto',
										width: '100%'
									}}
								>
									<Grid item>
										<Button
											color="primary"
											variant="contained"
											sx={{ borderRadius: '30px', marginRight: '10px' }}
											onClick={() => navigate('/shipment')}
										>
											Create Shipment
										</Button>
										<Link
											target={'_blank'}
											href={'https://www.tunl.to/'}
											variant="body2"
											sx={{ padding: '10px' }}
										>
											Get Quick Quote
										</Link>
									</Grid>

									{/* <Grid item alignSelf="flex-end">
								<RatesChart></RatesChart>
							</Grid> */}
								</Grid>
							</>
						)}

						<Grid
							container
							direction="column"
							justifyContent="space-between"
							sx={{ marginBottom: '20px', marginTop: '30px', width: '100%' }}
						>
							{!loading && (
								<Grid item width="100%">
									{shipments.length === 0 ? (
										<FirstShipment merchant={merchant} />
									) : (
										<ShipmentsTable
											merchantId={merchant.merchant_id}
											shipmentsCount={shipmentsCount}
											shipments={shipments}
										></ShipmentsTable>
									)}
								</Grid>
							)}
						</Grid>
					</Grid>
					<Snackbar
						open={openSnackBar}
						autoHideDuration={2000}
						onClose={handleAlertClose}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					>
						<Alert onClose={handleAlertClose} severity={apiResponse.type} sx={{ width: '100%' }}>
							{apiResponse.message}
						</Alert>
					</Snackbar>

					{/* alert for profile updated */}
					<Snackbar
						open={message !== '' ? updateOpen : !updateOpen}
						autoHideDuration={2000}
						onClose={() => setUpdateOpen(false)}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					>
						<Alert onClose={() => setUpdateOpen(false)} sx={{ width: '100%' }}>
							{message}
						</Alert>
					</Snackbar>
					{/* alert for profile updated */}
					<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
						<CircularProgress color="inherit" />
					</Backdrop>
				</div>
			)}
		</>
	);
}
