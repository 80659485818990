import { Alert, Backdrop, Button, CircularProgress, Grid, Snackbar, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import BookingsTable from '../../components/Customs/BookingsTable';
import { axiosConfig, useAuthToken } from '../../constants/axios';
import { Booking } from '../../types/booking';
import { SnackAlert } from '../../types/util';

export default function CustomsDashboard() {
	const [bookings, setBookings] = useState<Booking[]>([]);
	const [loading, setLoading] = useState(true);
	const [date, setDate] = useState('');
	const [shipmentDetails, setShipmentDetails] = useState([]);
	const [unAssignedBigBoxes, setUnassignedBigBoxes] = useState([]);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [snackAlert, setSnackAlert] = useState<SnackAlert>({ type: 'success', message: '' });
	const navigate = useNavigate();
	const user = JSON.parse(sessionStorage.getItem('user') as string);
	const merchant = JSON.parse(sessionStorage.getItem('merchant') as string);
	const token = useAuthToken();
	const axiosInstance = axiosConfig(token);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDate(event.target.value);
	};

	const handleGetOutstandingShipments = () => {
		setLoading(true);
		axiosInstance
			.post('/shipment/getShipmentsBookingID', {
				booking_id: '0'
			})
			.then(response => {
				const shipments = response.data.data;
				const extractedShipments = shipments.map((shipment: any) => {
					return {
						partitionKey: shipment.partitionKey,
						sortKey: shipment.sortKey,
						address_to_city_locality: shipment.address_to_city_locality,
						address_to_company: shipment.address_to_company,
						address_to_country: shipment.address_to_country,
						address_to_email: shipment.address_to_email,
						address_to_google_search: shipment.address_to_google_search,
						address_to_id: shipment.address_to_id,
						address_to_name: shipment.address_to_name,
						address_to_phone: shipment.address_to_phone,
						address_to_state_province: shipment.address_to_state_province,
						address_to_street_1: shipment.address_to_street_1,
						address_to_street_2: shipment.address_to_street_2,
						address_to_zip_postal: shipment.address_to_zip_postal,
						order_comm_invoice: shipment.order_comm_invoice,
						shipment_id: shipment.shipment_id,
						shipment_reference: shipment.shipment_reference,
						customs_hawb_number: shipment.customs_hawb_number,
						shipment_edit_status: shipment.shipment_edit_status,
						last_modified: shipment.last_modified,
						merchant_country: shipment.merchant_country,
						customs_info: shipment.customs_info
					};
				});
				setShipmentDetails(extractedShipments);
			})
			.catch(err => {
				console.log(err);
				setLoading(false);
			}); // eslint-disable-line react-hooks/exhaustive-deps
	};

	const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackBar(false);
	};

	useEffect(() => {
		if (shipmentDetails && shipmentDetails.length > 0) {
			navigate('/customs/shipments/review', {
				state: shipmentDetails
			});
		} else if (bookings.length > 0) {
			setLoading(false);
		}
	}, [shipmentDetails]);

	useEffect(() => {
		async function fetchData() {
			try {
				const response1 = await axiosInstance.post('/bigbox/getBigBoxByBookingID', {
					booking_id: '0'
				});
				setUnassignedBigBoxes(response1.data.data);

				const response2 = await axiosInstance.post('/booking/readBookings', {
					booking_date_departure: ['2023', '2024']
				});
				if (response2.data.data.length > 0) {
					setSnackAlert({ type: 'success', message: 'Success' });
					setOpenSnackBar(true);
				}
				setBookings(response2.data.data);
			} catch (error) {
				console.log(error);
			}
			setLoading(false);
		}

		fetchData();
	}, []);

	return (
		<>
			{!user || !user?.admin_access || !merchant ? (
				<Navigate to="/" state={{ type: 'error', message: 'Access Denied!' }} />
			) : (
				<div
					style={{
						display: 'block',
						justifyContent: 'center',
						alignContent: 'center',
						marginBottom: '60px',
						maxWidth: 1400,
						marginLeft: 'auto',
						marginRight: 'auto'
					}}
				>
					{!loading ? (
						<Grid container alignItems="center" direction="column">
							<Typography variant="h4" mt={5}>
								Customs Dashboard
							</Typography>
							<Grid
								container
								direction="column"
								justifyContent="space-between"
								sx={{ marginBottom: '20px', marginTop: '30px' }}
							>
								<Grid
									container
									direction={'row'}
									alignContent={'center'}
									justifyContent={'space-between'}
									spacing={2}
									mt={2}
									mb={2}
								>
									<Grid item alignSelf={'center'}>
										<Typography variant="h5">Bookings</Typography>
									</Grid>
									<Grid item>
										<Grid container direction={'row'} spacing={2}>
											<Grid item alignSelf={'center'}>
												<Button
													color="primary"
													variant="contained"
													size="small"
													onClick={handleGetOutstandingShipments}
												>
													Outstanding Shipments
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item width="100%">
									<BookingsTable
										bookings={bookings}
										setBookings={setBookings}
										unAssignedBigBoxes={unAssignedBigBoxes}
										setUnassignedBigBoxes={setUnassignedBigBoxes}
									></BookingsTable>
								</Grid>
							</Grid>
						</Grid>
					) : (
						<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
							<CircularProgress color="inherit" />
						</Backdrop>
					)}
					<Snackbar
						open={openSnackBar}
						autoHideDuration={2000}
						onClose={handleAlertClose}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					>
						<Alert onClose={handleAlertClose} severity={snackAlert.type} sx={{ width: '100%' }}>
							{snackAlert.message}
						</Alert>
					</Snackbar>
				</div>
			)}
		</>
	);
}
