import { Box, Grid, Paper, Typography, useTheme as useMuiTheme } from '@mui/material';
import Clouds from '../../img/clouds.png';

export default function AuthenticationContainer(props: any) {
	const muiTheme = useMuiTheme();

	return (
		<Grid 
			container 
			sx={{ height: '100vh', width: '100vw'}}
		>
			<Grid
				item
				component={Grid}
				display={{ xs: 'none', md: 'flex' }}
				md={6}
				lg={6}
				xl={6}
				sx={{
					backgroundImage: `url(${Clouds})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					flexDirection: 'column'
				}}
			>
				<img
					style={{ height: '150px', alignSelf: 'flex-start' }}
					src='./img/tunl-logo-blue.png'
					alt='TUNL logo'
				/>
				<Typography
					style={{ fontFamily: 'NeueMachinaUltraBold' }}
					pt={18}
					px={8}
					variant='h2'
					color={muiTheme.palette.primary.main}
					textAlign='center'
				>
					Ship internationally with the best rates
				</Typography>
				<Typography
					style={{ fontFamily: 'NeueMachinaRegular' }}
					pt={4}
					px={8}
					variant='h5'
					color={muiTheme.palette.primary.main}
					textAlign='center'
				>
					{props.text}
				</Typography>
			</Grid>
			<Grid 
				item 
				xs={12} 
				sm={12} 
				md={6} 
				lg={6} 
				xl={6} 
				component={Paper} 
				elevation={0} 
				square
				maxHeight='100%'
				maxWidth='100%'
				overflow='auto'
			>
				{props.children}
			</Grid>
		</Grid>
	);
}