import {
	Backdrop,
	CircularProgress,
	Dialog,
	DialogTitle,
	List,
	ListItem,
	ListItemButton,
	ListItemText
} from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { axiosConfig, useAuthToken } from '../../constants/axios';
import { UserMerchantRecord } from '../../types/user';
import CreateMerchant from '../Merchant/CreateMerchant';
import MerchantDashboard from '../Merchant/MerchantDashboard';

function Home(props: any) {
	const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user') as string));
	const [merchant, setMerchant] = useState(JSON.parse(sessionStorage.getItem('merchant') as string));
	const [userMerchants, setUserMerchants] = useState<UserMerchantRecord[]>(
		JSON.parse(sessionStorage.getItem('userMerchants') as string)
	);
	const [openMerchantSelect, setOpenMerchantSelect] = useState(true);
	const [loading, setLoading] = useState(false);
	const posthog = usePostHog();
	const token = useAuthToken();
	const axiosInstance = axiosConfig(token);

	const setSelectedMerchant = async (userMerchant: UserMerchantRecord) => {
		setOpenMerchantSelect(false);
		sessionStorage.setItem('activeUserMerchant', JSON.stringify(userMerchant));
		setLoading(true);
		try {
			const response = await axiosInstance.post('/merchant/getMerchant', {
				merchant_id: userMerchant.merchant_id
			});
			sessionStorage.setItem('merchant', JSON.stringify(response.data.merchantRecord));
			setMerchant(response.data.merchantRecord);
			props.setSelectedMerchant(response.data.merchantRecord);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (userMerchants && userMerchants.length === 1 && !merchant) {
			setSelectedMerchant(userMerchants[0]);
		}

		posthog?.identify(user.partitionKey, {
			email: user.user_email
		});
	}, [merchant, posthog]);

	return (
		<>
			{userMerchants && userMerchants.length !== 0 && !merchant ? (
				<Dialog open={openMerchantSelect}>
					<DialogTitle>Select Merchant </DialogTitle>
					<List>
						{userMerchants.map(userMerchant => (
							<ListItem>
								<ListItemButton
									onClick={() => {
										setSelectedMerchant(userMerchant);
									}}
								>
									<ListItemText>{userMerchant.merchant_name}</ListItemText>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</Dialog>
			) : merchant ? (
				<>
					<MerchantDashboard
						user={props.user}
						merchant={JSON.parse(sessionStorage.getItem('merchant') as string)}
						createMerchant={true}
					/>
				</>
			) : (
				<CreateMerchant setMerchant={setMerchant} user={user} />
			)}

			{loading ? (
				<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
					<CircularProgress color="inherit" />
				</Backdrop>
			) : (
				<></>
			)}
		</>
	);
}

export default Home;
