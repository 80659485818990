import {
	Alert,
	Backdrop,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import ContentContainer from '../../components/Layout/ContentContainer';
import { axiosConfig, useAuthToken } from '../../constants/axios';
import {
	BUTTON,
	createElementId,
	DROPDOWN,
	FORM_CONTROL,
	FORM_CONTROL_LABEL,
	HEADING,
	LABEL,
	MANAGE_USERS,
	MENU_ITEM,
	SELECT,
	TABLE,
	TABLE_BODY,
	TABLE_HEAD
} from '../../constants/id';
import { UserMerchantRecord, UserRecord } from '../../types/user';
import UserDetails from './UserDetails';
export default function UsersDashboard() {
	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState<UserRecord[]>();
	const [showUserModal, setShowUserModal] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [merchant, setMerchant] = useState(JSON.parse(sessionStorage.getItem('merchant') as string));
	const [userMerchants, setUserMerchants] = useState<UserMerchantRecord[]>(
		JSON.parse(sessionStorage.getItem('userMerchants') as string)
	);
	const [companyName, setCompanyName] = useState(merchant.merchant_name);
	const token = useAuthToken();
	const axiosInstance = axiosConfig(token);

	async function getUsers() {
		setLoading(true);

		try {
			const response = await axiosInstance.post(`/user/merchantUsers?merchantId=${merchant.partitionKey}`);

			setLoading(false);
			setUsers(response.data.data);
		} catch (ex) {
			setLoading(false);
		}
	}

	function handleAddUserClicked() {
		setShowSnackbar(false);
		setShowUserModal(true);
	}

	function handleModalClosed(updated: boolean) {
		if (updated) {
			setShowSnackbar(true);
			getUsers();
		}

		setShowUserModal(false);
	}

	const handleChange = (event: SelectChangeEvent) => {
		setCompanyName(event.target.value as string);
	};

	const setSelectedMerchant = async (userMerchants: UserMerchantRecord) => {
		sessionStorage.setItem('activeUserMerchant', JSON.stringify(userMerchants));
		setLoading(true);
		try {
			const response = await axiosInstance.post('/merchant/getMerchant', {
				merchant_id: userMerchants.merchant_id
			});
			sessionStorage.setItem('merchant', JSON.stringify(response.data.merchantRecord));
			setMerchant(response.data.merchantRecord);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (merchant) {
			getUsers();
		}
	}, [merchant]);

	//updates merchant in the text fields when setSelectedMechant is changed
	useEffect(() => {
		if (merchant) {
			setMerchant(merchant);
		}
	}, [merchant]);

	return (
		<ContentContainer>
			<Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
				<Typography variant="h5" mt={5}></Typography>
				<Typography id={createElementId([MANAGE_USERS, HEADING, 'manage_users'])} variant="h5" mt={5}>
					Manage Users
				</Typography>
				<Typography variant="h5" mt={5}>
					{userMerchants.length > 1 && (
						<FormControl fullWidth>
							<InputLabel
								id={createElementId([MANAGE_USERS, FORM_CONTROL_LABEL, DROPDOWN, SELECT, 'merchant'])}
							>
								Merchant
							</InputLabel>
							<Select
								id={createElementId([MANAGE_USERS, FORM_CONTROL_LABEL, DROPDOWN, SELECT, 'merchant'])}
								labelId="demo-simple-select-label"
								value={companyName}
								label="Merchant"
								onChange={handleChange}
							>
								{userMerchants.map(userMerchant => (
									<MenuItem
										id={createElementId([
											MANAGE_USERS,
											FORM_CONTROL,
											DROPDOWN,
											SELECT,
											MENU_ITEM,
											'userMerchant.merchant_name'
										])}
										key={userMerchant.merchant_name}
										onClick={() => setSelectedMerchant(userMerchant)}
										value={userMerchant.merchant_name}
									>
										{userMerchant.merchant_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</Typography>
			</Grid>
			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				spacing={2}
				sx={{ marginBottom: '20px', marginTop: '30px' }}
			>
				<Grid item>
					<Button
						id={createElementId([MANAGE_USERS, BUTTON, 'add_user'])}
						color="primary"
						variant="contained"
						sx={{ borderRadius: '30px' }}
						onClick={handleAddUserClicked}
					>
						Add User
					</Button>
				</Grid>
			</Grid>
			<TableContainer component={Paper} sx={{ backgroundColor: '#fff', boxShadow: 3, mb: 5 }}>
				<Table>
					<TableHead>
						<Grid container sx={{ p: 2, fontSize: '1.2em' }}>
							<Typography id={createElementId([MANAGE_USERS, LABEL, 'users'])}>Users</Typography>
						</Grid>
						<TableRow>
							<TableCell
								id={createElementId([MANAGE_USERS, TABLE, TABLE_HEAD, 'name'])}
								sx={{ paddingLeft: 5, fontWeight: 'light', fontSize: '0.8em', color: 'gray' }}
							>
								Name
							</TableCell>
							<TableCell
								id={createElementId([MANAGE_USERS, TABLE, TABLE_HEAD, 'email'])}
								sx={{ fontWeight: 'light', fontSize: '0.8em', color: 'gray' }}
							>
								Email Address
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users && users.length ? (
							users.map((user: UserRecord) => (
								<TableRow sx={{ borderBottom: 1, borderColor: '#dadada' }} key={user.sortKey}>
									<TableCell sx={{ paddingLeft: 5 }}>
										<Typography id={createElementId([MANAGE_USERS, TABLE, TABLE_BODY, 'name'])}>
											{user.user_name}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography id={createElementId([MANAGE_USERS, TABLE, TABLE_BODY, 'email'])}>
											{user.user_email}
										</Typography>
									</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={12}>
									<Grid container justifyContent="center" alignItems="center">
										<Typography id={createElementId([MANAGE_USERS, TABLE, TABLE_BODY, 'no_users'])}>
											No users added yet!
										</Typography>
									</Grid>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<UserDetails
				merchant={merchant}
				show={showUserModal}
				onModalClosed={handleModalClosed}
				newUsers={getUsers}
			></UserDetails>
			<Snackbar
				open={showSnackbar}
				autoHideDuration={2000}
				onClose={() => setShowSnackbar(false)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={() => setShowSnackbar(false)} severity="success" sx={{ width: '100%' }}>
					User created!
				</Alert>
			</Snackbar>
			<Backdrop sx={{ color: '#fff', zIndex: 10000 }} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</ContentContainer>
	);
}
